import { Button } from "../../../components";
import { ReactComponent as NewUser } from "../../../assets/icons/new-user.svg";
import { ReactComponent as Export } from "../../../assets/icons/export.svg";
import { ReactComponent as Share } from "../../../assets/icons/share.svg";
import { ReactComponent as RedRec } from "../../../assets/icons/red-rectangle.svg";
import { ReactComponent as GreyRec } from "../../../assets/icons/grey-rectangle.svg";
import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
} from "../../../utils/helpers";
import TransactionCard from "./components/TransactionCard";
import LeagueCard from "./components/LeagueCard";
import UserCard from "./components/UserCard";
import useDashboard from "./hooks/useDashboard";

const Dashboard = () => {
  const {
    data,
    usersCount,
    leaguesCount,
    users,
    latestTransactions,
    leagues,
    navigate,
  } = useDashboard();

  return (
    <div className="px-4 pb-6 md:px-10">
      <div className="w-full hidden justify-end gap-4 lg:flex">
        <AddNewLeagueModal />
        <Button
          leftIcon={<NewUser />}
          mode="secondary"
          //TODO: add function for ivite
          onClick={() => {}}
          className="rounded-[8px] w-auto px-4 !h-10"
        >
          Invite New User
        </Button>
        <Button
          leftIcon={<Export />}
          mode="secondary"
          //TODO: add function for export
          onClick={() => {}}
          className="rounded-[8px] w-auto px-4 !h-10"
        >
          Export
        </Button>
        <Button
          leftIcon={<Share />}
          mode="secondary"
          //TODO: add function for share
          onClick={() => {}}
          className="rounded-[8px] w-auto px-4 !h-10"
        >
          Share
        </Button>
      </div>
      <div className="w-full flex flex-col gap-4 lg:hidden">
        <div className="w-full flex justify-end gap-4">
          <AddNewLeagueModal />
          <Button
            leftIcon={<NewUser />}
            mode="secondary"
            //TODO: add function for ivite
            onClick={() => {}}
            className="rounded-[8px] w-full px-4 !h-10"
          >
            Invite New User
          </Button>
        </div>
        <div className="w-full flex justify-end gap-4">
          <Button
            leftIcon={<Export />}
            mode="secondary"
            //TODO: add function for export
            onClick={() => {}}
            className="rounded-[8px] w-full px-4 !h-10"
          >
            Export
          </Button>
          <Button
            leftIcon={<Share />}
            mode="secondary"
            //TODO: add function for share
            onClick={() => {}}
            className="rounded-[8px] w-full px-4 !h-10"
          >
            Share
          </Button>
        </div>
      </div>
      <div className="mt-6 flex gap-6 flex-col lg:flex-row">
        <div className="flex flex-col gap-6 w-full lg: w-[32%]">
          <div className="flex h-[80px]">
            <div className="relative bg-red-bgGradient2-1 py-4 pl-6 rounded-l-[12px] w-[45%] flex flex-col justify-center items-center">
              <p className="text-text-redLight text-xs font-gilroyBold">
                Total Revenues
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                ${data?.totalRevenues}
              </p>
              <RedRec className="absolute right-[-48px] z-[10]" />
            </div>
            <div className="relative bg-card-bgGreyLight py-4 pl-6 rounded-r-[12px] w-[55%] flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Total Expenses
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                ${data?.totalExpenses}
              </p>
            </div>
          </div>
          <div className="w-full ">
            <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
              <p className="font-gilroyBold">Latest Transactions</p>
              <p className="font-gilroyMedium text-text-red cursor-pointer">
                View all
              </p>
            </div>
            <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke h-[378px] bg-card-bgGreyDark rounded-b-[12px] overflow-hidden flex flex-col-reverse justify-end p-6">
              {latestTransactions?.length ? (
                Object.keys(groupMessagesByDay(latestTransactions))?.map(
                  (date, i) => {
                    const group = groupMessagesByDay(latestTransactions)[date];
                    return (
                      <div key={i}>
                        <div className="flex gap-4 my-1">
                          <p className="text-text-grey font-gilroyMedium text-nowrap">
                            {formatAsFriendlyPastDate(
                              new Date(group[0].createdAt)
                            )?.toString()}
                          </p>
                        </div>
                        {group?.map((item: any, index: number) => {
                          return (
                            <TransactionCard key={index} transaction={item} />
                          );
                        })}
                      </div>
                    );
                  }
                )
              ) : (
                <p className="font-gilroyMedium text-text-grey">
                  No transactions
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6 w-[68%]">
          <div className="flex h-[80px]">
            <div className="relative bg-card-bgGreyLight py-4 pl-6 rounded-l-[12px] w-full flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Active Leagues
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                {leaguesCount}
              </p>
              <GreyRec className="absolute right-[-48px] z-[10]" />
            </div>
            <div className="relative bg-modal-inputBg py-4 pl-6 rounded-r-[12px] w-full flex flex-col justify-center items-center">
              <p className="text-text-grey text-xs font-gilroyBold">
                Total Users
              </p>
              <p className="mt-1 font-absoluteEmpire text-[18px]">
                {usersCount}
              </p>
            </div>
          </div>
          <div className="w-full flex gap-6 flex-col lg:flex-row">
            <div className="w-full lg:w-[60%]">
              <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">League Status</p>
                <p
                  onClick={() => navigate("/leagues")}
                  className="font-gilroyMedium text-text-red cursor-pointer"
                >
                  View all
                </p>
              </div>
              <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke bg-card-bgGreyDark rounded-b-[12px] overflow-hidden h-[378px] flex flex-col gap-2 p-4">
                {leagues?.message?.length
                  ? leagues?.message?.map((league: any, index: number) => (
                      <LeagueCard key={index} league={league} />
                    ))
                  : null}
              </div>
            </div>
            <div className="border-[1px] border-modal-modalStroke rounded-[13px] w-full max-h-[65vh] bg-modal-grey lg:w-[45%]">
              <div className="relative px-6 py-4 bg-filterContainer rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">Latest Registered Users</p>
                <p
                  onClick={() => navigate("/users")}
                  className="font-gilroyMedium text-text-red cursor-pointer"
                >
                  View all
                </p>
              </div>
              <div className="rounded-b-[12px] p-6 flex flex-col-reverse justify-end h-[87%] overflow-y-auto">
                {users?.message?.length ? (
                  Object.keys(groupMessagesByDay(users?.message))?.map(
                    (date, i) => {
                      const group = groupMessagesByDay(users?.message)[date];
                      return (
                        <div key={i}>
                          <div className="flex gap-4 my-1">
                            <p className="text-text-grey font-gilroyMedium text-nowrap">
                              {formatAsFriendlyPastDate(
                                new Date(group[0].createdAt)
                              )?.toString()}
                            </p>
                          </div>
                          {group?.map((item: any, index: number) => {
                            return <UserCard user={item} key={index} />;
                          })}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="font-gilroyMedium text-text-grey">
                    No transactions
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
