import EmojiPicker from "emoji-picker-react";
import { Button, TextArea } from "../../../../components";
import { ReactComponent as Dots } from "../../../../assets/icons/dots-icon.svg";
import { ReactComponent as Emoji } from "../../../../assets/icons/emoji-icon.svg";
import { ReactComponent as Attach } from "../../../../assets/icons/attach-icon.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/right-arrow-icon.svg";
import { useEffect, useRef, useState } from "react";
import demo_logo from "../../../../assets/images/demoLogo.png";
import { useFetchAllMessages } from "../../../../queries/messagesQueries/messagesQueries";
import { useFetchLeague } from "../../../../queries/leaguesQueries/leaguesQueries";
import useAuthStore from "../../../../stores/auth/auth";
import { socket } from "../../../../socket";
import moment from "moment";
import {
  groupMessagesByDay,
  formatAsFriendlyPastDate,
} from "../../../../utils/helpers";
import clsx from "clsx";

const LiveChat = ({
  leagueId,
  overrideContainerStyles,
}: {
  leagueId: string;
  overrideContainerStyles?: any;
}) => {
  const { userData } = useAuthStore.getState();
  const { data: league } = useFetchLeague(leagueId!);
  const { data, refetch } = useFetchAllMessages(league?.chatRoom?.id!);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [message, setMesasge] = useState("");
  const [file, _setFile] = useState<any>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  useEffect(() => {
    refetch();
  }, [league?.chatRoom?.id]);

  // For uploading images
  // const handleChange = (e: any) => {
  //   // @ts-ignore
  //   setFile(e.target.files[0]);
  // };

  const sendMessage = () => {
    if (message) {
      socket.emit(
        "send-message",
        {
          userId: userData?.id,
          chatRoomId: league?.chatRoom?.id,
          content: message,
          type: "text",
        },
        userData?.id
      );
      setMesasge("");
    }
  };

  useEffect(() => {
    socket.on("new-message-notify", (data: any) => {
      console.log(data, "new-message-notify");
      refetch();
    });
  }, []);

  return (
    <div className={clsx("w-[30%]", overrideContainerStyles)}>
      <div className="px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
        <p className="font-gilroyBold">League Live Chat</p>
        <Dots />
      </div>
      <div
        id="chatBox"
        className="w-full bg-card-bgGreyDark rounded-b-[12px] overflow-hidden h-[400px] flex flex-col justify-between"
      >
        <div className="p-4 overflow-y-auto flex flex-col gap-2">
          {data?.length ? (
            Object.keys(groupMessagesByDay(data))?.map((date, i) => {
              const group = groupMessagesByDay(data)[date];
              return (
                <div key={i}>
                  <div className="flex items-center gap-4 my-1">
                    <div className="h-1 w-full bg-stroke rounded-[2px]" />
                    <p className="text-text-grey font-gilroyBold text-nowrap">
                      {formatAsFriendlyPastDate(
                        new Date(group[0].createdAt)
                      )?.toString()}
                    </p>
                    <div className="h-1 w-full bg-stroke rounded-[2px]" />
                  </div>
                  {group
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a?.createdAt)?.valueOf() -
                        new Date(b?.createdAt)?.valueOf()
                    )
                    ?.map((item: any, index: any) => {
                      return (
                        <div
                          className="flex mt-1"
                          key={index}
                          ref={messagesEndRef}
                        >
                          <img
                            src={
                              item?.userAvatar !== "no data"
                                ? item?.userAvatar
                                : demo_logo
                            }
                            alt="CutThroat"
                            className="w-[48px] h-[48px] rounded-[50px] mr-4"
                          />
                          <div>
                            <div className="flex">
                              <p className="text-base font-gilroyBold mr-3">
                                {item?.username}
                              </p>
                              <p className="text-text-grey font-gilroyMedium">
                                {moment(item?.createdAt).format("LT")}
                              </p>
                            </div>
                            {item?.content && (
                              <p className="text-text-lightGrey text-base font-gilroyMedium">
                                {item?.content}
                              </p>
                            )}

                            {item?.imgUrl && (
                              <img
                                src={item?.imgUrl}
                                alt="CutThroat"
                                className="mt-1"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <p className="font-gilroyMedium text-text-grey">No messages</p>
          )}
        </div>

        <div
          className={`rounded-[16px] bg-modal-inputBg min-h-[80px] mt-4 p-4 flex items-center justify-between !h-[24px]`}
        >
          <div className="relative">
            <TextArea
              value={message}
              containerClassName="border-none !py-0 !px-1 bg-transparent focus-within:bg-transparent rounded-none h-[24px]"
              textAreaClassName="placeholder-text-grey text-base font-gilroyMedium !min-h-[24px] !h-[24px] pr-10 resize-none"
              placeholder="Write a message..."
              onChange={(e) => {
                setMesasge(e.target.value);
              }}
            />
            {file && (
              <div className="flex items-center">
                <Attach className="h-3 w-3" />
                <p className="text-text-grey text-xs ml-2">
                  {file
                    ? file?.name?.length > 14
                      ? `${file?.name?.slice(0, 14)}...`
                      : file?.name
                    : "-"}
                </p>
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <div className="cursor-pointer p-1 absolute right-[100px]">
              <Emoji onClick={() => setOpenEmoji(!openEmoji)} />
              <EmojiPicker
                open={openEmoji}
                height={400}
                width={350}
                style={{
                  position: "absolute",
                  zIndex: 10,
                  right: 0,
                  bottom: 40,
                }}
                searchDisabled
                skinTonesDisabled
                // @ts-ignore
                theme="dark"
                autoFocusSearch={false}
                onEmojiClick={(data: any) => {
                  setMesasge((prev) => prev + data?.emoji);
                  setOpenEmoji(false);
                }}
              />
            </div>
            {/* TODO: Add upload photos when backend will be ready
            <div className="cursor-pointer p-1">
              <label className="cursor-pointer">
                <input type="file" onChange={handleChange} accept="image/*" />
                <Attach />
              </label>
            </div> */}
            <Button
              mode="primary"
              leftIcon={<RightArrow />}
              className="!h-[48px] !w-[48px]"
              onClick={() => {
                sendMessage();
              }}
            >
              {""}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChat;
