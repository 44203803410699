import { useUserById } from "../../../../queries/adminQueries/adminQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import clsx from "clsx";

const TransactionCard = ({ transaction }: { transaction: any }) => {
  const { data } = useUserById(transaction?.userId);

  return (
    <div className="flex justify-between items-center my-2">
      <div>
        <div className="flex items-center">
          <img
            src={data?.logo || demo_team_logo}
            alt="CutThroat"
            className="w-[40px] h-[40px] rounded-[50px] mr-3"
          />
          <div>
            <p className="text-base font-gilroyBold">{data?.name}</p>
            <p className="text-text-grey text-xs font-gilroyMedium capitalize">
              {transaction?.type}
            </p>
          </div>
        </div>
      </div>

      <p
        className={clsx(
          "text-text-red font-gilroyBold",
          transaction?.type === "deposit" && "!text-text-green"
        )}
      >
        {transaction?.type === "deposit" ? "+" : "-"}${transaction?.amount}
      </p>
    </div>
  );
};
export default TransactionCard;
