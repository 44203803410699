import league_logo from "../../assets/images/league-logo.png";
import clsx from "clsx";
import {useNavigate} from "react-router";
interface MobileLeaguesProps {
    leagues:{ [key: string]: any }[]
}
const MobileLeagues = ({leagues}:MobileLeaguesProps) => {
    const navigate = useNavigate();

    return (
        <>
            {leagues?.length>0 ?

                leagues?.map((data:{ [key: string]: any }) => (
                    <div
                        className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">
                        <div className="flex justify-between">
                            <img width={40} height={40} src={league_logo} alt="League logo"/>
                            <div>
                                <p className="font-gilroyBold text-base">{data?.name || ""}</p>
                                <p className="text-sm text-text-subGrey">Premium League</p>
                            </div>
                            <div
                                onClick={() =>navigate(`/leagues/league/${data?.id}`)}
                                className={`flex flex-col cursor-pointer`}
                            >
                                <p className="underline text-nowrap">View League</p>
                                <p className="text-sm text-text-subGrey">View Details</p>
                            </div>
                        </div>
                        <div className="bg-card-strokeGradient-2 h-[2px]">

                        </div>
                        <div className="flex justify-between">
                            <div>
                                <p className="font-gilroyBold text-base">{data?.entryFee}</p>
                                <p className="text-sm text-text-subGrey">Entry Fee</p>
                            </div>
                            <div>
                                <p className="font-gilroyBold text-base">{data?.numberOfTeams + " Teams"}</p>
                                <p className="text-sm text-text-subGrey">
                                    {data?.teamsEliminatedPerWeek + " were eliminated"}
                                </p>
                            </div>
                            <div>
                                <p className="font-gilroyBold text-base">{data?.eliminatedTeams ?? 0}</p>
                                <p className="text-sm text-text-subGrey">Eliminated</p>
                            </div>

                        </div>
                        <div className="flex justify-between">
                            <div>
                                <p className="font-gilroyBold text-base">{Number(data?.prizeFundFirstPlace) + Number(data?.prizeFundSecondPlace)}</p>
                                <p className="text-sm text-text-subGrey">Prize Fund</p>
                            </div>
                            <div className={`flex items-center justify-between`}>
                                <div
                                    className={clsx(
                                        "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
                                        data?.status === "registration" &&
                                        "from-elements-chipGradient-1 to-elements-chipGradient-2",
                                        data?.status === "in progress" &&
                                        "from-red-bgGradient2-1 to-red-bgGradient2-2",
                                        data?.status === "completed" && "bg-white text-screenBgGrey"
                                    )}
                                >
                                    {data?.status || ""}
                                </div>
                            </div>
                        </div>
                    </div>

                )): <div className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">

                    <p className="text-base text-text-subGrey">
                        No data available
                    </p>
                </div>}
        </>
    );
};

export default MobileLeagues;