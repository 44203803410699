import { TeamCard } from "../../League/components/TeamCard";
import LiveChat from "../../Team/components/LiveChat";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import clsx from "clsx";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-left.svg";
import { RequestWithdrawModal } from "../Finances/components/RequestWithdraw";
import { DepositModal } from "../Finances/components/Deposit";
import TransactionCard from "../../Dashboard/components/TransactionCard";
import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
} from "../../../../utils/helpers";
import { Button } from "../../../../components";
import useHomeHook from "./hooks/useHome";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 800 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 800, min: 400 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Home = () => {
  const {
    userData,
    user,
    activeLeagueId,
    setActiveLeagueId,
    data,
    transactions,
    navigate,
    prevButtonFunc,
    nextButtonFunc,
    balance,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    CustomButtonGroup,
  } = useHomeHook();

  return (
    <div className="px-10 pb-6 mt-6">
      <div className="flex gap-6 h-auto">
        {user?.league?.length ? (
          <>
            <div className="w-[67%]">
              <Carousel
                arrows={false}
                responsive={responsive}
                customButtonGroup={<CustomButtonGroup />}
                itemClass="px-2"
              >
                {user?.league?.length ? (
                  user?.league?.map((item: any) => (
                    <div
                      onClick={() => setActiveLeagueId(item?.id)}
                      className={clsx(
                        "h-[128px] max-w-[400px] bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-3 flex flex-col justify-center items-center cursor-pointer",
                        item?.id ===
                          (activeLeagueId || user?.league?.[0]?.id) &&
                          "bg-card-bgGreyLight"
                      )}
                    >
                      <div className="flex w-full justify-between items-center">
                        <p className="text-base font-gilroyBold">
                          {item?.name}
                        </p>
                        <div
                          className={clsx(
                            "text-xs font-gilroyBold px-4 py-2 rounded-[16px] bg-gradient-to-br capitalize text-nowrap w-[88px] h-[25px] ml-[46px]",
                            item?.status === "registration" &&
                              "from-elements-chipGradient-1 to-elements-chipGradient-2",
                            item?.status === "in progress" &&
                              "from-red-bgGradient2-1 to-red-bgGradient2-2",
                            item?.status === "completed" &&
                              "bg-white text-screenBgGrey"
                          )}
                        >
                          {item?.status || ""}
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-stroke my-3" />
                      <div className="flex justify-around w-full">
                        <div className="flex flex-col justify-center items-center">
                          <p className="text-xs text-text-grey font-gilroyMedium">
                            Teams
                          </p>
                          <p className="font-gilroyBold">
                            {item?.numberOfTeams}
                          </p>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <p className="text-xs text-text-grey font-gilroyMedium">
                            Entry Fee
                          </p>
                          <p className="font-gilroyBold">${item?.entryFee}</p>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <p className="text-xs text-text-grey font-gilroyMedium">
                            Fund
                          </p>
                          <p className="font-gilroyBold">
                            $
                            {Number(item?.prizeFundFirstPlace) +
                              Number(item?.prizeFundSecondPlace)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Carousel>
            </div>
            <div className="flex gap-3 justify-center items-center">
              <div
                onClick={() => prevButtonFunc?.current()}
                className="bg-modal-inputBg w-[48px] h-[48px] rounded-[24px] flex justify-center items-center cursor-pointer"
              >
                <Arrow />
              </div>
              <div
                onClick={() => nextButtonFunc?.current()}
                className="bg-modal-inputBg w-[48px] h-[48px] rounded-[24px] flex justify-center items-center cursor-pointer"
              >
                <Arrow className="rotate-180" />
              </div>
            </div>
          </>
        ) : (
          <div className="w-full">
            <div className="w-full bg-home-banner bg-cover bg-no-repeat rounded-[16px] h-[128px]"></div>
            <div className="w-full bg-card-bgGreyDark rounded-[16px] h-[126px] mt-6 p-6 flex justify-between items-center">
              <div>
                <p className="text-[24px] font-gilroyBold">
                  You are not currently a member of any leagues
                </p>
                <p className="mt-2 text-base text-text-grey font-gilroyMedium">
                  Join our community and start having fun with friends in the
                  heart of fantasy sports!
                </p>
              </div>
              <Button onClick={() => navigate("/leagues")}>
                Choose League
              </Button>
            </div>
          </div>
        )}
        <div className="w-[30%] h-[128px] bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-3 flex gap-3">
          <div className="w-[60%] flex flex-col justify-center items-center">
            <p className="text-xs text-text-grey">Your Balance</p>
            <p className="font-absoluteEmpire text-[28px]">
              ${balance?.toFixed(2)}
            </p>
          </div>
          <div className="w-[40%] flex flex-col justify-center items-center gap-2">
            <RequestWithdrawModal
              balance={Number(user?.balance?.winning_amount)}
            />
            <DepositModal />
          </div>
        </div>
      </div>
      {user?.league?.length ? (
        <div className="flex gap-6 mt-[32px]">
          <div className="w-full">
            <div className="relative px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
              <p className="font-gilroyBold">Teams</p>
              <p className="text-text-red font-gilroyMedium cursor-pointer">
                My Team
              </p>
            </div>
            <div className="flex flex-row flex-wrap justify-center bg-card-bgGreyDark rounded-b-[12px] border-b-[1px] border-x-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%] xl:justify-between">
              {inProgress && data?.team?.length
                ? regularTeams.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.position}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      faab={item?.faab_balance}
                      isHome
                      isMe={item?.userId === userData?.id}
                    />
                  ))
                : data?.team?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.position}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      faab={item?.faab_balance}
                      isHome
                      isMe={item?.userId === userData?.id}
                    />
                  ))}
              {inProgress && (
                <div className="flex w-full gap-4 items-center">
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-text-red font-gilroyBold">Risk zone</p>
                    <p className="text-text-red font-gilroyMedium text-xs text-nowrap">
                      (The elimination — Tuesday 12:00 AM)
                    </p>
                  </div>
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                </div>
              )}
              {teamsAtRisk?.length
                ? teamsAtRisk?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.position}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      faab={item?.faab_balance}
                      isHome
                      atRisk
                      isMe={item?.userId === userData?.id}
                    />
                  ))
                : null}
              {eliminatedTeams?.length
                ? eliminatedTeams?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.position}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      eliminated
                      faab={item?.faab_balance}
                      isHome
                      isMe={item?.userId === userData?.id}
                    />
                  ))
                : null}
            </div>
          </div>
          <div className="w-[30%] flex flex-col gap-6">
            {isJoined ? (
              <LiveChat
                overrideContainerStyles="!w-full"
                leagueId={activeLeagueId || user?.league?.[0]?.id}
              />
            ) : null}
            <div className="w-full ">
              <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
                <p className="font-gilroyBold">Latest Transactions</p>
                <p
                  onClick={() => navigate("/finances")}
                  className="font-gilroyMedium text-text-red cursor-pointer"
                >
                  View all
                </p>
              </div>
              <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke h-[270px] bg-card-bgGreyDark rounded-b-[12px] overflow-y-scroll flex flex-col-reverse p-6">
                {transactions?.length ? (
                  Object.keys(groupMessagesByDay(transactions))?.map(
                    (date, i) => {
                      const group = groupMessagesByDay(transactions)[date];
                      return (
                        <div key={i}>
                          <div className="flex gap-4 my-1">
                            <p className="text-text-grey font-gilroyMedium text-nowrap">
                              {formatAsFriendlyPastDate(
                                new Date(group[0].createdAt)
                              )?.toString()}
                            </p>
                          </div>
                          {group?.map((item: any, index: number) => {
                            return (
                              <TransactionCard transaction={item} key={index} />
                            );
                          })}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="font-gilroyMedium text-text-grey">
                    No transactions
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Home;
