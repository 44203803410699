import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const PaymentsServices = {
  makeDeposit: async (userId: string, payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/payment/deposit/${userId}`;
    try {
      const makeDeposit = await axios.post(endPoint, payload, config);
      return makeDeposit?.data;
    } catch (error) {
      throw error;
    }
  },
  getUserTransactions: async (userId: string, params?: any) => {
    const endPoint = `/api/payment/transactions/${userId}`;
    try {
      const transactions = await axios.get(endPoint, { params });
      return transactions?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  sendWithdrawalRequest: async (payload: any) => {
    const endPoint = `/api/payment/withdrawal/request/${payload?.userId}`;
    try {
      delete payload?.userId;
      const withdrawalRequest = await axios.post(endPoint, payload);
      return withdrawalRequest?.data;
    } catch (error) {
      throw error;
    }
  },
  makeWithdrawal: async (payload: any) => {
    const endPoint = `/api/payment/withdrawal`;
    try {
      delete payload?.userId;
      const withdrawal = await axios.post(endPoint, payload);
      return withdrawal?.data;
    } catch (error) {
      throw error;
    }
  },
};
