import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment";

export const containsUppercase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const containsNumber = (str: string) => {
  return /\d/.test(str);
};

export const emailValidation =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const phoneValidation = (phone: string): boolean => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isPossibleNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
};

export const isFloat = (n: any) => {
  return n === +n && n !== (n | 0);
};

export const isInteger = (n: any) => {
  return n === +n && n === (n | 0);
};

export const isOdd = (num: number) => {
  return num % 2;
};

export const groupMessagesByDay = (messages: any) => {
  const messagesOrdered = messages?.sort((a: any, b: any) =>
    a?.createdAt > b?.createdAt ? 1 : b?.createdAt > a?.createdAt ? -1 : 0
  );
  const groups: any = {};
  messagesOrdered?.forEach((message: any) => {
    const messageDate = moment(message?.createdAt).format("YYYY-MM-DD");
    let group = groups[messageDate];
    if (!group) {
      groups[messageDate] = [message];
    } else {
      group.push(message);
    }
  });
  return groups;
};

export const formatAsFriendlyPastDate = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  if (diff < 1000 * 60 * 60 * 24) {
    // less than a day
    // format as time ago e.g. '2 hours ago'
    return moment(date).fromNow();
  } else if (diff < 1000 * 60 * 60 * 24 * 7) {
    // less than a week
    // format as day of week e.g. 'Monday', 'Tuesday'
    return moment(date).format("dddd");
  } else {
    // more than a week
    // format as date e.g. '12 May'
    return moment(date).format("MMM D");
  }
};

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
