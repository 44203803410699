import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { PLAYER_QUERY_KEY } from "./playerQueriesKeys";
import { PlayerServices } from "../../services/apis/Player/PlayerServices";
import { TEAMS_QUERY_KEY } from "../teamsQueries/teamsQueriesKeys";

export const useFetchAvailablePlayers = (id: string, params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYERS],
    queryFn: () => PlayerServices.getAvailablePlayers(id, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddPlayerToTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PlayerServices.addPlayerToTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          PLAYER_QUERY_KEY.FETCH_PLAYERS,
          TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS,
        ],
      });
    },
  });
};

export const useFetchPlayer = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER, id],
    queryFn: () => PlayerServices.getPlayerById(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchPlayerStats = (firstName: string, lastName: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER_STATS],
    queryFn: () => PlayerServices.getPlayerStatsByName(firstName, lastName),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!firstName && !!lastName,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchPlayerInjures = (firstName: string, lastName: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER_INJURES],
    queryFn: () => PlayerServices.getPlayerInjuresByName(firstName, lastName),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!firstName && !!lastName,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useRemovePlayer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PlayerServices.removePlayer,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          PLAYER_QUERY_KEY.FETCH_PLAYERS,
          TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS,
        ],
      });
    },
  });
};
