import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus-icon.svg";
import player_demo from "../../../../assets/images/player_demo.png";
import usePlayerDetailsHook from "../hooks/usePlayerDetails";
import clsx from "clsx";
import TableStatisticListing from "../../../../components/TableCardsListing/TableStatisticListing";

export const PlayerDetailsModal = ({
  id,
  children,
  enabled,
}: {
  id: string;
  children: any;
  enabled: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <PlayerDetails id={id} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "xxl",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <div onClick={enabled ? openModal : () => {}}>{children}</div>
    </>
  );
};

const PlayerDetails = ({
  closeModal,
  id,
}: {
  closeModal?: () => void;
  id: string;
}) => {
  const {
    data,
    columsFantasy,
    isFetching,
    columsPass,
    columsRushing,
    columsPoints,
    columsImpact,
    columsDetails,
    columsSpecs,
    columsDateTrHistory,
    columsPriceTrHistory,
    columsTeamTrHistory,
    columsDetailsTrHistory,
    playerStats,
    playerInjures,
    activeTab,
    setActiveTab,
    activeYear,
    setActiveYear,
    bid,
    setBid,
    years,
    BID_STEP,
  } = usePlayerDetailsHook({ closeModal, id });

  //TODO: remove test data when real data will ready
  const testData3: any[] = [];
  return (
    <>
      <div className="w-full flex items-center">
        <img
          src={data?.player?.officialImageSrc || player_demo}
          alt="CutThroat"
          className="w-[160px] h-[160px] rounded-[30px] mr-6"
        />
        <div className="flex flex-col gap-4">
          <p className="text-[32px] font-absoluteEmpire">
            {data?.player?.firstName} {data?.player?.lastName}
          </p>
          <div className="h-[40px] rounded-[8px] bg-modal-inputBg w-[50%]">
            <div className="w-[40px] h-[40px] rounded-[8px] bg-gradient-to-br from-red-light-1 to-red-light-2 flex justify-center items-center">
              <p className="font-gilroyMedium">
                {data?.player?.primaryPosition}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col justify-center items-center pr-3">
              <p className="text-text-subGrey font-gilroyMedium">Age</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.age}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Height</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.height}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Weight</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.weight} lbs
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Exp</p>
              <p className="text-base font-gilroyBold mt-1">-</p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">College</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.college}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          </div>
        </div>
      </div>

      <div className="mt-4 flex gap-4 justify-between">
        <div className="w-[25%] py-4 px-6 bg-modal-inputBg border-[1px] border-modal-modalStroke rounded-[12px] flex flex-col justify-start items-start">
          <p className="text-text-subGrey font-gilroyMedium">
            {data?.player?.primaryPosition}
          </p>
          <p className="text-[20px] font-gilroyBold mt-2">-</p>
        </div>

        <div className="w-[25%] py-4 px-6 bg-modal-inputBg border-[1px] border-modal-modalStroke rounded-[12px] flex flex-col justify-start items-start">
          <p className="text-text-subGrey font-gilroyMedium">Overall</p>
          <p className="text-[20px] font-gilroyBold mt-2">-</p>
        </div>

        <div className="w-[25%] py-4 px-6 bg-modal-inputBg border-[1px] border-modal-modalStroke rounded-[12px] flex flex-col justify-start items-start">
          <p className="text-text-subGrey font-gilroyMedium">Rostered</p>
          <p className="text-[20px] font-gilroyBold mt-2">
            {data?.player?.currentRosterStatus}
          </p>
        </div>

        <div className="w-[25%] py-4 px-6 bg-modal-inputBg border-[1px] border-modal-modalStroke rounded-[12px] flex flex-col justify-start items-start">
          <p className="text-text-subGrey font-gilroyMedium">Started</p>
          <p className="text-[20px] font-gilroyBold mt-2">-</p>
        </div>
      </div>

      <div className="mt-7">
        <div className="flex gap-6">
          <p
            onClick={() => setActiveTab(1)}
            className={clsx(
              "text-base text-text-subGrey cursor-pointer",
              activeTab === 1 && "text-text-white font-gilroyBold"
            )}
          >
            Game Logs
          </p>
          <p
            onClick={() => setActiveTab(2)}
            className={clsx(
              "text-base text-text-subGrey cursor-pointer",
              activeTab === 2 && "text-text-white font-gilroyBold"
            )}
          >
            Injuries History
          </p>
          <p
            onClick={() => setActiveTab(3)}
            className={clsx(
              "text-base text-text-subGrey cursor-pointer",
              activeTab === 3 && "text-text-white font-gilroyBold"
            )}
          >
            Transaction History
          </p>
        </div>
        <div className="flex justify-between items-center">
          <div className="mt-4 p-[2px] rounded-[8px] bg-filterContainer flex w-[293px]">
            {years?.map((item: string) => (
              <div
                key={item}
                onClick={() => setActiveYear(item)}
                className={clsx(
                  "py-2 px-6 bg-gradient-to-br flex justify-center items-center rounded-[8px] cursor-pointer font-gilroyBold",
                  activeYear === item &&
                    "from-red-bgGradient2-1 to-red-bgGradient2-2"
                )}
              >
                {item}
              </div>
            ))}
          </div>

          <div className="flex items-center gap-2 mt-6">
            <Button
              leftIcon={<Minus />}
              mode="secondary"
              className="!h-[41px] !w-[40px]"
              onClick={() => {
                if (bid > BID_STEP) {
                  setBid(bid - BID_STEP);
                }
              }}
            >
              {" "}
            </Button>
            <div className="w-[120px] h-[48px] bg-modal-inputBg rounded-[8px] border-[2px] border-filterContainer px-5 py-3 flex justify-center items-center">
              <p className="text-base text-text-subGrey font-gilroyBold">
                ${bid}.00
              </p>
            </div>
            <Button
              leftIcon={<Plus />}
              mode="primary"
              className="!h-[41px] !w-[40px]"
              onClick={() => setBid(bid + BID_STEP)}
            >
              {" "}
            </Button>
            <Button
              mode="primary"
              className="!h-[48px] max-w-[100px] ml-4 rounded-[8px]"
              onClick={() => {}}
            >
              Bid ${bid}
            </Button>
          </div>
        </div>
      </div>
      {activeTab === 1 && (
        <>
          {playerStats ? (
            <div className="flex w-full justify-between mt-6">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Fantasy
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                </div>
                <TableStatisticListing
                  columns={columsFantasy}
                  onClickRow={() => {}}
                  dataSource={playerStats ? [playerStats] : []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Pass
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                </div>
                <TableStatisticListing
                  columns={columsPass}
                  onClickRow={() => {}}
                  dataSource={playerStats ? [playerStats] : []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Rushing
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4" />
                </div>
                <TableStatisticListing
                  columns={columsRushing}
                  onClickRow={() => {}}
                  dataSource={playerStats ? [playerStats] : []}
                  overrideTableContainerClass="!px-0"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
            </div>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">No logs found</p>
          )}
        </>
      )}
      {activeTab === 2 && (
        <>
          {playerInjures ? (
            <div className="flex w-full justify-between mt-6">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Specification
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                </div>
                <TableStatisticListing
                  columns={columsSpecs}
                  onClickRow={() => {}}
                  dataSource={playerInjures ? [playerInjures] : []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium text-nowrap">
                    Average Points
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                </div>
                <TableStatisticListing
                  columns={columsPoints}
                  onClickRow={() => {}}
                  dataSource={playerInjures ? [playerInjures] : []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Impact
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                </div>
                <TableStatisticListing
                  columns={columsImpact}
                  onClickRow={() => {}}
                  dataSource={playerInjures ? [playerInjures] : []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <div className="flex items-center">
                  <div className="w-full h-[1px] bg-text-red mr-4" />
                  <p className="text-xs text-text-red font-gilroyMedium">
                    Details
                  </p>
                  <div className="w-full h-[1px] bg-text-red ml-4" />
                </div>
                <TableStatisticListing
                  columns={columsDetails}
                  onClickRow={() => {}}
                  dataSource={playerInjures ? [playerInjures] : []}
                  overrideTableContainerClass="!px-0"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
            </div>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">
              No injuries found
            </p>
          )}
        </>
      )}
      {activeTab === 3 && (
        <>
          {testData3?.length ? (
            <div className="flex w-full justify-between mt-6">
              <div className="w-[45%]">
                <TableStatisticListing
                  columns={columsDateTrHistory}
                  onClickRow={() => {}}
                  //TODO: remove test data when real data will ready
                  dataSource={testData3 ?? []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-[40%]">
                <TableStatisticListing
                  columns={columsPriceTrHistory}
                  onClickRow={() => {}}
                  //TODO: remove test data when real data will ready
                  dataSource={testData3 ?? []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-[50%]">
                <TableStatisticListing
                  columns={columsTeamTrHistory}
                  onClickRow={() => {}}
                  //TODO: remove test data when real data will ready
                  dataSource={testData3 ?? []}
                  overrideTableContainerClass="!px-0 mr-1"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
              <div className="w-full">
                <TableStatisticListing
                  columns={columsDetailsTrHistory}
                  onClickRow={() => {}}
                  //TODO: remove test data when real data will ready
                  dataSource={testData3 ?? []}
                  overrideTableContainerClass="!px-0"
                  containerClass="border-0"
                  loading={isFetching}
                  isPlayers
                />
              </div>
            </div>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">
              No transactions found
            </p>
          )}
        </>
      )}
    </>
  );
};

export default PlayerDetailsModal;
