import { useFetchLeague } from "../../../../../queries/leaguesQueries/leaguesQueries";
import useAuthStore from "../../../../../stores/auth/auth";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useRef, useState } from "react";
import { useFetchUserDetails } from "../../../../../queries/userQueries/userQueries";
import { useFetchUserTransactions } from "../../../../../queries/paymentsQueries/paymentsQueries";

const useHomeHook = () => {
  const { userData } = useAuthStore.getState();
  const { data: user } = useFetchUserDetails(userData?.id!);
  const [activeLeagueId, setActiveLeagueId] = useState<any>(
    user?.league?.[0]?.id
  );
  const { data, refetch } = useFetchLeague(
    activeLeagueId || user?.league?.[0]?.id
  );
  const { data: transactions } = useFetchUserTransactions(userData?.id, {
    limit: 5,
  });
  const navigate = useNavigate();

  const prevButtonFunc = useRef<any>(null);
  const nextButtonFunc = useRef<any>(null);

  useEffect(() => {
    refetch();
  }, [user, activeLeagueId]);

  const balance =
    Number(user?.balance?.amount) + Number(user?.balance?.winning_amount);

  const isJoined = data?.team?.filter(
    (item: any) => item?.userId === userData?.id
  )?.length;

  const inProgress = data?.status === "inProgress";

  const { regularTeams, teamsAtRisk, eliminatedTeams } = (
    data?.team || []
  ).reduce(
    (acc: any, item: any) => {
      switch (item?.status) {
        case "Confirmed":
          acc.regularTeams.push(item);
          break;
        case "AtRisk":
          acc.teamsAtRisk.push(item);
          break;
        case "Eliminated":
          acc.eliminatedTeams.push(item);
          break;
        default:
          break;
      }
      return acc;
    },
    { regularTeams: [], teamsAtRisk: [], eliminatedTeams: [] }
  );

  const CustomButtonGroup = ({ next, previous }: any) => {
    prevButtonFunc.current = previous;
    nextButtonFunc.current = next;
    return <></>;
  };
  return {
    userData,
    user,
    activeLeagueId,
    setActiveLeagueId,
    data,
    transactions,
    navigate,
    prevButtonFunc,
    nextButtonFunc,
    balance,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    CustomButtonGroup,
  };
};
export default useHomeHook;
