import {
  useFetchAllUsers,
  useFetchLatestTransactions,
  useFetchLeaguesCount,
  useFetchStats,
  useFetchUsersCount,
} from "../../../../queries/adminQueries/adminQueries";
import { useFetchLeaguesList } from "../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate } from "react-router-dom";

const useDashboard = () => {
  const { data } = useFetchStats();
  const { data: usersCount } = useFetchUsersCount();
  const { data: leaguesCount } = useFetchLeaguesCount();
  const { data: users } = useFetchAllUsers(true);
  const { data: latestTransactions } = useFetchLatestTransactions();
  const { data: leagues } = useFetchLeaguesList({ limit: 5 });
  const navigate = useNavigate();
  return {
    data,
    usersCount,
    leaguesCount,
    users,
    latestTransactions,
    leagues,
    navigate,
  };
};
export default useDashboard;
