import React from "react";
import clsx from "clsx";
import {TablePropsType, TableSizes} from "./type";

const classNameSizes: Record<TableSizes, string> = {
    sm: "h-[31px] pr-6 pl-4 py-[6px]",
    lg: "h-[52px] p-4",
};

const tableClassNameSize: Record<TableSizes, string> = {
    sm: "py-[10px]",
    lg: "",
};

export const Table: React.FC<TablePropsType> = ({
                                                    columns = [],
                                                    dataSource = [],
                                                    onClickRow,
                                                    emptyState,
                                                    tableClassName,
                                                    footer = null,
                                                    size = "lg",
                                                    overrideTableContainerClass,
                                                }) => {
    return (
        <div className={clsx("overflow-hidden")}>
            <div
                className={clsx(
                    "bg-card-bgGreyDark rounded-b-[12px] px-6 overflow-hidden",
                    tableClassNameSize[size],
                    overrideTableContainerClass
                )}
            >
                <table
                    className={clsx(
                        "min-w-full text-left border-separate border-spacing-y-6",
                        tableClassName
                    )}
                >
                    <tbody className="text-base font-gilroyBold gap-4">
                    {!dataSource?.length ? (
                        <tr>
                            <td colSpan={100}>
                                {emptyState ?? (
                                    <p className="text-base text-text-subGrey">
                                        No data available
                                    </p>
                                )}
                            </td>
                        </tr>
                    ) : (
                        dataSource?.map((item, index) => (
                            <tr
                                tabIndex={0}
                                key={index}
                                className={clsx(
                                    "hover:bg-surface-container-4 focus:bg-primary-2-light",
                                    onClickRow && "cursor-pointer"
                                )}
                                onClick={(e) => {
                                    if (onClickRow) {
                                        onClickRow(index, item, e as any);
                                    }
                                }}
                            >
                                {columns.map(({dataIndex, render, tdClassName}, index) => {
                                    const lastColumn = columns?.length - 1 === index;
                                    const firstColumn = index === 0;
                                    return (
                                        <td
                                            key={dataIndex}
                                            style={{
                                                wordBreak: "break-word",
                                            }}
                                            className={clsx(
                                                "relative text-base max-w-xs leading-none bg-modal-inputBg !mb-10 border-modal-modalStroke",
                                                firstColumn &&
                                                "rounded-l-[8px] border-l-[1px] border-y-[1px]",
                                                lastColumn &&
                                                "rounded-r-[8px] border-r-[1px] border-y-[1px]",
                                                !firstColumn && !lastColumn && "border-y-[1px]",
                                                classNameSizes[size],
                                                tdClassName
                                            )}
                                        >
                                            {render
                                                ? render(item[dataIndex], item)
                                                : item[dataIndex]}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
            {footer ? (
                <div
                    className={clsx(
                        "relative text-base leading-none",
                        classNameSizes[size]
                    )}
                >
                    {footer}
                </div>
            ) : null}
        </div>
    );
};
