import { useFetchUserTransactions } from "../../../../../queries/paymentsQueries/paymentsQueries";
import { useFetchUserDetails } from "../../../../../queries/userQueries/userQueries";
import useAuthStore from "../../../../../stores/auth/auth";
import { useNavigate } from "react-router";

const useFinances = () => {
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const { data } = useFetchUserDetails(userData?.id);
  const { data: transactions } = useFetchUserTransactions(userData?.id, {
    limit: 10,
  });

  const balance =
    Number(data?.balance?.amount) + Number(data?.balance?.winning_amount);

  return { navigate, data, transactions, balance };
};
export default useFinances;
