import { AuthServices } from "../../../../services/apis/Auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useSignupHook = () => {
  const navigate = useNavigate();
  const [activeTab, setSetActiveTab] = useState<number>(1);
  const [isReffered, setIsReffered] = useState<boolean>(false);
  const [isRemembered, setIsRemembered] = useState<boolean>(false);

  const formOptions = useForm({
    defaultValues: {
      username: "",
      password: "",
      name: "",
      email: "",
      phone: "",
      reffered: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = formOptions;

  const checkEmailUsername = async (data: {
    username?: string;
    email?: string;
  }) => {
    try {
      await AuthServices.checkEmailUsername(data);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (activeTab === 1 && isValid) {
        setSetActiveTab(2);
      } else if (activeTab === 2) {
        const phone = data.phone ? { phone: data.phone } : {};
        await AuthServices.register({
          name: data.name,
          email: data.email,
          username: data.username,
          password: data.password,
          rememberMe: isRemembered,
          ...phone,
        });
        window.location.reload();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  });

  return {
    control,
    errors,
    activeTab,
    isReffered,
    isRemembered,
    navigate,
    setIsReffered,
    setIsRemembered,
    setSetActiveTab,
    checkEmailUsername,
    onSubmit,
  };
};

export default useSignupHook;
