import registration_bg from "../../../assets/images/signup-bg.png";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import { Button } from "../../../components/Button/Button";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { Controller } from "react-hook-form";
import LabeledInput from "../../../components/LabeledInput/LabeledInput";
import LabeledPasswordInput from "../../../components/LabeledPasswordInput/LabeledPasswordInput";
import {
  containsNumber,
  containsUppercase,
  emailValidation,
  phoneValidation,
} from "../../../utils/helpers";
import useSignupHook from "./hooks/useSignup";

const Signup = () => {
  const {
    control,
    errors,
    activeTab,
    isReffered,
    isRemembered,
    navigate,
    setIsReffered,
    setIsRemembered,
    setSetActiveTab,
    checkEmailUsername,
    onSubmit,
  } = useSignupHook();

  return (
    <div className="flex flex-row h-svh p-0 md:p-2">
      <img
        src={registration_bg}
        alt="CutThroat"
        className="w-[50%] sm:w-full hidden sm:block"
      />
      <div className="p-10 w-full flex flex-col items-center bg-auth-mobile bg-cover bg-center md:bg-none">
        <div className="relative w-full max-w-[397px] flex justify-center">
          {activeTab !== 1 && (
            <div
              onClick={() => setSetActiveTab(activeTab - 1)}
              className="absolute left-0 flex flex-row justify-center items-center cursor-pointer"
            >
              <Back />
              <p className="text-text-white ml-2 text-base font-gilroyMedium">
                Back
              </p>
            </div>
          )}
          <Logo />
        </div>

        <div className="mt-[64px] order-3 max-w-[397px] h-full">
          <h1 className="text-text-white font-absoluteEmpire text-[40px] text-center">
            SIGN UP
          </h1>
          <p className="mt-2 text-text-subGrey text-center">
            Sign up now to embark on an epic journey filled with strategy,
            competition, and gridiron glory
          </p>
          {activeTab === 1 && (
            <div>
              <Controller
                control={control}
                name="username"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter your username"
                    error={errors?.username?.message}
                    errorClassName="text-text-red"
                    label="Your Username"
                    containerClassName="mt-[32px]"
                  />
                )}
                rules={{
                  minLength: {
                    value: 6,
                    message: `<div>
                    <p>The username must meet complexity requirements:</p>
                    <div className="flex flex-row items-center mt-2">
                    <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                 <p>Minimum 6 characters</p>
                  </div></div>`,
                  },
                  validate: {
                    value: async (value) => {
                      const check = await checkEmailUsername({
                        username: value,
                      });
                      if (!check) {
                        return "<p>Username has already been taken</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <LabeledPasswordInput
                    {...field}
                    placeholder="Enter your password"
                    error={errors?.password?.message}
                    errorClassName="text-text-red"
                    label="Your password"
                    containerClassName="mt-6"
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      if (
                        value?.length < 8 ||
                        !containsUppercase(value) ||
                        !containsNumber(value)
                      ) {
                        return `<div><p>The password must meet following requirements:</p>
                            ${
                              value?.length < 8
                                ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>Minimum 8 characters</p></div>`
                                : ""
                            }
                         ${
                           !containsNumber(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>At least one number</p></div>`
                             : ""
                         }
                         ${
                           !containsUppercase(value)
                             ? `<div className="flex flex-row items-center mt-2">
                            <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
                         <p>One uppercase letter</p></div>`
                             : ""
                         }
                         </div>`;
                      }
                      return true;
                    },
                  },
                }}
              />
              <Checkbox
                checked={isReffered}
                key={"invite"}
                containerClassName="my-6"
                onChange={() => setIsReffered(!isReffered)}
              >
                <p className="text-text-white text-xs ml-1 cursor-pointer">
                  I was referred
                </p>
              </Checkbox>
              {isReffered && (
                <Controller
                  control={control}
                  name="reffered"
                  render={({ field }) => (
                    <LabeledInput
                      {...field}
                      placeholder="Specify email"
                      error={errors?.reffered?.message}
                      errorClassName="text-text-red"
                      label="I was referred by"
                      containerClassName="mb-[32px]"
                    />
                  )}
                  rules={{
                    validate: {
                      value: async (value) => {
                        const check = await checkEmailUsername({
                          email: value,
                        });
                        if (check && isReffered) {
                          return "<p>Incorrect email. Please, check again</p>";
                        }
                        return true;
                      },
                    },
                  }}
                />
              )}

              <Button onClick={onSubmit} className="w-full text-base">
                Continue
              </Button>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter your name"
                    error={errors?.name?.message}
                    errorClassName="text-text-red"
                    label="Your Name"
                    containerClassName="mt-[32px]"
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message:
                      "<p>This is a wrong Name, please use your real name</p>",
                  },
                }}
              />
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter your email"
                    error={errors?.email?.message}
                    errorClassName="text-text-red"
                    label="Your Email"
                    containerClassName="mt-6"
                  />
                )}
                rules={{
                  pattern: {
                    value: emailValidation,
                    message:
                      "<p>The email address did not pass format validation, enter another address</p>",
                  },
                  validate: {
                    value: async (value) => {
                      const check = await checkEmailUsername({ email: value });
                      if (!check) {
                        return "<p>This email address is already in use, please enter a different one</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Enter your phone number"
                    error={errors?.phone?.message}
                    errorClassName="text-text-red"
                    label="Your Phone Number (Optional)"
                    containerClassName="mt-6"
                    required={false}
                  />
                )}
                rules={{
                  validate: {
                    value: (value) => {
                      if (value?.length && !phoneValidation(value)) {
                        return "<p>Invalid phone number</p>";
                      }
                      return true;
                    },
                  },
                }}
              />
              <Checkbox
                checked={isRemembered}
                key={"remember"}
                containerClassName="my-6"
                onChange={() => setIsRemembered(!isRemembered)}
              >
                <p className="text-text-white text-xs ml-1 cursor-pointer">
                  Remember this device for 30 days
                </p>
              </Checkbox>
              <Button onClick={onSubmit} className="w-full text-base">
                Continue
              </Button>
            </div>
          )}
          <p className="text-text-white text-base font-gilroyMedium text-center mt-4">
            Already have an account?{" "}
            <span
              onClick={() => navigate("/login")}
              className="underline cursor-pointer"
            >
              Login
            </span>
          </p>
        </div>
        <div className="flex flex-row mt-auto gap-2 order-2 w-full max-w-[397px] pt-10 sm:order-last">
          <div className="flex flex-col w-[50%]">
            <p
              className={`font-gilroySemibold text-center ${
                activeTab === 1 || activeTab === 2
                  ? "bg-gradient-to-br from-red-accent-1 to-red-accent-2 bg-clip-text text-transparent"
                  : "text-text-greyDark"
              } `}
            >
              Credentials
            </p>
            <div
              className={`h-2 w-full rounded-[8px] ${
                activeTab === 1 || activeTab === 2
                  ? "bg-gradient-to-br from-red-bgGradient2-1 to-red-bgGradient2-2"
                  : "bg-gradient-to-br from-elements-chipGradient-1 to-elements-chipGradient-2"
              } `}
            />
          </div>
          <div className="flex flex-col w-[50%]">
            <p
              className={`font-gilroySemibold text-center ${
                activeTab === 2
                  ? "bg-gradient-to-br from-red-accent-1 to-red-accent-2 bg-clip-text text-transparent"
                  : "text-text-greyDark"
              } `}
            >
              Personal data
            </p>
            <div
              className={`h-2 w-full rounded-[8px] ${
                activeTab === 2
                  ? "bg-gradient-to-br from-red-bgGradient2-1 to-red-bgGradient2-2"
                  : "bg-gradient-to-br from-elements-chipGradient-1 to-elements-chipGradient-2"
              } `}
            />
          </div>
        </div>

        <div className="mt-4 order-last flex items-center">
          <p className="text-xs md:text-sm text-text-grey text-center">
            <span
              onClick={() => navigate("/privacy-policy")}
              className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
            >
              Privacy Policy,
            </span>{" "}
            <span
              onClick={() => navigate("/terms-conditions")}
              className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer"
            >
              Terms and Conditions,
            </span>{" "}
            <span className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer">
              FAQ,
            </span>{" "}
            <a href="mailto:cutthroatfantasysports@gmail.com">
              Contact us:{" "}
              <span className="text-xs md:text-sm text-text-grey text-center underline cursor-pointer">
                cutthroatfantasysports@gmail.com
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
