import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const PlayerServices = {
  getAvailablePlayers: async (leagueId: string, params: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
      params,
    };
    const endPoint = `/api/game/player/available/${leagueId}`;
    try {
      const availablePlayers = await axios.get(endPoint, config);
      return availablePlayers?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addPlayerToTeam: async (payload: any) => {
    const endPoint = `/api/game/player/add/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const availablePlayers = await axios.post(endPoint, {
        player_id: payload?.player_id,
      });
      return availablePlayers?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getPlayerById: async (playerId: string) => {
    const endPoint = `/api/game/player/${playerId}`;
    try {
      const player = await axios.get(endPoint);
      return player?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getPlayerStatsByName: async (firstName: string, lastName: string) => {
    const endPoint = `/api/game/player/stats/player`;
    try {
      const player = await axios.get(endPoint, {
        params: {
          firstName,
          lastName,
          latest: true,
        },
      });
      return player?.data?.message;
    } catch (error: any) {
      throw error?.error;
    }
  },
  getPlayerInjuresByName: async (firstName: string, lastName: string) => {
    const endPoint = `/api/game/player/injures/player`;
    try {
      const player = await axios.get(endPoint, {
        params: {
          firstName,
          lastName,
          latest: true,
        },
      });
      return player?.data?.message;
    } catch (error: any) {
      throw error?.error;
    }
  },
  removePlayer: async (payload: any) => {
    const endPoint = `/api/game/player/drop/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const player = await axios.delete(endPoint, { data: payload });
      return player?.data?.message;
    } catch (error) {
      throw error;
    }
  },
};
