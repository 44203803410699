import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const AdminServices = {
  getAllUsers: async (isTransactions?: boolean) => {
    const transactionsQuesry = isTransactions
      ? "?limit=5&createdAtSort=asc"
      : "";
    const endPoint = `/api/admin/user-management/users${transactionsQuesry}`;
    try {
      const allUsers = await axios.get(endPoint);
      return allUsers?.data;
    } catch (error) {
      throw error;
    }
  },
  getUserByID: async (userId: string) => {
    const endPoint = `/api/admin/user-management/user/${userId}`;
    try {
      const user = await axios.get(endPoint);
      return user?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  addCredits: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/funds/${payload?.userId}`;
    try {
      delete payload?.userId;
      const addCredits = await axios.post(endPoint, payload, config);
      return addCredits?.data;
    } catch (error) {
      throw error;
    }
  },
  banUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/ban`;
    try {
      const banUser = await axios.post(endPoint, payload, config);
      return banUser?.data;
    } catch (error) {
      throw error;
    }
  },
  unbanUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/unban`;
    try {
      const banUser = await axios.post(endPoint, payload, config);
      return banUser?.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/${payload?.userId}`;
    try {
      delete payload?.userId;
      const updateUser = await axios.patch(endPoint, payload, config);
      return updateUser?.data;
    } catch (error) {
      throw error;
    }
  },
  addBalance: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/balance/${payload?.userId}`;
    try {
      delete payload?.userId;
      const addBalance = await axios.patch(endPoint, payload, config);
      return addBalance?.data;
    } catch (error) {
      throw error;
    }
  },
  getUsersCount: async () => {
    const endPoint = `/api/admin/user-management/users/count`;
    try {
      const usersCount = await axios.get(endPoint);
      return usersCount?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getStats: async () => {
    const endPoint = `/api/admin/league-management/league/stats`;
    try {
      const stats = await axios.get(endPoint);
      return stats?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getLeaguesCount: async () => {
    const endPoint = `/api/game/league/active/count`;
    try {
      const leaguesCount = await axios.get(endPoint);
      return leaguesCount?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  getLatestTransactions: async () => {
    const endPoint = `/api/payment/transactions?status=completed&limit=2&amountSort=asc`;
    try {
      const latestTransactions = await axios.get(endPoint);
      return latestTransactions?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (userId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/user-management/user/${userId}`;
    try {
      const deleteUser = await axios.delete(endPoint, config);
      return deleteUser?.data;
    } catch (error) {
      throw error;
    }
  },
};
