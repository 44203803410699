import termsOfUse from "../../../configs/termsOfUse";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="px-5 py-5 md:px-20 md:py-10">
      <h1
        className="text-4xl font-gilriyBold text-text-red cursor-pointer mb-4"
        onClick={() => navigate("/")}
      >
        CutThroat Football
      </h1>
      <p className="text-3xl font-gilriyBold mb-4">Terms of Use</p>
      <p className="text-1xl mb-4">
        <span className="font-gilroyBold">IMPORTANT NOTICE:</span> PLEASE
        CAREFULLY READ THESE TERMS OF USE BEFORE USING THE SERVICE (defined
        below), AS THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. THIS AGREEMENT
        IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS
        DETAILED IN SECTION 15.
      </p>
      {termsOfUse?.map((item, index) => {
        return (
          <div key={index} className="mb-6">
            <h2 className="text-2xl font-bold">{item?.title}</h2>
            <p className="mt-4">{item?.content}</p>
          </div>
        );
      })}
      <p>Thank you for using CutThroat!</p>
      <p className="text-right">All rights reserved</p>
    </div>
  );
};

export default TermsAndConditions;
