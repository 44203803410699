import { useEffect, useState } from "react";
import AppContext from "./appContext";
import { socket } from "../socket";

const ContextProvider = (props: any) => {
  const [timers, setTimers] = useState({});
  const [completedDrafts, setCompletedDrafts] = useState({});
  const [activeDrafts, setActiveDraftd] = useState({});

  useEffect(() => {
    socket.on("draft-started", (data: any) => {
      setActiveDraftd((prev: any) => {
        return { ...prev, [data?.draftId]: data };
      });
    });
    socket.on("timer-update", (data: any) => {
      setTimers((prev: any) => {
        return { ...prev, [data?.draftId]: data };
      });
    });
    socket.on("draft-ended", (data: any) => {
      setCompletedDrafts((prev: any) => {
        return { ...prev, [data?.draftId]: data };
      });
    });
  }, []);

  return (
    <AppContext.Provider value={{ timers, completedDrafts, activeDrafts }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
