import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DRAFT_QUERY_KEY } from "./draftQueriesKeys";
import { DraftServices } from "../../services/apis/Draft/DraftServices";
import { useContext } from "react";
import appContext from "../../context/appContext";

export const useFetchDraft = (id: string, timeout_number?: number) => {
  const { timers, completedDrafts } = useContext(appContext);
  // @ts-ignore:next-line
  const completed = completedDrafts?.[id]?.status === "completed";
  // @ts-ignore:next-line
  const newRound = timers?.[id]?.timeRemaining === 1000;
  // @ts-ignore:next-line
  const newPick = timeout_number === timers?.[id]?.timeRemaining || undefined;
  const queryResult = useQuery<any>({
    queryKey: [DRAFT_QUERY_KEY.FETCH_DRAFT, completed, newRound, newPick],
    queryFn: () => DraftServices.getDraftDetails(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useMakePick = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.makePick,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_DRAFT],
      });
    },
  });
};

export const useFetchTeamQueue = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
    queryFn: () => DraftServices.getTeamQueue(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddToQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.addPlayerToQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useRemoveFromQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.removePlayerFromQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useRemoveAllQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.removeAllQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};
