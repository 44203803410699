import { forwardRef } from "react";
import clsx from "clsx";
import { TextAreaPropsType } from "./type";

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaPropsType>(
  (props, ref) => {
    const {
      placeholder = "Type value...",
      textAreaClassName = "",
      containerClassName = "",
      error,
      ...rest
    } = props;

    return (
      <div
        className={clsx(
          "flex items-center justify-between gap-2",
          "bg-card-bgGreyMedium text-text-white text-base text-left w-full block py-[11px] px-[20px]",
          "rounded-[25px] border-2 border-card-strokeGradient-1",
          "focus-within:bg-card-bgGreyLight",
          error &&
            "!border-red-bgGradient2-1 bg-red-bgDark focus-within:bg-red-bgDark",
          containerClassName
        )}
      >
        <textarea
          ref={ref}
          placeholder={placeholder}
          className={clsx(
            "bg-inherit w-full min-h-[100px] grow",
            "appearance-none focus:outline-none focus:shadow-outline",
            "disabled:cursor-not-allowed",
            "placeholder-text-subGrey placeholder:text-left flex-1 bg-transparent",
            textAreaClassName
          )}
          aria-invalid={!!error}
          aria-errormessage={error}
          {...rest}
        />
      </div>
    );
  }
);
