import { Button, Input, Modal, useModal } from "../../../../components";
import { ReactComponent as Search } from "../../../../assets/icons/search-icon.svg";
import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import clsx from "clsx";
import useAvailablePlayers from "../hooks/useAvailablePlayers";

export const AvailablePlayersModal = ({
  leagueId,
  team,
}: {
  leagueId: string;
  team?: any;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <AvailablePlayers team={team} leagueId={leagueId} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Available Players",
    content: addContent,
    size: "xxl",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        mode="primary"
        onClick={openModal}
        className="rounded-[8px] w-auto px-4 text-nowrap !h-10"
      >
        View Available Players
      </Button>
    </>
  );
};

const AvailablePlayers = ({
  leagueId,
  team,
}: {
  closeModal?: () => void;
  leagueId: string;
  team?: any;
}) => {
  const {
    isFetching,
    activeFilter,
    playersTypeFilter,
    allPlayers,
    handlePlayersType,
    columsPlayers,
    setActiveFilter,
    data,
    loadMore,
    allPlayersForFilter,
    setAllPlayersForFilters,
  } = useAvailablePlayers({ leagueId, team });

  return (
    <>
      <div className="w-full h-full rounded-[12px] mt-4">
        <div className="flex justify-between items-center">
          <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center p-[2px]">
            <div
              onClick={() => setActiveFilter("All")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                activeFilter === "All" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">All</p>
            </div>
            {!(activeFilter === "All" || activeFilter === "My bids") && (
              <div className="h-[32px] w-[1px] bg-card-stroke" />
            )}
            <div
              onClick={() => setActiveFilter("My bids")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                activeFilter === "My bids" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">My bids</p>
            </div>
            {!(activeFilter === "My bids" || activeFilter === "Saved") && (
              <div className="h-[32px] w-[1px] bg-card-stroke" />
            )}
            <div
              onClick={() => setActiveFilter("Saved")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                activeFilter === "Saved" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">Saved</p>
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-text-grey text-base">
              Free Agent Acquisition Budget:
            </p>
            <span className="text-text-white text-[24px] ml-1">
              ${team?.faab_balance}
            </span>
            <span className="text-text-grey text-base">/$1000</span>
          </div>
        </div>

        <div className="w-full rounded-t-[12px] flex justify-between items-center gap-2 mt-4">
          <div className="bg-gradient-to-br from-card-top-1 to-card-top-2 rounded-[6px] flex items-center">
            <div
              onClick={() => handlePlayersType("All")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "All" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">All</p>
            </div>
            {!(playersTypeFilter === "All" || playersTypeFilter === "QB") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => handlePlayersType("QB")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "QB" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">QB</p>
            </div>
            {!(playersTypeFilter === "QB" || playersTypeFilter === "WR") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => handlePlayersType("WR")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "WR" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">WR</p>
            </div>
            {!(playersTypeFilter === "WR" || playersTypeFilter === "Flex") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => handlePlayersType("Flex")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "Flex" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">Flex</p>
            </div>
            {!(playersTypeFilter === "Flex" || playersTypeFilter === "K") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => handlePlayersType("K")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "K" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">K</p>
            </div>
            {!(playersTypeFilter === "K" || playersTypeFilter === "DEF") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => handlePlayersType("DEF")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "DEF" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">DEF</p>
            </div>
          </div>
          <div className="w-full">
            <Input
              containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
              inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
              placeholder="Find Player..."
              rightIcon={
                <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                  <Search className="w-6 h-6" />
                </div>
              }
              onChange={(e) => {
                const filteredPlayers = allPlayers?.filter(
                  (item: any) =>
                    item?.player?.firstName
                      ?.toLocaleLowerCase()
                      ?.includes(e?.target?.value?.toLocaleLowerCase()) ||
                    item?.player?.lastName
                      ?.toLocaleLowerCase()
                      ?.includes(e?.target?.value?.toLocaleLowerCase())
                );
                setAllPlayersForFilters(filteredPlayers);
              }}
            />
          </div>
        </div>
        <TableCardsListing
          columns={columsPlayers}
          onClickRow={() => {}}
          dataSource={
            activeFilter === "My bids"
              ? allPlayersForFilter?.sort(
                  (a: any, b: any) => a?.bid?.priority - b?.bid?.priority
                )
              : allPlayersForFilter ?? []
          }
          overrideTableContainerClass="!overflow-scroll mt-4 !h-[45vh]"
          containerClass="border-0"
          loading={isFetching}
          isPlayers
        />
        {data?.length ? (
          <div className="w-full flex justify-center">
            <Button
              disabled={isFetching}
              className="rounded-[8px] !max-h-[40px]"
              onClick={() => loadMore()}
            >
              Load more
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AvailablePlayersModal;
