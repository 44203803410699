import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import useLeagueHook from "../hooks/useLeague";

export const DeleteLeagueModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <DeleteLeague closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Delete />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 xl:w-auto"
      >
        Delete League
      </Button>
    </>
  );
};

const DeleteLeague = ({ closeModal }: { closeModal?: () => void }) => {
  const { deleteLeague } = useLeagueHook();
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to delete this league?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        This action cannot be undone, any remaining funds will be returned to
        the teams. All associated data will be permanently removed.
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={deleteLeague}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Delete League
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default DeleteLeague;
