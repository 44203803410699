import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-icon.svg";
import { Controller } from "react-hook-form";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import useEditTeamHook from "../hooks/useEditTeam";

export const EditTeamModal = ({ name }: { name?: string }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <EditTeam name={name} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Edit Team",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Edit />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-auto px-4 text-nowrap !h-10"
      >
        Edit Team
      </Button>
    </>
  );
};

const EditTeam = ({
  closeModal,
  name,
}: {
  closeModal?: () => void;
  name?: string;
}) => {
  const { control, errors, onSubmit } = useEditTeamHook({ closeModal, name });
  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter team name"
            error={errors?.name?.message}
            errorClassName="text-text-red"
            label="Team Name"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

export default EditTeam;
