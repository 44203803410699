import {
  useFetchPlayer,
  useFetchPlayerInjures,
  useFetchPlayerStats,
} from "../../../../queries/playerQueries/playerQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import moment from "moment";
import { useState } from "react";

const WKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.wk || "-"}</p>
    </div>
  );
};
const OPPRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.opp || "-"}</p>
    </div>
  );
};
const PROJRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.proj || "-"}</p>
    </div>
  );
};
const FPTSRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.fpts || "-"}</p>
    </div>
  );
};
const SNPRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.snp || "-"}</p>
    </div>
  );
};
const ATTRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.passing?.passAttempts || "-"}</p>
    </div>
  );
};
const CMPRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.passing?.passCompletions || "-"}</p>
    </div>
  );
};
const YDRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.passing?.passYards || "-"}</p>
    </div>
  );
};
const TDRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.passing?.passTD || "-"}</p>
    </div>
  );
};
const INTRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.passing?.passInt || "-"}</p>
    </div>
  );
};
const DateRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>
        {data?.asOfDate ? moment(data?.asOfDate).format("DD/MM/YYYY") : "-"}
      </p>
    </div>
  );
};
const TypeRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.shortDescription || "-"}</p>
    </div>
  );
};
const RecoveryRender = ({ ...data }) => {
  const recoveryDays =
    Number(moment(data?.asOfDate).format("x")) -
    Number(moment(data?.actualReturn?.returnDate).format("x"));
  return (
    <div className="flex justify-between">
      <p>{recoveryDays ? `${moment(recoveryDays).format("DD")} days` : "-"}</p>
    </div>
  );
};
const BeforeRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.before || "-"}</p>
    </div>
  );
};
const AfterRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.after || "-"}</p>
    </div>
  );
};
const ChangeRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.change || "-"}</p>
    </div>
  );
};
const AVGRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.avg || "-"}</p>
    </div>
  );
};
const PercRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.perc || "-"}</p>
    </div>
  );
};
const ReasonRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.longDescription || "-"}</p>
    </div>
  );
};
const DateHRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.date || "-"}</p>
    </div>
  );
};
const TypeHRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.type || "-"}</p>
    </div>
  );
};
const PriceRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.price || "-"}</p>
    </div>
  );
};
const StatusRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <div className="h-[25px] w-[88px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 rounded-[16px] flex justify-center items-center">
        <p className="text-xs font-gilroyBold">{data?.status}</p>
      </div>
    </div>
  );
};
const TeamRender = ({ ...data }) => {
  return (
    <div className="flex items-center">
      <img
        src={data?.logo || demo_team_logo}
        alt="CutThroat"
        className="w-[32px] h-[32px] rounded-[50px] mr-2"
      />
      <p className="font-gilroyMedium">{data?.team || "-"}</p>
    </div>
  );
};
const DetailsRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.details || "-"}</p>
    </div>
  );
};
const ATTRushRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.rushing?.rushAttempts || "-"}</p>
    </div>
  );
};
const YDRushRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.rushing?.rushYards || "-"}</p>
    </div>
  );
};
const TDRushRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p>{data?.rushing?.rushTD || "-"}</p>
    </div>
  );
};

const usePlayerDetailsHook = ({
  closeModal,
  id,
}: {
  closeModal?: () => void;
  id: string;
}) => {
  const BID_STEP = 1;
  const { data, isFetching } = useFetchPlayer(id);
  const { data: playerStats } = useFetchPlayerStats(
    data?.player?.firstName,
    data?.player?.lastName
  );
  const { data: playerInjures } = useFetchPlayerInjures(
    data?.player?.firstName,
    data?.player?.lastName
  );
  const [activeTab, setActiveTab] = useState(1);
  const [activeYear, setActiveYear] = useState("2024");
  const [bid, setBid] = useState<number>(BID_STEP);
  const years = ["2024", "2023", "2022", "2021"];

  const onSubmit = () => {
    closeModal && closeModal();
  };

  const columsFantasy = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">WK</p>,
      dataIndex: "wk",
      render: (_: string, record: any) => {
        console.log(record);
        return <WKRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">OPP</p>,
      dataIndex: "opp",
      render: (_: string, record: any) => {
        return <OPPRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">PROJ</p>,
      dataIndex: "proj",
      render: (_: string, record: any) => {
        return <PROJRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">FPTS</p>,
      dataIndex: "fpts",
      render: (_: string, record: any) => {
        return <FPTSRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">SNP%</p>,
      dataIndex: "snp",
      render: (_: string, record: any) => {
        return <SNPRender {...record} />;
      },
    },
  ];

  const columsPass = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">ATT</p>,
      dataIndex: "att",
      render: (_: string, record: any) => {
        return <ATTRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">CMP</p>,
      dataIndex: "cmp",
      render: (_: string, record: any) => {
        return <CMPRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">YD</p>,
      dataIndex: "yd",
      render: (_: string, record: any) => {
        return <YDRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">TD</p>,
      dataIndex: "td",
      render: (_: string, record: any) => {
        return <TDRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">INT</p>,
      dataIndex: "int",
      render: (_: string, record: any) => {
        return <INTRender {...record} />;
      },
    },
  ];

  const columsRushing = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">ATT</p>,
      dataIndex: "attR",
      render: (_: string, record: any) => {
        return <ATTRushRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">YD</p>,
      dataIndex: "ydR",
      render: (_: string, record: any) => {
        return <YDRushRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">TD</p>,
      dataIndex: "tdR",
      render: (_: string, record: any) => {
        return <TDRushRender {...record} />;
      },
    },
  ];

  const columsSpecs = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Date</p>,
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Type</p>,
      dataIndex: "type",
      render: (_: string, record: any) => {
        return <TypeRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-xs font-gilroyMedium text-text-grey">Recovery</p>
      ),
      dataIndex: "recovery",
      render: (_: string, record: any) => {
        return <RecoveryRender {...record} />;
      },
    },
  ];

  const columsPoints = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Before</p>,
      dataIndex: "before",
      render: (_: string, record: any) => {
        return <BeforeRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">After</p>,
      dataIndex: "after",
      render: (_: string, record: any) => {
        return <AfterRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Change</p>,
      dataIndex: "change",
      render: (_: string, record: any) => {
        return <ChangeRender {...record} />;
      },
    },
  ];

  const columsImpact = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">AVG</p>,
      dataIndex: "avg",
      render: (_: string, record: any) => {
        return <AVGRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">%</p>,
      dataIndex: "perc",
      render: (_: string, record: any) => {
        return <PercRender {...record} />;
      },
    },
  ];

  const columsDetails = [
    {
      title: (
        <p className="text-xs font-gilroyMedium text-text-grey">
          Reason of the Injury
        </p>
      ),
      dataIndex: "reason",
      render: (_: string, record: any) => {
        return <ReasonRender {...record} />;
      },
    },
  ];

  const columsDateTrHistory = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Date</p>,
      dataIndex: "dateH",
      render: (_: string, record: any) => {
        return <DateHRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Type</p>,
      dataIndex: "typeH",
      render: (_: string, record: any) => {
        return <TypeHRender {...record} />;
      },
    },
  ];

  const columsPriceTrHistory = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Price</p>,
      dataIndex: "price",
      render: (_: string, record: any) => {
        return <PriceRender {...record} />;
      },
    },
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Status</p>,
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
    },
  ];

  const columsTeamTrHistory = [
    {
      title: <p className="text-xs font-gilroyMedium text-text-grey">Team</p>,
      dataIndex: "team",
      render: (_: string, record: any) => {
        return <TeamRender {...record} />;
      },
    },
  ];
  const columsDetailsTrHistory = [
    {
      title: (
        <p className="text-xs font-gilroyMedium text-text-grey">Details</p>
      ),
      dataIndex: "details",
      render: (_: string, record: any) => {
        return <DetailsRender {...record} />;
      },
    },
  ];

  return {
    onSubmit,
    data,
    columsFantasy,
    isFetching,
    columsPass,
    columsRushing,
    columsPoints,
    columsImpact,
    columsDetails,
    columsSpecs,
    columsDateTrHistory,
    columsPriceTrHistory,
    columsTeamTrHistory,
    columsDetailsTrHistory,
    playerStats,
    playerInjures,
    activeTab,
    setActiveTab,
    activeYear,
    setActiveYear,
    bid,
    setBid,
    years,
    BID_STEP,
  };
};

export default usePlayerDetailsHook;
