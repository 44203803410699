import { Button, Input, Modal, useModal } from "../../../../components";
import { ReactComponent as Bid } from "../../../../assets/icons/bid-icon.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Pencil } from "../../../../assets/icons/pencil-icon.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus-icon.svg";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { useState } from "react";
import { Popup } from "../../../../components/Popup/Popup";
import {
  useDeleteBid,
  useEditBid,
  useMakeBid,
} from "../../../../queries/waiverQueries/waiverQueries";
import { isNumeric } from "../../../../utils/helpers";

export const MakeBidModal = ({
  player,
  team,
  bid,
}: {
  player: any;
  team: any;
  bid?: any;
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <MakeBid
      team={team}
      player={player}
      closeModal={closeModal}
      bid={bid}
      isDelete={isDelete}
    />
  );
  const { modalControl, openModal } = useModal({
    title: isDelete ? "Delete a bid" : bid ? "Change a bid" : "Add a bid",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      {bid ? (
        <div className="flex gap-2">
          <Button
            leftIcon={<Pencil />}
            mode="secondary"
            onClick={() => {
              setIsDelete(false);
              openModal();
            }}
            className="rounded-[8px] w-auto px-4 text-nowrap !h-10 !w-[160px]"
          >
            Bidded ${bid?.amount}
          </Button>
          <Button
            leftIcon={<Delete />}
            mode="secondary"
            onClick={() => {
              setIsDelete(true);
              openModal();
            }}
            className="rounded-[8px] !h-10 !w-[40px]"
          >
            {""}
          </Button>
        </div>
      ) : (
        <Button
          leftIcon={<Bid />}
          mode="primary"
          onClick={() => {
            setIsDelete(false);
            openModal();
          }}
          className="rounded-[8px] w-auto px-4 text-nowrap !h-10 !w-[208px]"
        >
          Make a bid
        </Button>
      )}
    </>
  );
};

const MakeBid = ({
  closeModal,
  player,
  team,
  bid,
  isDelete,
}: {
  closeModal?: () => void;
  player: any;
  team: any;
  bid?: any;
  isDelete?: boolean;
}) => {
  const BID_STEP = 1;
  const { mutateAsync } = useMakeBid();
  const { mutateAsync: updateBid } = useEditBid();
  const { mutateAsync: deleteBid } = useDeleteBid();
  const [bidStep, setBid] = useState<number>(bid?.amount || BID_STEP);

  const makeBid = async () => {
    try {
      await mutateAsync({
        teamId: team?.id,
        playerId: player?.id,
        amount: bidStep,
      });
    } catch (error: any) {
    } finally {
      closeModal?.();
    }
  };

  const changeBid = async () => {
    try {
      await updateBid({
        bidId: bid?.id,
        amount: bidStep,
      });
    } catch (error: any) {
    } finally {
      closeModal?.();
    }
  };
  const onBidDelete = async () => {
    try {
      await deleteBid({
        bidId: bid?.id,
      });
    } catch (error: any) {
    } finally {
      closeModal?.();
    }
  };

  return (
    <>
      <div className="flex flex-col items-center w-full mt-6">
        <img
          src={player?.logo || demo_team_logo}
          alt="CutThroat"
          className="w-[96px] h-[96px] rounded-[50px]"
        />
        <p className="text-[24px] font-gilroyBold mt-2">
          {isDelete ? "Delete " : bid ? "Change " : "Make "} a bid for{" "}
          {player?.firstName} {player?.lastName}{" "}
          {`(${player?.primaryPosition})`}
        </p>
        {!isDelete && (
          <div className="flex items-center gap-2 mt-6">
            <Button
              leftIcon={<Minus />}
              mode="secondary"
              className="!h-[39px] !w-[40px]"
              onClick={() => {
                if (bidStep > BID_STEP && typeof Number(bidStep) === "number") {
                  setBid(Number(bidStep) - BID_STEP);
                }
              }}
            >
              {""}
            </Button>
            <Input
              value={bidStep}
              containerClassName="!w-[160px] h-[52px] bg-modal-inputBg rounded-[30px] border-[2px] border-filterContainer py-3"
              inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
              placeholder="Enter your bid"
              rightIcon={
                <Popup
                  data={
                    <p className="text-base text-left">
                      You can place a bet within the total Free Agent
                      Acquisition Budget (FAAB) of your team. Your current
                      budget is ${team?.faab_balance}.
                    </p>
                  }
                />
              }
              onChange={(e: any) => {
                if (isNumeric(e?.target?.value)) {
                  setBid(Number(e?.target?.value));
                }
              }}
            />
            <Button
              leftIcon={<Plus />}
              mode="primary"
              className="!h-[39px] !w-[40px]"
              onClick={() => {
                if (bidStep >= 0 && typeof Number(bidStep) === "number") {
                  setBid(Number(bidStep) + BID_STEP);
                }
              }}
            >
              {""}
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          onClick={isDelete ? onBidDelete : bid ? changeBid : makeBid}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          {isDelete ? "Delete " : bid ? "Change " : "Add "} bid
        </Button>
      </div>
    </>
  );
};

export default MakeBidModal;
