import { useEffect, useState } from "react";
import clsx from "clsx";
import { Button, Input } from "../../../../components";
import { ReactComponent as Search } from "../../../../assets/icons/search-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as Close } from "../../../../assets/icons/close-icon.svg";
import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import {
  useAddToQueue,
  useFetchTeamQueue,
  useMakePick,
  useRemoveAllQueue,
  useRemoveFromQueue,
} from "../../../../queries/draftQueries/draftQueries";
import useAuthStore from "../../../../stores/auth/auth";
import { toast } from "react-toastify";

const RKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p className="font-gilroyBold">{data?.rk || "-"}</p>
    </div>
  );
};
const PlayerRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center mt-1">
        <img
          src={data?.logo || demo_team_logo}
          alt="CutThroat"
          className="w-[40px] h-[40px] rounded-[50px] mr-3"
        />
        <div>
          <p className="font-gilroyBold text-base">{data?.name}</p>
          <p className="text-text-grey text-xs font-gilroyMedium">
            {data?.position}
          </p>
        </div>
      </div>
    </div>
  );
};

const ActionsRender = ({ ...data }) => {
  const { userData } = useAuthStore.getState();
  const { mutateAsync } = useMakePick();
  const { mutateAsync: addToQueue } = useAddToQueue();

  return (
    <div className="flex gap-2">
      <Button
        mode="secondary"
        className="rounded-[8px] !h-[40px] w-auto px-3"
        leftIcon={<Plus />}
        onClick={async () =>
          await addToQueue({
            leagueId: data?.draftId,
            userId: userData?.id,
            playerId: data?.playerId,
            teamId: data?.teamId,
          })
        }
      >
        Add to queue
      </Button>
      <Button
        disabled={!data?.canMakePick}
        onClick={async () => {
          try {
            await mutateAsync({
              leagueId: data?.leagueId,
              userId: userData?.id,
              playerId: data?.playerId,
            });
          } catch (error: any) {
            toast.error(error?.message);
          }
        }}
        mode="primary"
        className="rounded-[8px] !h-[40px] w-auto px-4"
      >
        Choose
      </Button>
    </div>
  );
};

const PlayersList = ({
  teamId,
  canMakePick,
}: {
  teamId: string;
  canMakePick: boolean;
}) => {
  const { data: team, isFetching } = useFetchTeamDetails(teamId!);
  const { data: queque } = useFetchTeamQueue(teamId!);
  const { mutateAsync: removePlayerFromQueue } = useRemoveFromQueue();
  const { mutateAsync: removeAllQueue } = useRemoveAllQueue();

  const columsPlayers = [
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">RK</p>,
      dataIndex: "rk",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">
          Selected Players
        </p>
      ),
      dataIndex: "SelectedPlayers",
      render: (_: string, record: any) => {
        return <PlayerRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">ADP</p>,
      dataIndex: "adp",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">ATT</p>,
      dataIndex: "att",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "yds",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TD</p>,
      dataIndex: "td",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Actions</p>
      ),
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            draftId={team?.draftId}
            canMakePick={canMakePick}
            {...record}
          />
        );
      },
    },
  ];
  const [activeFilter, setActiveFilter] = useState("All");
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    setPlayers(team?.player);
  }, [isFetching]);

  return (
    <div className="h-[50vh] w-full bg-modal-bgModal backdrop-blur-sm fixed bottom-0 py-4 px-6 flex gap-4">
      <div className="w-[70%] h-full bg-modal-grey rounded-[12px] border-[1px] border-card-stroke">
        <div className="w-full p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
          <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center">
            <div
              onClick={() => setActiveFilter("All")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "All" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">All</p>
              <p className="text-xs">{team?.player?.length}/16</p>
            </div>
            {!(activeFilter === "All" || activeFilter === "QB") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => setActiveFilter("QB")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "QB" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">QB</p>
              <p className="text-xs">0/2</p>
            </div>
            {!(activeFilter === "QB" || activeFilter === "WR") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => setActiveFilter("WR")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "WR" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">WR</p>
              <p className="text-xs">0/2</p>
            </div>
            {!(activeFilter === "WR" || activeFilter === "Flex") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => setActiveFilter("Flex")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "Flex" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">Flex</p>
              <p className="text-xs">0/2</p>
            </div>
            {!(activeFilter === "Flex" || activeFilter === "K") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => setActiveFilter("K")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "K" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">K</p>
              <p className="text-xs">0/2</p>
            </div>
            {!(activeFilter === "K" || activeFilter === "DEF") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <div
              onClick={() => setActiveFilter("DEF")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                activeFilter === "DEF" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">DEF</p>
              <p className="text-xs">0/2</p>
            </div>
          </div>
          <div className="w-full">
            <Input
              containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
              inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
              placeholder="Find Player..."
              rightIcon={
                <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                  <Search className="w-6 h-6" />
                </div>
              }
              onChange={(e) => {
                const filteredPlayers = team?.player?.filter((item: any) =>
                  item?.name
                    ?.toLocaleLowerCase()
                    ?.includes(e?.target?.value?.toLocaleLowerCase())
                );
                setPlayers(filteredPlayers);
              }}
            />
          </div>
        </div>
        <TableCardsListing
          columns={columsPlayers}
          onClickRow={() => {}}
          dataSource={players ?? []}
          overrideTableContainerClass="!h-[35vh] !overflow-scroll"
          containerClass="border-0"
          loading={isFetching}
          isPlayers
        />
      </div>
      <div className="w-[30%] h-full bg-modal-grey rounded-[12px] border-[1px] border-card-stroke">
        <div className="w-full p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
          <div>
            <p className="font-gilroyBold">My Queue</p>
          </div>
          <div className="flex gap-2">
            <Button
              mode="secondary"
              disabled={!queque?.length}
              className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[8px] text-nowrap w-[89px]"
              onClick={async () => {
                try {
                  await removeAllQueue({
                    draftId: team?.draftId,
                    userId: team?.userId,
                  });
                } catch (error: any) {
                  toast.error(error?.message);
                }
              }}
            >
              Clear all
            </Button>
            <Button
              disabled={true}
              mode="secondary"
              className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[8px] text-nowrap w-[89px]"
            >
              Automatics
            </Button>
          </div>
        </div>
        {queque?.length ? (
          <div className="py-2 px-6 h-[35vh] overflow-scroll">
            {queque?.map((item: any) => (
              <div
                className={clsx(
                  "flex items-center mt-1 pb-3 w-full",
                  queque?.length > 1 && "border-b-[1px] border-card-stroke"
                )}
              >
                <img
                  src={item?.logo || demo_team_logo}
                  alt="CutThroat"
                  className="w-[40px] h-[40px] rounded-[50px] mr-3"
                />
                <div>
                  <p className="font-gilroyBold text-base">{item?.name}</p>
                  <p className="text-text-grey text-xs font-gilroyMedium">
                    {item?.position}
                  </p>
                </div>
                <Button
                  className="rounded-[25px] !h-[40px] w-[40px] ml-auto"
                  mode="primary"
                  leftIcon={<Close />}
                  onClick={async () => {
                    try {
                      await removePlayerFromQueue({
                        userId: team?.userId,
                        playerId: item?.playerId,
                        draftId: team?.draftId,
                      });
                    } catch (error: any) {
                      toast.error(error?.message);
                    }
                  }}
                >
                  {" "}
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[70%]">
            <p className="font-gilroyBold text-base">No players in queue</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayersList;
