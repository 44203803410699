import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { LEAGUES_QUERY_KEY } from "./leaguesQueriesKeys";
import { LeaguesServices } from "../../services/apis/Leagues/LeaguesServices";

export const useFetchLeaguesList = (params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST, params],
    queryFn: () => LeaguesServices.getAllLeagues(params),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLeague = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUE],
    queryFn: () => LeaguesServices.getLeagueById(id),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUpdateLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.updateLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUE],
      });
    },
  });
};

export const useCreateLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.createLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST],
      });
    },
  });
};
