import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import { ReactComponent as Point } from "../../../../assets/icons/point.svg";
import { ReactComponent as Star } from "../../../../assets/icons/star.svg";
import { useNavigate } from "react-router";
import clsx from "clsx";

export const TeamCard = ({
  name,
  status,
  position,
  score,
  logo,
  id,
  atRisk,
  eliminated,
  isHome,
  faab,
  isMe,
}: {
  name?: string;
  status?: string;
  position?: string;
  score?: string;
  logo?: string;
  id: string;
  atRisk?: boolean;
  eliminated?: boolean;
  isHome?: boolean;
  faab?: string;
  isMe?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/leagues/team/${id}`)}
      className={clsx(
        "bg-modal-inputBg h-[80px] py-[19px] px-6 rounded-[8px] border-[1px] border-stroke w-[49%] flex justify-between items-center cursor-pointer",
        atRisk && "border-text-red bg-red-bgDark",
        eliminated && "border-none bg-red-bgDark opacity-40"
      )}
    >
      <div className="flex items-center">
        <img
          src={logo || demo_team_logo}
          alt="CutThroat"
          className="w-[40px] h-[40px] rounded-[50px] mr-4"
        />
        <div>
          <div className="flex items-center">
            <p className="font-gilroyBold text-base mr-1">{name || "-"}</p>
            {isMe && <Star className="mb-1" />}
          </div>
          <div className="flex items-center">
            <Point />
            <p className="font-gilroyMedium text-xs text-text-grey ml-[6px]">
              {status || "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-[30px]">
          <p className="font-gilroyBold text-base text-center">
            {isHome ? `$${faab}` : position || "-"}
          </p>
          <p className="font-gilroyMedium text-text-grey text-center">
            {isHome ? "FAAB" : "Draft Position"}
          </p>
        </div>
        <div>
          <p className="font-gilroyBold text-base text-center">
            {score || "-"}
          </p>
          <p className="font-gilroyMedium text-text-grey text-center">Score</p>
        </div>
      </div>
    </div>
  );
};

export const OpenSlot = () => {
  return (
    <div className="h-[80px] rounded-[8px] border-dashed border-[1px] border-stroke w-[49%] flex justify-center items-center">
      <p className="font-gilroyBold text-base text-center text-text-subGrey">
        Open Slot
      </p>
    </div>
  );
};
