import { useUpdateTeam } from "../../../../queries/teamsQueries/teamsQueries";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useEditTeamHook = ({
  closeModal,
  name,
}: {
  closeModal?: () => void;
  name?: string;
}) => {
  const { id } = useParams();
  const { mutateAsync } = useUpdateTeam();

  const formOptions = useForm({
    defaultValues: {
      name: name || "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        name: data?.name,
        teamId: id,
      });
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      closeModal && closeModal();
    }
  });

  return { control, errors, onSubmit };
};

export default useEditTeamHook;
