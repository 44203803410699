import { useFetchLeague } from "../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate, useParams } from "react-router-dom";
import { isInteger } from "../../../../utils/helpers";
import { LeaguesServices } from "../../../../services/apis/Leagues/LeaguesServices";
import { toast } from "react-toastify";
import { DraftServices } from "../../../../services/apis/Draft/DraftServices";
import useAuthStore from "../../../../stores/auth/auth";

const useLeagueHook = (leagueId?: string) => {
  const { id } = useParams();
  const { data, isFetching } = useFetchLeague(id!);
  const { userData } = useAuthStore.getState();
  const navigate = useNavigate();
  const isAdmin = userData?.role === "admin";

  const isJoined = data?.team?.filter(
    (item: any) => item?.userId === userData?.id
  )?.length;
  const inProgress = data?.status === "inProgress";
  const { regularTeams, teamsAtRisk, eliminatedTeams } = (
    data?.team || []
  ).reduce(
    (acc: any, item: any) => {
      if (item?.status === "Confirmed") {
        acc.regularTeams.push(item);
      } else if (item?.status === "AtRisk") {
        acc.teamsAtRisk.push(item);
      } else if (item?.status === "Eliminated") {
        acc.eliminatedTeams.push(item);
      }
      return acc;
    },
    { regularTeams: [], teamsAtRisk: [], eliminatedTeams: [] }
  );

  const handleNumber = (value: any) => {
    if (isInteger(Number(value))) {
      return Number(value)?.toFixed(0);
    } else return value;
  };

  const deleteLeague = async () => {
    try {
      await LeaguesServices.deleteLeague(id!);
      navigate("/leagues");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const launchDraft = async () => {
    try {
      const draft = await DraftServices.launchDraft(id!);
      navigate(`/draft/${draft?.data?.draft?.id}`);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const joinLeague = async () => {
    try {
      await LeaguesServices.joinLeague(id || leagueId!, userData?.id);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const closeRegistration = async () => {
    //TODO: add api for close registration
    try {
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return {
    isFetching,
    handleNumber,
    data,
    deleteLeague,
    launchDraft,
    closeRegistration,
    joinLeague,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    id,
    isAdmin,
  };
};

export default useLeagueHook;
