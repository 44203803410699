import clsx from "clsx";

const CustomBox = ({
  title,
  data,
  className,
}: {
  title: string;
  data: string;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "max-w-full w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-6 flex flex-col justify-between xl:max-w-[244px]",
        className
      )}
    >
      <p className="text-text-subGrey font-gilroyMedium">{title}</p>
      <p className="font-gilroyBold text-[16px] text-nowrap xl:text-[20px]">{data}</p>
    </div>
  );
};

export default CustomBox;
