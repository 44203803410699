import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Block } from "../../../../assets/icons/lock2-icon.svg";
import { ReactComponent as Unblock } from "../../../../assets/icons/unblock-icon.svg";
import demo_logo from "../../../../assets/images/demoLogo.png";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import {
  useBanUser,
  useUnbanUser,
} from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";

export const BlockUnblockUserModal = ({ user }: { user: any }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <BlockUnblockUser user={user} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title:
      user?.account_status === "active"
        ? "Block Confirmation"
        : "Unblock Confirmation",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={user?.account_status === "active" ? <Block /> : <Unblock />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-full px-4 text-nowrap !h-10 md:w-auto"
      >
        {user?.account_status === "active" ? "Block" : "Unblock"}
      </Button>
    </>
  );
};

const BlockUnblockUser = ({
  closeModal,
  user,
}: {
  closeModal?: () => void;
  user: any;
}) => {
  const formOptions = useForm({
    defaultValues: {
      reason: "",
    },
  });
  const { mutateAsync: banUser } = useBanUser();
  const { mutateAsync: unbanUser } = useUnbanUser();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async () => {
    try {
      if (user?.account_status === "active") {
        await banUser({
          userId: user?.id,
          reason: formOptions.getValues("reason"),
        });
      } else {
        await unbanUser({
          userId: user?.id,
        });
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="flex flex-col items-center mt-8">
        <img
          src={user?.profileUrl || demo_logo}
          alt="Avatar"
          className="w-[100px] h-[100px] rounded-[50px]"
        />
        <div>
          <p className="font-absoluteEmpire text-[14px] leading-none mt-4">
            {user?.name}
          </p>
          <p className="text-text-subGrey text-xs font-gilroyMedium mt-1">
            {user?.email}
          </p>
        </div>
      </div>
      {user?.account_status === "active" ? (
        <Controller
          control={control}
          name="reason"
          render={({ field }) => (
            <LabeledInput
              {...field}
              placeholder="Leave your comment with the reason for block..."
              error={errors?.reason?.message}
              required
              minLength={3}
              errorClassName="text-text-red"
              label="Comment (Required to fill out)"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative mt-6"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
          )}
        />
      ) : null}

      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Back
        </Button>
        <Button
          onClick={onSubmit}
          disabled={user?.account_status === "active" ? !isDirty : false}
          size="md"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          {user?.account_status === "active" ? "Block" : "Unblock"}
        </Button>
      </div>
    </>
  );
};

export default BlockUnblockUser;
