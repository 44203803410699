import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useUpdateUser } from "../../../../../queries/userQueries/userQueries";
import { AuthServices } from "../../../../../services/apis/Auth";
import useAuthStore from "../../../../../stores/auth/auth";

const useProfileHook = () => {
  const { userData } = useAuthStore.getState();
  const [isTwoAuth, setIsTwoAuth] = useState(!!userData?.twoStepAuth);
  const { mutateAsync } = useUpdateUser();
  const firstName = userData?.name?.split(" ")?.[0];
  const secondName = userData?.name
    ?.split(" ")
    ?.splice(1, userData?.name?.split(" ")?.length - 1)
    ?.join(" ");

  const formOptions = useForm({
    defaultValues: {
      name: firstName || "",
      surname: secondName || "",
      email: userData?.email || "",
    },
  });
  const formOptionsPass = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const {
    control: controlPass,
    formState: { errors: err },
    handleSubmit: submitPassword,
    getValues,
  } = formOptionsPass;

  const checkEmail = async (data: { email: string }) => {
    try {
      await AuthServices.checkEmailUsername(data);
      return true;
    } catch (error) {
      return false;
    }
  };

  const updatePersonalInfo = handleSubmit(async (data) => {
    try {
      await mutateAsync({
        userId: userData?.id,
        name: data?.name + " " + data?.surname,
        email: data?.email,
      });
    } catch (error: any) {
      toast.error(error?.message);
    }
  });

  const updatePassword = submitPassword(async (data) => {
    try {
      await mutateAsync({
        userId: userData?.id,
        currentPassword: data?.oldPassword,
        newPassword: data?.newPassword,
      });
    } catch (error: any) {
      toast.error(error?.message);
    }
  });
  const updateTwoStepAuth = async () => {
    try {
      await mutateAsync({
        userId: userData?.id,
        twoStepAuth: isTwoAuth,
      });
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  return {
    isTwoAuth,
    setIsTwoAuth,
    control,
    errors,
    controlPass,
    err,
    getValues,
    checkEmail,
    updatePersonalInfo,
    updatePassword,
    userData,
    updateTwoStepAuth,
  };
};

export default useProfileHook;
