import { Button, Modal, useModal } from "../../../../../components";
import { ReactComponent as DepositIcon } from "../../../../../assets/icons/deposit-icon.svg";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../../components/LabeledInput/LabeledInput";
import { toast } from "react-toastify";
import { PaymentsServices } from "../../../../../services/apis/Payments/PaymentsServices";
import useAuthStore from "../../../../../stores/auth/auth";
import { isNumeric } from "../../../../../utils/helpers";

export const DepositModal = () => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <Deposit closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Deposit",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<DepositIcon />}
        mode="secondary"
        onClick={openModal}
        className="rounded-[8px] w-auto px-4 text-nowrap !h-10 !w-[120px]"
      >
        Deposit
      </Button>
    </>
  );
};

const Deposit = ({ closeModal }: { closeModal?: () => void }) => {
  const { userData } = useAuthStore.getState();

  const formOptions = useForm({
    defaultValues: {
      amount: "",
      ccNumber: "",
      ccExp: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = formOptions;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await PaymentsServices.makeDeposit(userData?.id, {
        amount: data?.amount,
        ccNumber: data?.ccNumber,
        ccExp: data?.ccExp,
      });
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-[32px]">
        <h2 className="font-absoluteEmpire text-[32px] leading-none">
          Make a deposit
        </h2>
        <p className="text-text-subGrey font-gilroyMEdium mt-1">
          Specify the amount and card with which you want to make a deposit
        </p>
      </div>
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <LabeledInput
            {...field}
            onChange={(e) => {
              if (isNumeric(e?.target?.value) || e?.target?.value === "") {
                // @ts-ignore
                setValue("amount", Number(e?.target?.value), {
                  shouldValidate: true,
                });
              }
            }}
            placeholder="Specify the amount you want to deposit..."
            error={errors?.amount?.message}
            errorClassName="text-text-red"
            label="Total"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong amount</p>",
          },
        }}
      />
      <Controller
        control={control}
        name="ccNumber"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter your card number..."
            error={errors?.ccNumber?.message}
            errorClassName="text-text-red"
            label="Card Number"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong card number</p>",
          },
        }}
      />
      <Controller
        control={control}
        name="ccExp"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter Expiration Date..."
            error={errors?.ccExp?.message}
            errorClassName="text-text-red"
            maxLength={4}
            label="Expiration Date"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong expiration date</p>",
          },
        }}
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onSubmit}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Deposit
        </Button>
      </div>
    </>
  );
};

export default Deposit;
