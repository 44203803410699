import { useNavigate } from "react-router";
import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import useLeaguesHook from "./hooks/useLeagues";
import Filters from "../../../components/Filters/Filters";
import { Button } from "../../../components";
import { ReactComponent as NewUser } from "../../../assets/icons/new-user.svg";
import { ReactComponent as Export } from "../../../assets/icons/export.svg";
import { ReactComponent as Share } from "../../../assets/icons/share.svg";
import MobileFilters from "../../../components/MobileFilters/MobileFilters.tsx";
import MobileLeagues from "../../../components/MobileLeagues/MobileLeagues.tsx";

const Leagues = () => {
  const { columns, isFetching, filters, leagues, filter, onChangeFilter } =
    useLeaguesHook();
  const navigate = useNavigate();

  return (
      <div className="pb-6 px-4 pb-6 md:px-10">
        <div className="w-full flex justify-end gap-4 hidden lg:flex">
          <AddNewLeagueModal/>
          <Button
              leftIcon={<NewUser/>}
              mode="secondary"
              //TODO: add function for ivite
              onClick={() => {
              }}
              className="rounded-[8px] w-auto px-4 !h-10"
          >
            Invite New User
          </Button>
          <Button
              leftIcon={<Export/>}
              mode="secondary"
              //TODO: add function for export
              onClick={() => {
              }}
              className="rounded-[8px] w-auto px-4 !h-10"
          >
            Export
          </Button>
          <Button
              leftIcon={<Share/>}
              mode="secondary"
              //TODO: add function for share
              onClick={() => {
              }}
              className="rounded-[8px] w-auto px-4 !h-10"
          >
            Share
          </Button>
        </div>
        <div className="mt-6 hidden lg:block">
          <div
              className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
            <p className="font-gilroyBold">Active Leagues</p>
            <div className="flex flex-row justify-between items-center gap-[32px]">
              {filters?.map((item) => (
                  <Filters
                      key={item?.value}
                      name={item?.name}
                      value={item?.value}
                      filters={item?.filters}
                  />
              ))}
            </div>
          </div>
          <TableCardsListing
              columns={columns}
              onClickRow={(_index, item) => {
                navigate(`/leagues/league/${item?.id}`);
              }}
              dataSource={leagues}
              loading={isFetching}
              paginationData={{
                page: filter?.page as number,
                limit: filter?.limit as number,
                count: leagues?.length ?? 0,
                onChange: (params) => {
                  onChangeFilter(params);
                },
              }}
          />
        </div>
          <div className="flex gap-4 flex-col lg:hidden">
              <div className="w-full flex flex-col gap-4">
              <div className="w-full flex justify-end gap-4">
                  <AddNewLeagueModal/>
                  <Button
                      leftIcon={<NewUser/>}
                      mode="secondary"
                      //TODO: add function for ivite
                      onClick={() => {
                      }}
                      className="rounded-[8px] w-full px-4 !h-10"
                  >
                      Invite New User
                  </Button>
              </div>
                  <div className="w-full flex justify-end gap-4">
                  <Button
                      leftIcon={<Export/>}
                      mode="secondary"
                      //TODO: add function for export
                      onClick={() => {
                      }}
                      className="rounded-[8px] w-full px-4 !h-10"
                  >
                      Export
                  </Button>
                  <Button
                      leftIcon={<Share/>}
                      mode="secondary"
                      //TODO: add function for share
                      onClick={() => {
                      }}
                      className="rounded-[8px] w-full px-4 !h-10"
                  >
                      Share
                  </Button>
              </div>
              </div>
              <p className="font-gilroyBold mt-8 mb-3">All Leagues</p>

              <MobileFilters page="leagues" filters={filters}/>
              <MobileLeagues leagues={leagues}/>
          </div>
      </div>
  );
};

export default Leagues;
