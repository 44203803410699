import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { NOTIFICATIONS_QUERY_KEY } from "./notificationsQueriesKeys";
import { NotificationsServices } from "../../services/apis/Notifications/NotificationsServices";

export const useFetchUserNotifications = (userId: string, params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [NOTIFICATIONS_QUERY_KEY.FETCH_USER_NOTIFICATIONS],
    queryFn: () => NotificationsServices.getUserNotifications(userId, params),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useCheckIndicator = (userId: string, params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [NOTIFICATIONS_QUERY_KEY.CHECK_INDICATOR],
    queryFn: () => NotificationsServices.getUserNotifications(userId, params),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
