import { Button } from "../../../../components";
import { ReactComponent as Favorite } from "../../../../assets/icons/favorite-icon.svg";
import { ReactComponent as FavoriteFilled } from "../../../../assets/icons/favorite-filled-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowUp-icon.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import {
  useAddPlayerToTeam,
  useFetchAvailablePlayers,
} from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import MakeBidModal from "../components/MakeBid";
import {
  useFetchMyBids,
  useFetchPlayersOnWaiver,
  useIncreasePriority,
  usePriorityDecrease,
} from "../../../../queries/waiverQueries/waiverQueries";
import { Popup } from "../../../../components/Popup/Popup";
import moment from "moment";
import useAuthStore from "../../../../stores/auth/auth";
import { useParams } from "react-router-dom";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";

const BidPriorityRender = ({ ...data }) => {
  const { mutateAsync: increasePriority } = useIncreasePriority();
  const { mutateAsync: priorityDecrease } = usePriorityDecrease();
  return (
    <div className="flex gap-2">
      <Button
        className={clsx(
          "!h-[44px] !w-[44px]",
          data?.bid?.priority === 1 && "bg-card-stroke opacity-50"
        )}
        mode="secondary"
        onClick={() =>
          data?.bid?.priority !== 1 &&
          increasePriority({ bidId: data?.bid?.id })
        }
        leftIcon={<Arrow className="rotate-180" />}
      >
        {""}
      </Button>
      <Button
        className={clsx(
          "!h-[44px] !w-[44px]",
          data?.allBids?.length === data?.bid?.priority &&
            "bg-card-stroke opacity-50"
        )}
        mode="secondary"
        onClick={() =>
          data?.allBids?.length !== data?.bid?.priority &&
          priorityDecrease({ bidId: data?.bid?.id })
        }
        leftIcon={<Arrow />}
      >
        {""}
      </Button>
    </div>
  );
};

const RKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p className="font-gilroyBold">{data?.player?.rk || "-"}</p>
    </div>
  );
};

const PlayerRender = ({ ...data }) => {
  const endDate = moment(data?.waiver?.endTime).calendar();
  return (
    <div className="flex justify-between">
      <div className="flex items-center mt-1">
        <img
          src={data?.player?.officialImageSrc || demo_team_logo}
          alt="CutThroat"
          className="w-[40px] h-[40px] rounded-[50px] mr-3"
        />
        <div>
          <div className="flex items-center">
            <p className="font-gilroyBold text-base">
              {data?.player?.firstName} {data?.player?.lastName}
            </p>
            {data?.waiver && (
              <Popup
                icon={
                  <span className="ml-1 text-text-red font-gilroyBold">W</span>
                }
                data={
                  <>
                    <p className="text-base text-left">On waiver until</p>
                    <p className="text-base text-left">{endDate}</p>
                  </>
                }
              />
            )}
          </div>

          <p className="text-text-grey text-xs font-gilroyMedium">
            {data?.player?.primaryPosition}
          </p>
        </div>
      </div>
    </div>
  );
};

const ActionsRender = ({ ...data }) => {
  const { id } = useParams();
  const { mutateAsync } = useAddPlayerToTeam();
  const { refetch } = useFetchTeamDetails(id!);
  const isFavorite = data?.savedPlayers?.find(
    (item: any) => item?.id === data?.player?.id
  );
  const addForFree = async () => {
    try {
      await mutateAsync({
        teamId: data?.team?.id,
        player_id: data?.player?.id,
      });
      data?.refetch();
      refetch();
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div className="flex gap-2">
      <Button
        mode="secondary"
        onClick={() => data?.handleSavedPlayers(data?.player)}
        leftIcon={isFavorite ? <FavoriteFilled /> : <Favorite />}
        className="h-[40px] w-[40px] rounded-[8px]"
      >
        {""}
      </Button>
      {data?.bid ? (
        <MakeBidModal bid={data?.bid} team={data?.team} player={data?.player} />
      ) : data?.waiver ? (
        <MakeBidModal team={data?.team} player={data?.player} />
      ) : (
        <Button
          onClick={addForFree}
          mode="primary"
          className="rounded-[8px] !h-[40px] !w-[208px] px-4"
        >
          Add for Free
        </Button>
      )}
    </div>
  );
};
const useAvailablePlayers = ({
  leagueId,
  team,
}: {
  leagueId: string;
  team: any;
}) => {
  const { savedPlayers, setSavedPlayers } = useAuthStore.getState();
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const { data, isFetching, refetch } = useFetchAvailablePlayers(leagueId!, {
    page: page,
    limit: 10,
    offset: offset,
  });
  const { data: playersOnWaiver } = useFetchPlayersOnWaiver(team?.id);
  const { data: biddedPlayers } = useFetchMyBids(team?.id);
  const [activeFilter, setActiveFilter] = useState("All");
  const [playersTypeFilter, setPlayersTypeFilter] = useState("All");
  const [allPlayers, setAllPlayers] = useState<any>([]);
  const [allPlayersForFilter, setAllPlayersForFilters] = useState<any>([]);

  useEffect(() => {
    if (data?.length) {
      handlePlayers();
    }
  }, [data]);

  const handlePlayers = () => {
    setPage(page + 1);
    setOffset(offset + 10);
  };

  const loadMore = async () => {
    try {
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllPlayers = () => {
    const responseData = data ? data : [];
    const players = [...allPlayers, ...responseData]?.reduce(
      (accumulator: any, currentPlayer: any) => {
        const waiverPlayer = playersOnWaiver?.find(
          (item: any) =>
            item?.playerId === currentPlayer?.player?.id?.toString()
        );
        const biddedPlayer = biddedPlayers?.find(
          (item: any) =>
            item?.playerId === currentPlayer?.player?.id?.toString()
        );
        const saved = savedPlayers?.find(
          (item: any) => item?.id === currentPlayer?.player?.id
        );
        if (waiverPlayer) {
          currentPlayer.waiver = waiverPlayer;
        } else {
          currentPlayer.waiver = false;
        }
        if (biddedPlayer) {
          currentPlayer.bid = biddedPlayer;
        } else {
          currentPlayer.bid = false;
        }
        if (saved) {
          currentPlayer.saved = true;
        } else {
          currentPlayer.saved = false;
        }

        return [...accumulator, currentPlayer];
      },
      []
    );
    const playersData = players ? players : [];
    if (activeFilter === "All") {
      setAllPlayers(playersData);
      setAllPlayersForFilters(playersData);
    } else if (activeFilter === "My bids") {
      const bidsPlayers = playersData?.filter((item: any) => item?.bid);
      setAllPlayersForFilters(bidsPlayers);
    } else if (activeFilter === "Saved") {
      const playersSaved = playersData?.filter((item: any) => item?.saved);
      setAllPlayersForFilters(playersSaved);
    }

    return playersData;
  };

  const handlePlayersType = (type: string) => {
    setActiveFilter("All");
    switch (type) {
      case "QB":
        setPlayersTypeFilter("QB");
        const QBPlayers = allPlayers?.filter(
          (item: any) => item?.player?.primaryPosition === "QB"
        );
        setAllPlayersForFilters(QBPlayers);
        break;
      case "WR":
        setPlayersTypeFilter("WR");
        const WRPlayers = allPlayers?.filter(
          (item: any) => item?.player?.primaryPosition === "WR"
        );
        setAllPlayersForFilters(WRPlayers);
        break;
      case "K":
        setPlayersTypeFilter("K");
        const KPlayers = allPlayers?.filter(
          (item: any) => item?.player?.primaryPosition === "K"
        );
        setAllPlayersForFilters(KPlayers);
        break;
      case "DEF":
        setPlayersTypeFilter("DEF");
        const DEFPlayers = allPlayers?.filter(
          (item: any) =>
            item?.player?.primaryPosition === "LB" ||
            item?.player?.primaryPosition === "RB" ||
            item?.player?.primaryPosition === "DB"
        );
        setAllPlayersForFilters(DEFPlayers);
        break;
      case "Flex":
        setPlayersTypeFilter("Flex");
        const FlexPlayers = data?.filter(
          (item: any) =>
            item?.player?.primaryPosition !== "LB" &&
            item?.player?.primaryPosition !== "RB" &&
            item?.player?.primaryPosition !== "DB" &&
            item?.player?.primaryPosition !== "K" &&
            item?.player?.primaryPosition !== "WR" &&
            item?.player?.primaryPosition !== "QB"
        );
        setAllPlayersForFilters(FlexPlayers);
        break;
      case "All":
        setPlayersTypeFilter("All");
        handleAllPlayers();
        break;
      default:
        handleAllPlayers();
        break;
    }
  };

  const handleSavedPlayers = (player: any) => {
    const savedPlayer = savedPlayers?.find(
      (item: any) => item?.id === player?.id
    );
    if (savedPlayer) {
      setSavedPlayers(
        savedPlayers?.filter((item: any) => item?.id !== player?.id)
      );
    } else {
      setSavedPlayers([...savedPlayers, player]);
    }
  };

  useEffect(() => {
    handleAllPlayers();
  }, [data, playersOnWaiver, biddedPlayers, activeFilter, savedPlayers]);

  const columsPlayers = [
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">
          {activeFilter === "My bids" ? "Bid priority" : "RK"}
        </p>
      ),
      dataIndex: "rk",
      thClassName: "w-[120px]",
      render: (_: string, record: any) => {
        return activeFilter === "My bids" ? (
          <BidPriorityRender {...record} allBids={biddedPlayers} />
        ) : (
          <RKRender {...record} />
        );
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Players</p>
      ),
      dataIndex: "SelectedPlayers",
      render: (_: string, record: any) => {
        return <PlayerRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">ADP</p>,
      dataIndex: "adp",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">ATT</p>,
      dataIndex: "att",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "yds",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TD</p>,
      dataIndex: "td",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Actions</p>
      ),
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            handleSavedPlayers={handleSavedPlayers}
            savedPlayers={savedPlayers}
            team={team}
            refetch={refetch}
            {...record}
          />
        );
      },
      thClassName: "w-[100px]",
    },
  ];
  return {
    isFetching,
    activeFilter,
    playersTypeFilter,
    allPlayers,
    handlePlayersType,
    columsPlayers,
    setActiveFilter,
    data,
    setAllPlayers,
    loadMore,
    allPlayersForFilter,
    setAllPlayersForFilters,
  };
};

export default useAvailablePlayers;
