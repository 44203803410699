import useTeamHook from "./hooks/useTeam";
import demo_logo from "../../../assets/images/demoLogo.png";
import demo_team_logo from "../../../assets/images/demoTeamLogo.png";
import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import CustomBox from "../League/components/CustomBox";
import { EditTeamModal } from "./components/EditTeam";
import { AvailablePlayersModal } from "./components/AvailablePlayers";
import LiveChat from "./components/LiveChat";

const Team = () => {
  const { team, user, columns, isFetching, isMyTeam, isAdmin } = useTeamHook();

  return (
    <div className="px-10 pb-6">
      <div className="w-full flex justify-between">
        <div className="w-[50%] flex flex-row justify-start items-center">
          <img
            src={team?.logo_url || demo_logo}
            alt="CutThroat"
            className="w-[100px] h-[100px] rounded-[50px] mr-6"
          />
          <div>
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              {team?.name}
            </h2>
            <div className="flex items-center mt-1">
              <img
                src={demo_team_logo}
                alt="CutThroat"
                className="w-[32px] h-[32px] rounded-[50px] mr-1"
              />
              <div>
                <p className="font-gilroyBold">{user?.name}</p>
                <p className="text-text-subGrey text-xs font-gilroyMedium">
                  Team Owner
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[50%] flex flex-row justify-end gap-4">
          {isMyTeam && (
            <AvailablePlayersModal team={team} leagueId={team?.leagueId} />
          )}
          {isAdmin && <EditTeamModal name={team?.name} />}
        </div>
      </div>
      <div className="mt-[32px] flex gap-6">
        <CustomBox
          title="League"
          data={team?.leaguenName}
          className="capitalize"
        />
        <CustomBox
          title="League Ranking"
          data={team?.ranking}
          className="capitalize"
        />
        <CustomBox
          title="Total Fantasy Points"
          data={`${team?.score || 0} Points`}
          className="capitalize"
        />
        <CustomBox
          title="Average Points per Game"
          data={`${team?.score || 0} Points`}
          className="capitalize"
        />
        <CustomBox
          title="Free Agent Acquisition Budget"
          data={`$${team?.faab_balance || 0}`}
          className="capitalize"
        />
      </div>
      <div className="mt-6 flex gap-6">
        <div className="w-[70%]">
          <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-start items-center">
            <p className="font-gilroyBold">List of Players</p>
          </div>
          <TableCardsListing
            columns={columns}
            onClickRow={() => {}}
            dataSource={team?.player ?? []}
            overrideTableContainerClass=""
            loading={isFetching}
            isPlayers
          />
        </div>
        {isMyTeam ? <LiveChat leagueId={team?.leagueId} /> : null}
      </div>
    </div>
  );
};

export default Team;
