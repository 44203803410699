import axios from "axios";

export const NotificationsServices = {
  getUserNotifications: async (userId: string, params: any) => {
    const endPoint = `/api/notification/${userId}`;
    try {
      const notifications = await axios.get(endPoint, { params });
      return notifications?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  readNotification: async (payload: any) => {
    const endPoint = `/api/notification`;
    try {
      const notification = await axios.patch(endPoint, payload);
      return notification?.data;
    } catch (error) {
      throw error;
    }
  },
  readAllNotification: async (userId: string) => {
    const endPoint = `/api/notification/read/${userId}`;
    try {
      const notifications = await axios.patch(endPoint);
      return notifications?.data;
    } catch (error) {
      throw error;
    }
  },
};
