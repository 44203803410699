import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  useCreateLeague,
  useFetchLeague,
  useUpdateLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";

const useAddNewLeagueHook = ({
  closeModal,
  isEdit,
}: {
  closeModal?: () => void | undefined;
  isEdit?: boolean;
}) => {
  const { id } = useParams();
  const { data: league } = useFetchLeague(id!);
  const { mutateAsync } = useUpdateLeague();
  const { mutateAsync: createLeague } = useCreateLeague();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [receivingOption, setReceivingOption] = useState<string>("PPR");
  const [date, setDate] = useState(null);
  const isFirstTab = activeTab === 1;
  const isSecondTab = activeTab === 2;
  const isThirdTab = activeTab === 3;

  const formOptions = useForm({
    defaultValues: {
      name: isEdit ? league?.name : "",
      description: isEdit ? league?.description : "",
      entryFee: isEdit ? league?.entryFee : "",
      numberOfTeams:
        isEdit && league?.numberOfTeams
          ? [{ label: league?.numberOfTeams, value: league?.numberOfTeams }]
          : ([] as any),
      teamsEliminatedPerWeek:
        isEdit && league?.teamsEliminatedPerWeek
          ? [
              {
                label: league?.teamsEliminatedPerWeek,
                value: league?.teamsEliminatedPerWeek,
              },
            ]
          : ([] as any),
      timeForEachTeam:
        isEdit && league?.timeForEachTeam
          ? [{ label: league?.timeForEachTeam, value: league?.timeForEachTeam }]
          : ([] as any),
      prizeFundFirstPlace: isEdit ? league?.prizeFundFirstPlace : "",
      prizeFundSecondPlace: isEdit ? league?.prizeFundSecondPlace : "",
      //TODO: fix mock data
      pricePoint: 5.0,
      totalWeeks: 8,
    },
  });

  const data = ["QB", "RB", "WR", "TE", "Flex", "Flex "];

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isFirstTab) {
        setActiveTab(2);
        return;
      } else if (isSecondTab) {
        setActiveTab(3);
        return;
      } else if (isThirdTab) {
        if (isEdit) {
          await mutateAsync({
            name: data?.name,
            description: data?.description,
            draftDateTime: moment(date).format("YYYY-MM-DD HH:MM"),
            entryFee: data?.entryFee,
            pricePoint: data?.pricePoint,
            numberOfTeams: data?.numberOfTeams?.value,
            teamsEliminatedPerWeek: data?.teamsEliminatedPerWeek?.value,
            totalWeeks: data?.totalWeeks,
            receivingOption: receivingOption,
            prizeFundFirstPlace: data?.prizeFundFirstPlace,
            prizeFundSecondPlace: data?.prizeFundSecondPlace,
            draftPickTime: data?.timeForEachTeam,
            leagueId: id,
          });
        } else {
          await createLeague({
            name: data?.name,
            description: data?.description,
            draftDateTime: moment(date).format("YYYY-MM-DD HH:MM"),
            entryFee: data?.entryFee,
            pricePoint: data?.pricePoint,
            numberOfTeams: data?.numberOfTeams?.value,
            teamsEliminatedPerWeek: data?.teamsEliminatedPerWeek?.value,
            totalWeeks: data?.totalWeeks,
            receivingOption: receivingOption,
            prizeFundFirstPlace: data?.prizeFundFirstPlace,
            prizeFundSecondPlace: data?.prizeFundSecondPlace,
            draftPickTime: data?.timeForEachTeam?.value,
          });
        }
      }
      closeModal?.();
    } catch (error: any) {
      closeModal?.();
      toast.error(error?.message);
    }
  });
  return {
    activeTab,
    setActiveTab,
    receivingOption,
    setReceivingOption,
    date,
    setDate,
    errors,
    control,
    data,
    onSubmit,
    isFirstTab,
    isSecondTab,
    isThirdTab,
  };
};

export default useAddNewLeagueHook;
