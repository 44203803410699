import axios from "axios";
import { LeaguesType } from "./leagues.types";
import useAuthStore from "../../../stores/auth/auth";

export const LeaguesServices = {
  createLeague: async (payload: LeaguesType) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = "/api/admin/league-management/league";
    try {
      const newLeague = await axios.post(endPoint, payload, config);
      return newLeague;
    } catch (error) {
      throw error;
    }
  },
  getAllLeagues: async (params: any) => {
    const endPoint = "/api/game/league";
    try {
      const leagues = await axios.get(endPoint, { params });
      return leagues?.data;
    } catch (error) {
      throw error;
    }
  },
  getLeagueById: async (id: string) => {
    const endPoint = `/api/game/league/${id}`;
    try {
      const league = await axios.get(endPoint);
      return league?.data?.message;
    } catch (error) {
      throw error;
    }
  },
  joinLeague: async (leagueId: string, userId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/league/join/${userId}`;
    try {
      const joinLeague = await axios.post(endPoint, { leagueId }, config);
      return joinLeague?.data;
    } catch (error) {
      throw error;
    }
  },
  getAllLeagueUsers: async (leagueId: string) => {
    const endPoint = `/api/game/league/user/${leagueId}`;
    try {
      const leagueUsers = await axios.get(endPoint);
      return leagueUsers?.data;
    } catch (error) {
      throw error;
    }
  },
  getTeamDetails: async (teamId: string) => {
    const endPoint = `/api/game/team/${teamId}`;
    try {
      const team = await axios.get(endPoint);
      return team?.data;
    } catch (error) {
      throw error;
    }
  },
  deleteLeague: async (leagueId: string) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/admin/league-management/league/${leagueId}`;
    try {
      const deleteLeague = await axios.delete(endPoint, config);
      return deleteLeague;
    } catch (error) {
      throw error;
    }
  },
  updateLeague: async (payload: LeaguesType) => {
    const endPoint = `/api/admin/league-management/league/${payload?.leagueId}`;
    try {
      delete payload?.leagueId;
      const updateLeague = await axios.patch(endPoint, payload);
      return updateLeague;
    } catch (error) {
      throw error;
    }
  },
};
