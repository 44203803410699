import { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import league_logo from "../../../../assets/images/league-logo.png";
import { ReactComponent as User } from "../../../../assets/icons/user-icon.svg";
import {
  useFetchAllUsers,
  useFetchUsersCount,
} from "../../../../queries/adminQueries/adminQueries";
import { useNavigate } from "react-router-dom";
import SingleFilter from "./SingleFilter";
import { DeleteUserModal } from "../components/DeleteUser";
import { EditUserModal } from "../../../../pages/private/UserProfile/components/EditUser";

const NameRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <img
        src={data?.profileUrl || league_logo}
        alt="Avatar"
        className="w-[32px] h-[32px]"
      />
      <div className="flex flex-col ml-2 text-base">
        <p className="font-gilroyMedium">{data?.name || ""}</p>
        <p className="text-xs text-text-subGrey text-nowrap">
          {data?.email || ""}
        </p>
      </div>
    </div>
  );
};

const RegisterDateRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <p className="font-gilroyMedium">
        {moment(data?.createdAt)?.format("LL") || ""}
      </p>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-xs font-gilroyBold px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.account_status === "blocked" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.account_status === "active" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2"
        )}
      >
        {data?.account_status || ""}
      </div>
    </div>
  );
};

const NumberOfLeaguesRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p className="font-gilroyMedium">
        {data?.userStats?.numberOfLeaguesEntered}
      </p>
    </div>
  );
};

const BalanceRender = ({ ...data }) => {
  const accountBalance =
    Number(data?.balance?.amount) + Number(data?.balance?.winning_amount);
  return (
    <div className={`flex flex-col`}>
      <p className="font-gilroyBold text-nowrap">
        {!isNaN(accountBalance) ? `$${accountBalance?.toFixed(2)}` : ""}
      </p>
    </div>
  );
};

const CreditRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p className="font-gilroyBold text-nowrap">
        {data?.balance?.credit_amount ? `$${data?.balance?.credit_amount}` : ""}
      </p>
    </div>
  );
};

const ActionsRender = ({ ...data }) => {
  const navigate = useNavigate();
  return (
    <div className={`flex flex-row gap-4`}>
      <User
        onClick={() => navigate(`/users/user-profile/${data?.id}`)}
        className="cursor-pointer"
      />
      <EditUserModal userId={data?.id} isListView />
      <DeleteUserModal userId={data?.id} />
    </div>
  );
};

const useUsersHook = () => {
  const { data, isFetching } = useFetchAllUsers();
  const { data: usersCount } = useFetchUsersCount();
  const [users, setUsers] = useState(data?.message ?? []);
  const [status, setStatus] = useState("All");
  const [numOfLeagues, setNumOfLeagues] = useState("All");
  const [balance, setBalance] = useState("All");
  const [credits, setCredits] = useState("All");
  const [name, setName] = useState("");

  const onFilterValues = () => {
    const filteredUsers = data?.message
      ?.filter((item: any) => {
        if (name) {
          return item?.name
            ?.toLocaleLowerCase()
            ?.includes(name?.toLocaleLowerCase());
        } else return item.name;
      })
      .filter((item: any) => {
        if (status !== "All") {
          return item.account_status === status?.toLocaleLowerCase();
        } else return item.account_status;
      })
      .filter((item: any) => {
        if (numOfLeagues !== "All") {
          const values = numOfLeagues.split("-");
          return (
            item?.userStats?.numberOfLeaguesEntered >= Number(values[0]) &&
            item?.userStats?.numberOfLeaguesEntered <= Number(values[1])
          );
        } else return item;
      })
      .filter((item: any) => {
        const accountBalance =
          Number(item?.balance?.amount) + Number(item?.balance?.winning_amount);
        if (balance !== "All") {
          if (balance === "$0 - $50") {
            return accountBalance < 50;
          } else if (balance === "$50 - $100") {
            return accountBalance > 50 && accountBalance < 100;
          } else if (balance === "$100+") {
            return accountBalance > 100;
          }
        } else return item;
      })
      .filter((item: any) => {
        if (credits !== "All") {
          if (credits === "$0 - $50") {
            return Number(item?.balance?.credit_amount) < 50;
          } else if (balance === "$50 - $100") {
            return (
              Number(item?.balance?.credit_amount) > 50 &&
              Number(item?.balance?.credit_amount) < 100
            );
          } else if (balance === "$100+") {
            return Number(item?.balance?.credit_amount) > 100;
          }
        } else return item;
      });

    setUsers(filteredUsers);
  };

  useEffect(() => {
    onFilterValues();
  }, [status, numOfLeagues, balance, credits, name, data]);

  const filters = {
    status: ["All", "Active", "Blocked"],
    numOfLeagues: ["All", "0-4", "4-10", "10-16", "16-32"],
    balance: ["All", "$0 - $50", "50 - $100", "$100+"],
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Registration Date",
      dataIndex: "registerDate",
      render: (_: string, record: any) => {
        return <RegisterDateRender {...record} />;
      },
      sort: true,
    },
    {
      name: "Status",
      title: (
        <div className="flex gap-2 items-center">
          <p>Status</p>
          <SingleFilter
            activeFilter={status}
            setFilter={setStatus}
            filters={filters.status}
          />
        </div>
      ),
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
      filters: filters.status,
    },
    {
      name: "Number of Leagues",
      title: (
        <div className="flex gap-2 items-center">
          <p>Number of Leagues</p>
          <SingleFilter
            activeFilter={numOfLeagues}
            setFilter={setNumOfLeagues}
            filters={filters.numOfLeagues}
          />
        </div>
      ),
      dataIndex: "numberOfLeagues",
      render: (_: string, record: any) => {
        return <NumberOfLeaguesRender {...record} />;
      },
      sort: true,
      filters: filters.numOfLeagues,
    },
    {
      name: "Balance",
      title: (
        <div className="flex gap-2 items-center">
          <p>Balance</p>
          <SingleFilter
            activeFilter={balance}
            setFilter={setBalance}
            filters={filters.balance}
          />
        </div>
      ),
      dataIndex: "balance",
      render: (_: string, record: any) => {
        return <BalanceRender {...record} />;
      },
      sort: true,
      filters: filters.balance,
    },
    {
      name: "Credit",
      title: (
        <div className="flex gap-2 items-center">
          <p>Credit</p>
          <SingleFilter
            activeFilter={credits}
            setFilter={setCredits}
            filters={filters.balance}
          />
        </div>
      ),
      dataIndex: "credit",
      render: (_: string, record: any) => {
        return <CreditRender {...record} />;
      },
      sort: true,
      filters: filters.balance,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return <ActionsRender {...record} />;
      },
      sort: true,
    },
  ];

  return {
    columns,
    isFetching,
    setName,
    users,
    usersCount,
    setStatus,
    setNumOfLeagues,
    setCredits,
    setBalance,
    status,
    numOfLeagues,
    balance,
    credits,
  };
};

export default useUsersHook;
