import axios from "axios";

import useAuthStore from "../../../stores/auth/auth";
import { SignUpDataType } from ".";

export const AuthServices = {
  register: async (payload: SignUpDataType) => {
    const endPoint = "/api/users";
    try {
      const register = await axios.post(endPoint, payload);
      const { setLoginData, setUserData } = useAuthStore.getState();
      setLoginData({ access_token: register?.data?.data?.token });
      setUserData(register?.data?.data?.user);
      return register;
    } catch (error) {
      throw error;
    }
  },
  checkEmailUsername: async (payload: {
    username?: string;
    email?: string;
  }) => {
    const endPoint = "/api/users/check";
    try {
      const check = await axios.post(endPoint, payload);
      return check?.data;
    } catch (error) {
      throw error;
    }
  },
  forgotPassword: async (payload: { email: string }) => {
    const endPoint = "/api/auth/forgot-password";
    try {
      const forgot = await axios.post(endPoint, payload);
      return forgot?.data;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (payload: { newPassword: string; token: string }) => {
    const endPoint = "/api/auth/reset-password";
    try {
      const reset = await axios.post(endPoint, payload);
      return reset?.data;
    } catch (error) {
      throw error;
    }
  },
  login: async (payload: {
    email?: string;
    username?: string;
    password: string;
    rememberMe?: boolean;
  }) => {
    const endPoint = "/api/auth/login";
    try {
      const login = await axios.post(endPoint, payload);
      if (!login?.data?.data?.twoStepAuth) {
        const { setLoginData, setUserData } = useAuthStore.getState();
        setLoginData({ access_token: login?.data?.data?.token });
        setUserData(login?.data?.data?.user);
      }
      return login?.data;
    } catch (error) {
      throw error;
    }
  },
  verify: async (payload: {
    email?: string;
    username?: string;
    verificationCode: string;
  }) => {
    const endPoint = "/api/auth/verify";
    try {
      const verify = await axios.post(endPoint, payload);
      const { setLoginData, setUserData } = useAuthStore.getState();
      setLoginData({ access_token: verify?.data?.data?.result?.token });
      setUserData(verify?.data?.data?.result?.user);
      return verify?.data;
    } catch (error) {
      throw error;
    }
  },
};
