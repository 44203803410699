import { Button, Modal, useModal } from "../../../../../components";
import { ReactComponent as Withdrawal } from "../../../../../assets/icons/withdraw-icon.svg";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../../components/LabeledInput/LabeledInput";
import { toast } from "react-toastify";
import LabeledTextArea from "../../../../../components/LabeledTextArea/LabeledTextArea";
import { useSendWithdrawalRequest } from "../../../../../queries/paymentsQueries/paymentsQueries";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState } from "react";
import { isNumeric } from "../../../../../utils/helpers";

export const RequestWithdrawModal = ({ balance }: { balance?: number }) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <RequestWithdraw balance={balance} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: "Withdrawal",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        leftIcon={<Withdrawal />}
        mode="primary"
        onClick={openModal}
        className="rounded-[8px] w-auto px-4 text-nowrap !h-10 !w-[120px]"
      >
        Withdraw
      </Button>
    </>
  );
};

const RequestWithdraw = ({
  closeModal,
  balance,
}: {
  closeModal?: () => void;
  balance?: number;
}) => {
  const { mutateAsync } = useSendWithdrawalRequest();
  const [date, setDate] = useState<any>(null);
  const formOptions = useForm({
    defaultValues: {
      sender: "",
      receiver: "",
      bankNumber: "",
      amount: "",
      description: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = formOptions;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await mutateAsync({
        senderAccountNumber: data?.sender,
        receiverAccountNumber: data?.receiver,
        amount: data?.amount,
        date: moment(date).format("DD-MM-YYYY"),
        description: data?.description,
      });
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-[32px]">
        <h2 className="font-absoluteEmpire text-[32px] leading-none">
          Withdrawal
        </h2>
        <p className="text-text-subGrey font-gilroyMEdium mt-1">
          Specify the amount and indicate which card you want to make a
          withdrawal to
        </p>
        <p className="font-absoluteEmpire text-text-subGrey mt-6">
          Available amount:{" "}
          <span className="text-text-red">${balance?.toFixed(2)}</span>
        </p>
      </div>
      <Controller
        control={control}
        name="sender"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter sender’s account number..."
            error={errors?.sender?.message}
            errorClassName="text-text-red"
            label="Sender's Account Number"
            minLength={16}
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-[28px]"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>Minimum length 16 numbers.</p>",
          },
        }}
      />
      <Controller
        control={control}
        name="receiver"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter receiver’s account number..."
            error={errors?.receiver?.message}
            errorClassName="text-text-red"
            label="Receiver's Account Number"
            minLength={16}
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>Minimum length 16 numbers.</p>",
          },
        }}
      />
      <Controller
        control={control}
        name="bankNumber"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter bank routing number..."
            error={errors?.bankNumber?.message}
            errorClassName="text-text-red"
            label="Bank Routing Number"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          required: {
            value: true,
            message: "<p>This is a wrong bank routing number</p>",
          },
        }}
      />
      <div className="flex mt-4">
        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <LabeledInput
              {...field}
              onChange={(e) => {
                if (isNumeric(e?.target?.value) || e?.target?.value === "") {
                  // @ts-ignore
                  setValue("amount", Number(e?.target?.value), {
                    shouldValidate: true,
                  });
                }
              }}
              placeholder="Indicate the amount"
              error={errors?.amount?.message}
              errorClassName="text-text-red"
              label="Amount"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative mr-4"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
          )}
          rules={{
            required: {
              value: true,
              message: "<p>This is a wrong amount</p>",
            },
          }}
        />
        <DatePicker
          showPopperArrow={false}
          selected={date}
          dateFormat="yyyy-mm-dd"
          minDate={moment()?.toDate()}
          showPreviousMonths={false}
          onChange={(date: any) => setDate(date)}
          customInput={
            <div className="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7 cursor-pointer">
              <p className="absolute top-[12px] left-[16px] text-text-subGrey text-xs font-gilroyMedium">
                Date
              </p>
              <p className="text-text-grey text-base font-gilroyMedium text-left sm:text-nowrap">
                {date ? moment(date).format("DD-MM-YYYY") : "Enter date..."}
              </p>
            </div>
          }
        />
      </div>
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <LabeledTextArea
            {...field}
            error={!!errors?.description?.message}
            label="Description (Optional)"
            placeholder="Write a description..."
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
      />
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onSubmit}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Withdraw
        </Button>
      </div>
    </>
  );
};

export default RequestWithdraw;
