import demo_logo from "../../../../assets/images/demoLogo.png";
import clsx from "clsx";

const LeagueCard = ({ league }: { league: any }) => {
  return (
    <div className="flex justify-between items-center border-[1px] border-stroke rounded-[8px] px-4 py-3 hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2">
      <div className="flex items-center">
        <img
          src={league?.logo || demo_logo}
          alt="CutThroat"
          className="w-[40px] h-[40px] rounded-[50px] mr-3"
        />
        <p className="text-base font-gilroyBold">{league?.name}</p>
      </div>
      <div className="flex items-center">
        <p className="text-base font-gilroyBold">
          {league?.team?.length}
          <span className="text-xs text-text-grey font-gilroyMedium">
            /{league?.numberOfTeams}
          </span>
        </p>
        <div
          className={clsx(
            "text-xs font-gilroyBold px-4 py-2 rounded-[16px] bg-gradient-to-br capitalize text-nowrap w-[88px] h-[25px] ml-[46px]",
            league?.status === "registration" &&
              "from-elements-chipGradient-1 to-elements-chipGradient-2",
            league?.status === "in progress" &&
              "from-red-bgGradient2-1 to-red-bgGradient2-2",
            league?.status === "completed" && "bg-white text-screenBgGrey"
          )}
        >
          {league?.status || ""}
        </div>
      </div>
    </div>
  );
};
export default LeagueCard;
