export const colors = {
  screenBgGrey: "#080B11",
  white: "#FFFFFF",
  text: {
    red: "#DE0000",
    white: "#FFFFFF",
    grey: "#909294",
    greyDark: "#525557",
    subGrey: "#8F8E97",
    lightGrey: "#D0D1D1",
    green: "#33C481",
    redLight: "#F1D4D3",
  },
  red: {
    bgGradient: {
      1: "#A22C29",
      2: "#A1090A",
    },
    light: {
      1: "#DC343B",
      2: "#A1090A",
    },
    bgSolid: "#A22C29",
    bgDark: "#260D12",
    accent: {
      1: "#F01132",
      2: "#B00000",
    },
    bgGradient2: {
      1: "#B11B21",
      2: "#B00000",
    },
    dark: "#2A0B12",
  },
  button: {
    bgSolid: "#212529",
  },
  card: {
    bgHover: {
      1: "#3C3F41",
      2: "#2C2F31",
    },
    bgGreyDark: "#14181B",
    bgGreyMedium: "#212529",
    stroke: "#24292E",
    strokeGradient: {
      1: "#494C4D",
      2: "#343638",
    },
    top: {
      1: "#272A2D",
      2: "#1F2325",
    },
    bgGreyLight: "#333A41",
  },
  elements: {
    chipGradient: {
      1: "#555",
      2: "#404040",
    },
  },
  modal: {
    bgModal: "rgba(2, 2, 2, 0.74)",
    grey: "#13181b",
    modalStroke: "#3A3948",
    inputBg: "#202529",
    inputBorder: "rgba(0, 0, 0, 0.10)",
    label: "#939393",
  },
  filterContainer: "#262A2D",
  stroke: "#474C4D",
  transparent: "transparent",
  chipGreen: "#004F2A",
};
