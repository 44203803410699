import { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import league_logo from "../../../../assets/images/league-logo.png";
import { ReactComponent as Edit } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete-icon.svg";
import useFilter from "../../../../hooks/useFilter";
import { useFetchLeaguesList } from "../../../../queries/leaguesQueries/leaguesQueries";

const NameRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <img src={league_logo} alt="League logo" />
      <div className="flex flex-col ml-6 text-base">{data?.name || ""}</div>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "in progress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const TeamsRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{data?.numberOfTeams + " Teams"}</p>
      <p className="text-sm text-text-subGrey">
        {data?.teamsEliminatedPerWeek + " were eliminated"}
      </p>
    </div>
  );
};

const PPRRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{data?.receivingOption}</p>
      <p className="text-sm text-text-subGrey">Scoring Type</p>
    </div>
  );
};

const DateRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{moment(data?.draftDateTime).format("LL")}</p>
      <p className="text-sm text-text-subGrey">Start Date</p>
    </div>
  );
};

const ActionsRender = () => {
  return (
    <div className={`flex flex-row gap-4`}>
      <Edit />
      <Delete />
    </div>
  );
};

const useLeaguesHook = () => {
  const { filter, onChangeFilter } = useFilter({});
  const { data, isFetching } = useFetchLeaguesList({
    page: filter?.page,
    limit: filter?.limit,
  });
  const [leagues, setLeagues] = useState(data?.message ?? []);

  const onFilterValues = () => {
    const filteredLeagues = data?.message
      .filter((item: any) => {
        if (filter?.keywords) {
          return (
            item?.name
              ?.toLocaleLowerCase()
              // @ts-ignore
              ?.includes(filter?.keywords?.toLocaleLowerCase())
          );
        } else return item.name;
      })
      .filter((item: any) => {
        if (filter?.status !== "All") {
          // @ts-ignore
          return item.status === filter?.status?.toLocaleLowerCase();
        } else return item.status;
      })
      .filter((item: any) => {
        if (filter?.numberOfTeams !== "All") {
          // @ts-ignore
          const values = filter?.numberOfTeams?.split("-");
          return (
            item?.numberOfTeams >= Number(values[0]) &&
            item?.numberOfTeams <= Number(values[1])
          );
        } else return item?.numberOfTeams;
      })
      .filter((item: any) => {
        if (filter?.receivingOption !== "All") {
          // @ts-ignore
          return item.receivingOption === filter?.receivingOption;
        } else return item.receivingOption;
      })
      .filter((item: any) => {
        if (filter?.draftDateTime !== "All") {
          // @ts-ignore
          return (
            moment(item.draftDateTime).format("LL") ===
            moment(filter?.draftDateTime).format("LL")
          );
        } else return item.draftDateTime;
      });

    setLeagues(filteredLeagues);
  };

  useEffect(() => {
    onFilterValues();
  }, [
    filter?.status,
    filter?.numberOfTeams,
    filter?.receivingOption,
    filter?.draftDateTime,
    filter?.keywords,
    data,
  ]);

  const filters = [
    {
      name: "Search",
      value: "keywords",
    },
    {
      name: "Status",
      value: "status",
      filters: ["All", "Registration", "In Progress", "Completed"],
    },
    {
      name: "Number of Teams",
      value: "numberOfTeams",
      filters: ["All", "2-4", "4-10", "10-16", "16-32"],
    },
    {
      name: "Scoring Type",
      value: "receivingOption",
      filters: ["All", "PPR", "Non-PPR"],
    },
    {
      name: "Start Date",
      value: "draftDateTime",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Teams",
      dataIndex: "teams",
      render: (_: string, record: any) => {
        return <TeamsRender {...record} />;
      },
      sort: true,
    },
    {
      title: "PPR",
      dataIndex: "ppr",
      render: (_: string, record: any) => {
        return <PPRRender {...record} />;
      },
      sort: true,
    },
    {
      title: "date",
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return <ActionsRender {...record} />;
      },
      sort: true,
    },
  ];

  return { columns, isFetching, filters, leagues, filter, onChangeFilter };
};

export default useLeaguesHook;
