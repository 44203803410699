import { useEffect, useState } from "react";
import clsx from "clsx";
import league_logo from "../../../../../assets/images/league-logo.png";
import useFilter from "../../../../../hooks/useFilter";
import { useFetchLeaguesList } from "../../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate } from "react-router";
import useAuthStore from "../../../../../stores/auth/auth";
import LeagueRegistrationModal from "../../../League/components/LeagueRegistration";

const NameRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <img src={league_logo} alt="League logo" />
      <div className="flex flex-col ml-6 text-base">{data?.name || ""}</div>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "in progress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const TeamsRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{data?.numberOfTeams + " Teams"}</p>
      <p className="text-sm text-text-subGrey">
        {data?.teamsEliminatedPerWeek + " were eliminated"}
      </p>
    </div>
  );
};

const EliminatedRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{data?.eliminatedTeams ?? 0}</p>
      <p className="text-sm text-text-subGrey">Eliminated</p>
    </div>
  );
};

const EntryFeeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>${data?.entryFee}</p>
      <p className="text-sm text-text-subGrey">Entry Fee</p>
    </div>
  );
};

const PrizeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>
        $
        {Number(data?.prizeFundFirstPlace) + Number(data?.prizeFundSecondPlace)}
      </p>
      <p className="text-sm text-text-subGrey">Prize Fund</p>
    </div>
  );
};
const ViewLeagueRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-col cursor-pointer`}
    >
      <p className="underline text-nowrap">View League</p>
      <p className="text-sm text-text-subGrey">View Details</p>
    </div>
  );
};
const JoinLeagueRender = ({ ...data }) => {
  const { userData } = useAuthStore.getState();
  const isJoined = data?.team?.filter(
    (item: any) => item?.userId === userData?.id
  )?.length;

  return <>{!isJoined && <LeagueRegistrationModal league={data} />}</>;
};

const useUserLeaguesHook = () => {
  const navigate = useNavigate();
  const { filter, onChangeFilter } = useFilter({});
  const { data, isFetching } = useFetchLeaguesList({
    page: filter?.page,
    limit: filter?.limit,
  });
  const [leagues, setLeagues] = useState(data?.message ?? []);
  const [showOpenLeagues, setOpenLeagues] = useState(false);

  const onFilterValues = () => {
    const filteredLeagues = data?.message
      .filter((item: any) => {
        if (filter?.keywords) {
          return (
            item?.name
              ?.toLocaleLowerCase()
              // @ts-ignore
              ?.includes(filter?.keywords?.toLocaleLowerCase())
          );
        } else return item.name;
      })
      .filter((item: any) => {
        if (filter?.entryFee !== "All") {
          if (filter?.entryFee === "Free") {
            return item.entryFee === "0.00";
          } else {
            return item.entryFee !== "0.00";
          }
        } else return item.entryFee;
      })
      .filter((item: any) => {
        if (filter?.numberOfTeams !== "All") {
          // @ts-ignore
          const values = filter?.numberOfTeams?.split("-");
          return (
            item?.numberOfTeams >= Number(values[0]) &&
            item?.numberOfTeams <= Number(values[1])
          );
        } else return item?.numberOfTeams;
      })
      .filter((item: any) => {
        if (filter?.receivingOption !== "All") {
          // @ts-ignore
          return item.receivingOption === filter?.receivingOption;
        } else return item.receivingOption;
      });

    setLeagues(filteredLeagues);
  };

  const onShowJustOpen = () => {
    if (!showOpenLeagues) {
      setOpenLeagues(!showOpenLeagues);
      const openLeagues = data?.message?.filter(
        (item: any) => item?.status === "registration"
      );
      setLeagues(openLeagues);
    } else {
      setOpenLeagues(!showOpenLeagues);
      setLeagues(data?.message);
    }
  };

  useEffect(() => {
    onFilterValues();
  }, [
    filter?.numberOfTeams,
    filter?.receivingOption,
    filter?.draftDateTime,
    filter?.keywords,
    filter?.entryFee,
    data,
  ]);

  const filters = [
    {
      name: "Search",
      value: "keywords",
    },
    {
      name: "Entry Fee",
      value: "entryFee",
      filters: ["All", "Free", "Not Free"],
    },
    {
      name: "Number of Teams",
      value: "numberOfTeams",
      filters: ["All", "2-4", "4-10", "10-16", "16-32"],
    },
    {
      name: "League Type",
      value: "receivingOption",
      filters: ["All", "PPR", "Non-PPR"],
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Entry Fee",
      dataIndex: "entryFee",
      render: (_: string, record: any) => {
        return <EntryFeeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Teams",
      dataIndex: "teams",
      render: (_: string, record: any) => {
        return <TeamsRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Prize Fund",
      dataIndex: "prizeFund",
      render: (_: string, record: any) => {
        return <PrizeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "View League",
      dataIndex: "viewLeague",
      render: (_: string, record: any) => {
        return <ViewLeagueRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "Join League",
      dataIndex: "joinLeague",
      render: (_: string, record: any) => {
        return <JoinLeagueRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Eliminated",
      dataIndex: "eliminated",
      render: (_: string, record: any) => {
        return <EliminatedRender {...record} />;
      },
      sort: true,
    },
  ];

  return {
    columns,
    isFetching,
    filters,
    leagues,
    onShowJustOpen,
    onChangeFilter,
    filter,
  };
};

export default useUserLeaguesHook;
