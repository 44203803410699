import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const DraftServices = {
  launchDraft: async (leagueId: string) => {
    const endPoint = `/api/game/draft/start`;
    try {
      const draft = await axios.post(endPoint, { leagueId });
      return draft?.data;
    } catch (error) {
      throw error;
    }
  },
  getDraftDetails: async (draftId: string) => {
    const endPoint = `api/game/draft/teams/current/${draftId}`;
    try {
      const draftDetails = await axios.get(endPoint);
      return draftDetails?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  makePick: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/draft/pick`;
    try {
      const pick = await axios.post(endPoint, payload, config);
      return pick?.data;
    } catch (error) {
      throw error;
    }
  },
  getTeamQueue: async (teamId: string) => {
    const endPoint = `/api/game/draft/player/queue`;
    try {
      const queue = await axios.post(endPoint, { teamId });
      return queue?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  endDraft: async (leagueId: string) => {
    const endPoint = "/api/game/draft/end";
    try {
      const endDraft = await axios.post(endPoint, { leagueId });
      return endDraft?.data;
    } catch (error) {
      throw error;
    }
  },
  addPlayerToQueue: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/draft/player/add`;
    try {
      delete payload?.teamId;
      const addPlayer = await axios.post(endPoint, payload, config);
      return addPlayer?.data;
    } catch (error) {
      throw error;
    }
  },
  removePlayerFromQueue: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/draft/player/remove/${payload?.draftId}`;
    try {
      delete payload?.draftId;
      const removePlayer = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return removePlayer?.data;
    } catch (error) {
      throw error;
    }
  },
  removeAllQueue: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `api/game/draft/player/removeAll/${payload?.draftId}`;
    try {
      delete payload?.draftId;
      const removePlayer = await axios.delete(endPoint, {
        data: payload,
        headers: config.headers,
      });
      return removePlayer?.data;
    } catch (error) {
      throw error;
    }
  },
};
