import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Settings } from "../../../../assets/icons/settings.svg";
import demo_logo from "../../../../assets/images/demoLogo.png";
import useLeagueHook from "../hooks/useLeague";
import useAuthStore from "../../../../stores/auth/auth";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import { toast } from "react-toastify";
import { PaymentsServices } from "../../../../services/apis/Payments/PaymentsServices";
import { useState } from "react";

export const LeagueRegistrationModal = ({
  league,
  isLeagueView,
}: {
  league: any;
  isLeagueView?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <LeagueRegistration league={league} closeModal={closeModal} />
  );
  const { modalControl, openModal } = useModal({
    title: league?.entryFee !== "0.00" ? "League Registration" : "Join League",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });

  return (
    <>
      <Modal {...modalControl} />
      {isLeagueView ? (
        <Button
          leftIcon={<Settings />}
          className="rounded-[8px] w-auto px-4 text-nowrap !h-10"
          onClick={openModal}
        >
          Join League
        </Button>
      ) : (
          <>
            <Button
                leftIcon={<Settings />}
                className="rounded-[8px] w-full px-4 text-nowrap !h-10 block xl:hidden"
                onClick={openModal}
            >
              Join League
            </Button>
        <div onClick={openModal} className="flex-col cursor-pointer hidden xl:flex">
          <p className="underline text-text-red text-nowrap">Join League</p>
          <p className="text-sm text-text-subGrey">Action</p>
        </div>
          </>
      )}
    </>
  );
};

const LeagueRegistration = ({
  closeModal,
  league,
}: {
  closeModal?: () => void;
  league: any;
}) => {
  const { userData } = useAuthStore.getState();
  const [activeTab, setActiveTab] = useState(
    league?.entryFee !== "0.00" ? 1 : 2
  );
  const { joinLeague } = useLeagueHook(league?.id);

  const formOptions = useForm({
    defaultValues: {
      cardNumber: "",
      name: "",
      date: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await PaymentsServices.makeDeposit(userData?.id, {
        amount: league?.entryFee,
        ccNumber: data?.cardNumber,
        ccExp: data?.date,
      });
      setActiveTab(2);
    } catch (error: any) {
      toast.error(error?.message);
    }
  });

  const onConfirm = async () => {
    try {
      await joinLeague();
      closeModal?.();
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <form className="flex flex-col gap-4 pt-6">
        {activeTab === 1 ? (
          <div className="flex flex-col gap-4">
            <Controller
              control={control}
              name="cardNumber"
              render={({ field }) => (
                <LabeledInput
                  {...field}
                  placeholder="Enter your card number..."
                  error={errors?.cardNumber?.message}
                  maxLength={16}
                  errorClassName="text-text-red"
                  label="Card Number"
                  labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                  containerClassName="relative"
                  inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                />
              )}
            />
            <div className="flex gap-2">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Cardholder Name..."
                    error={errors?.name?.message}
                    errorClassName="text-text-red"
                    label="Cardholder Name"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
              />
              <Controller
                control={control}
                name="date"
                render={({ field }) => (
                  <LabeledInput
                    {...field}
                    placeholder="Date..."
                    error={errors?.date?.message}
                    errorClassName="text-text-red"
                    maxLength={4}
                    label="Date"
                    labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
                    containerClassName="relative"
                    inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
                  />
                )}
              />
            </div>
            <div className="w-full h-[1px] bg-modal-modalStroke mt-4" />
            <div className="flex justify-between items-center">
              <p className="text-base">Total:</p>
              <p className="text-[24px] font-absoluteEmpire">
                ${league?.entryFee}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full mt-6">
            <img
              src={league?.logo || demo_logo}
              alt="CutThroat"
              className="w-[100px] h-[100px] rounded-[50px]"
            />
            <p className="text-[24px] font-gilroyBold mt-4">
              Congratulations, {userData?.name}!
            </p>
            <p className="font-gilroyMedium text-text-subGrey mt-2">
              You are about to join the league. Please confirm your{" "}
              <span className="text-text-red">${league?.entryFee}</span> entry.
            </p>
          </div>
        )}

        <div className="flex flex-col gap-4 md:flex-row">
          <Button
            onClick={closeModal}
            size="md"
            mode="secondary"
            className="!text-base w-full !rounded-[8px]"
          >
            Cancel
          </Button>
          <Button
            onClick={activeTab === 1 ? onSubmit : onConfirm}
            size="md"
            className="!text-base w-full !rounded-[8px]"
          >
            {activeTab === 1 ? "Register" : "Confirm"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LeagueRegistrationModal;
