import { Link, useLocation } from "react-router-dom";
import { ReactComponent as NavIcon } from "../../assets/icons/nav-icon.svg";
import clsx from "clsx";
import useAuthStore from "../../stores/auth/auth.ts";

type Route = {
    name: string;
    path: string;
    isEnabled: boolean;
    selectedIcon?: JSX.Element;
};

const routes: Route[] = [
    {
        name: "HOME",
        path: "/profile",
        isEnabled: true,
        selectedIcon: NavIcon,
    },
    {
        name: "DASHBOARD",
        path: "/dashboard",
        isEnabled: true,
        selectedIcon: NavIcon,
    },
    {
        name: "LEAGUES",
        path: "/leagues",
        isEnabled: true,
        selectedIcon: NavIcon,
    },
    {
        name: "USERS",
        path: "/users",
        isEnabled: true,
        selectedIcon: NavIcon,
    },
    {
        name: "DRAFT TEST",
        path: "/draft",
        isEnabled: true,
        selectedIcon: NavIcon,
    },
];

const MobileSidebar = () => {
    const location = useLocation();
    const {  setIsMobileMenuOpen } = useAuthStore.getState();
    return (
        <div className="p-6 max-w-full h-3/6 fixed bg-black w-full block  md:hidden" style={{background:"black",zIndex:"2000"}}>
            <div className="flex flex-col">
                {routes.map((route, _index) => {
                    const isActive =
                        location.pathname === route.path ||
                        location.pathname.includes(route.path);
                    return (
                        <Link
                            key={route.name}
                            to={route.path}
                            className={clsx(
                                "px-4 py-2 font-absoluteEmpire flex flex-row justify-start items-center",
                                route.isEnabled
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed text-text-grey pointer-events-none"
                            )}
                            aria-disabled={!route.isEnabled}
                        >
                            {isActive && route.selectedIcon ? (
                                <NavIcon />
                            ) : (
                                <div className="w-2" />
                            )}
                            <span
                                className={clsx(
                                    "px-4 py-2 font-absoluteEmpire",
                                    isActive && "text-text-red"
                                )}
                                onClick={()=>setIsMobileMenuOpen(false)}
                            >
                {route.name}
              </span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default MobileSidebar;
