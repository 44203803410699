import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ADMIN_QUERY_KEY } from "./adminQueriesKeys";
import { AdminServices } from "../../services/apis/Admin/AdminServices";

export const useFetchAllUsers = (isTransactions?: boolean) => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_ALL_USERS],
    queryFn: () => AdminServices.getAllUsers(isTransactions),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUserById = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_USER, id],
    queryFn: () => AdminServices.getUserByID(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddCredits = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addCredits,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useBanUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.banUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useUnbanUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.unbanUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useAddBalance = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.addBalance,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_USER],
      });
    },
  });
};

export const useFetchUsersCount = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_USERS_COUNT],
    queryFn: () => AdminServices.getUsersCount(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchStats = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_STATS],
    queryFn: () => AdminServices.getStats(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLeaguesCount = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_LEAGUES_COUNT],
    queryFn: () => AdminServices.getLeaguesCount(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLatestTransactions = () => {
  const queryResult = useQuery<any>({
    queryKey: [ADMIN_QUERY_KEY.FETCH_TRANSACTIONS],
    queryFn: () => AdminServices.getLatestTransactions(),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AdminServices.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_QUERY_KEY.FETCH_ALL_USERS],
      });
    },
  });
};
