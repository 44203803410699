import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import clsx from "clsx";

const UserCard = ({ user }: { user: any }) => {
  return (
    <div className="flex justify-between items-center my-2">
      <div>
        <div className="flex items-center">
          <img
            src={user?.logo || demo_team_logo}
            alt="CutThroat"
            className="w-[40px] h-[40px] rounded-[50px] mr-3"
          />
          <div>
            <p className="text-base font-gilroyBold">{user?.name}</p>
            <p className="text-text-grey text-xs font-gilroyMedium capitalize">
              {user?.transaction?.[0]?.type}
            </p>
          </div>
        </div>
      </div>
      {user?.transaction?.length ? (
        <p
          className={clsx(
            "text-text-red font-gilroyBold",
            user?.transaction?.[0]?.type === "deposit" && "!text-text-green"
          )}
        >
          {user?.transaction?.[0]?.type === "deposit" ? "+" : "-"}$
          {user?.transaction?.[0]?.amount}
        </p>
      ) : null}
    </div>
  );
};
export default UserCard;
