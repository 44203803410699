const privacyPolicy = [
  {
    title: "1. Introduction",
    content: `1.1 CutThroat Fantasy Sports, LLC ("CutThroat") recognizes that people who use 
      CutThroat's Service ("you" or "users") value their privacy. This Privacy Policy details 
      important information regarding the collection, use and disclosure of User information 
      collected on CutThroat's fantasy sports website located at CutThroatfantasyfootball.com
      (the "Site"), the Site's related mobile app,  and any other features, tools, materials, or 
      other services (including co-branded or affiliated services) offered from time to time by 
      CutThroat (the "Services") CutThroat provides this Privacy Policy to help you make an 
      informed decision about whether to use or continue using the Service.
      1.2 This Privacy Policy should be read in conjunction with our Terms of Use. By 
      accessing the Services, you are consenting to the information collection and use 
      practices described in this Privacy Policy.
      1.3 Your use of the Services and any information you provide through the Services 
      remains subject to the terms of this Privacy Policy and our Terms of Use, as each may 
      be updated from time to time.
      1.4 Any questions, comments or complaints that you might have about this Privacy 
      Policy should be emailed to CutThroatFantasySports@gmail.com or forwarded in writing 
      to:
      CutThroat Fantasy Sports, LLC
      548 Market St #675217
      San Francisco, CA 94104`,
  },
  {
    title: "2. Information We Collect",
    content: `By using our Services and by submitting information to us through your use of our 
      Services then this Privacy Policy will apply. You provide certain personal information to 
      CutThroat when choosing to participate in the various activities on the Services such as 
      registering for an account, participating in contests, posting messages, taking 
      advantage of promotions, responding to surveys or subscribing to newsletters or other 
      mailing lists, or otherwise communicating with us or making a request on the Services. 
      If you contact us, by email, text message, phone call, or otherwise, in addition to any 
      personal information we may receive, we may record and store the contents of those 
      communications ourselves or using a third-party vendor. We may use the contents of 
      these communications for training or other purposes and to satisfy our legal, auditing, 
      and other obligations, or for any reason set forth in this Privacy Policy. Your 
      communications with us might include requests, questions, and/or issues regarding your
      use of the Services or other topics you might raise.
      The personal information we collect from you generally may include:
      2.1.1 your name;
      2.1.2 email address;
      2.1.3 date of birth;
      2.1.4 PayPal or credit card billing information;
      2.1.5 contacts you choose to submit;
      2.1.6 profile photo;
      2.1.7 username;
      2.1.8 location;
      2.1.9 your preferences;
      2.1.10 submissions to various competitions and promotions;
      2.1.11 responses to surveys;
      2.1.12 communications sent to us by you;
      2.1.13 your subscriptions to newsletters and services offered by us; and
      2.1.14 any other information you submit to CutThroat when choosing to participate in 
      various activities on the Services.
      2.2 In addition to the above, we may need to verify your identity in order for you to use 
      some aspects of the Services. For purposes of verification, we may also collect the 
      following personal information from you (for compliance reasons, provision of this 
      information, when requested, is a mandatory condition of using our Services):
      2.2.1 passport information;
      2.2.2 driving license information;
      2.2.3 other identification documents;
      2.2.4 permanent and temporary address;
      2.2.5 tax-related information; and
      2.2.6 other information as may be required to verify you in accordance with applicable 
      laws and regulations.
      2.3 When you use our mobile apps, we also may collect mobile device information like 
      operating system and hardware type, numbers or codes that are unique to your 
      particular device (such as IDFA or an Android Ad ID), device information, default device 
      language, the location of your device (at a GPS level), and app usage information. This 
      data also may be linked to your other information, including your location data. Our 
      Services use precise location-based services (e.g., location collected through GPS 
      technology) in order to locate you so we may verify your location, process payments, 
      perform analytics, deliver you relevant content and ads based on your location, share 
      your location with our vendors as part of the location-based services we offer, and for 
      purposes of legal and regulatory compliance. We also collect non-precise geolocation 
      data (i.e., the city and state in which your device is located based on its IP address). 
      This non-precise geolocation data allows us to customize your experience, give you 
      access to content that varies based on your general location, and serve you ads that are
      relevant to you. Both precise and non-precise geolocation may be disclosed to third 
      party entities pursuant to Section 4.10 below.
      2.4 In addition, if you choose to log in, access or otherwise connect to CutThroat, or 
      contact CutThroat, through a social networking service (such as Facebook), we may 
      collect your user ID and user name associated with that social networking service, as 
      well as any information you make public using that social networking service. We may 
      also collect information you have authorized the social networking service to share with 
      us (such as your user ID, public profile information, email address, birthday, friends list, 
      and pages you have "liked").
      2.5 Cookies Information: When you visit the CutThroatfantasyfootball.com website, we 
      may send one or more cookies - small files - to your computer or other device, which 
      may enable us or others to uniquely identify your browser. CutThroat uses both session 
      cookies and persistent cookies. A persistent cookie remains after you close your 
      browser. Persistent cookies may be used by your browser on subsequent visits to the 
      Sites. Persistent cookies can be removed by following your web browser help file 
      directions. Session cookies are temporary and typically disappear after you close your 
      browser. You may be able to reset your web browser to refuse all cookies or to indicate 
      when a cookie is being sent. However, some features of the Site or Service may not 
      function properly if the ability to accept cookies is disabled.
      2.6 Log File Information: When you use our Service, our servers may automatically 
      record certain information that your device sends whenever you visit any website and 
      use certain apps. These server logs may include information such as your web or app 
      request, Internet Protocol ("IP") address, browser type, browser language, referring/exit 
      pages and URLs, platform type, number of clicks, domain names, landing pages, pages 
      viewed and the order of those pages, the amount of time spent on particular pages, the 
      date and time of your request, and one or more cookies that may uniquely identify your 
      browser.
      2.7 Clear GIFs Information: When you use our Services, we may employ technology such
      as "clear GIFs" (a.k.a. Web Beacons) which are used to track the online usage patterns 
      of our users. In addition, we may also use clear GIFs in HTML-based emails sent to our 
      users to track which emails are opened by specific users.
      2.8 CutThroat may also collect information about you from third parties, such as 
      marketing partners, identity verification services, anti-fraud services and other service 
      providers. You authorize your wireless carrier to use or disclose information about your 
      account and your wireless device, if available, to us or our service provider for the 
      duration of your business relationship, solely to help us identify you or your wireless 
      device and to prevent fraud.`,
  },
  {
    title: "3. The Way CutThroat Uses Your Personal Information",
    content: `3.1 We use your personal information to operate, maintain, and provide to you the 
      features and functionality of the Services, including but not limited to the following:
      3.1.1 providing you with our products and services, including our games;
      3.1.2 processing and responding to enquiries;
      3.1.3 personalizing your use of the Services;
      3.1.4 alerting you to new features, special events, products and services, or certain 
      third-party products or services in which we think you will be interested;
      3.1.5 enforcing the legal terms that govern your use of the Service; and
      3.1.6 investigating and protecting the integrity of CutThroat's contests.
      3.2 We may use your information (both personal and non-personal information) to send 
      you marketing and advertising content, including sending you advertising through 
      multiple channels, such as direct mail, email, push notifications display media, and 
      personal text messages. We may send you advertising or content regarding our 
      products and services, or products and services that we market on behalf of another 
      company, such as a sports team, a sports venue, or other entity.
      3.3 We may use your information to communicate with you about:
      3.3.1 our products and services in which you may be interested provided that you have 
      not requested otherwise;
      3.3.2 newsletters and information for which you have signed up; and
      3.3.3 non-marketing or administrative purposes (such as notifying you of major changes
      to the Service or for customer service purposes).
      3.3 We use your information to improve the quality and design of our Service and to 
      create new features, promotions, functionality, and services such as by storing, 
      tracking, and analyzing user preferences and trends.
      3.4 We also use cookies, clear gifs, log file information, and mobile app information such
      as device identifiers for purposes such as (a) remembering information so that you will 
      not have to re-enter it during your visit or the next time you visit our Service; (b) 
      providing custom, personalized content and information; (c) monitoring the 
      effectiveness of our marketing campaigns; (d) monitoring aggregate metrics such as 
      total number of visitors, pages viewed, etc.; and (e) tracking your entries, submissions, 
      and status in promotions, sweepstakes, and contests. You can learn more about how 
      these technologies are used in the Section below, Third Party Advertising and Analytics.
      3.5 We may anonymize, de-identify, or aggregate your information for any legitimate 
      business purposes, including for reporting and research, or advertising. Such 
      anonymized and/or aggregated data does not personally identify you. Sometimes, this 
      information may be used to target more relevant content or offers to you across 
      different devices \u2013 for instance, we may provide you with offers and content on 
      your mobile device, based on your web-based activity.
      3.6 We will retain personal information and the contents of our communications with 
      you as long as necessary to fulfill these purposes unless the law requires us to keep it 
      for a longer period of time. To provide security and business continuity for the activities 
      described here, we make backups of certain data, which we may retain for longer than 
      the original data.`,
  },
  {
    title: "4. When CutThroat Discloses Information",
    content: `4.1 We may disclose the information we collect or receive from you to our subsidiaries, 
      affiliated companies, agents, or other businesses, or service providers who process your
      information on our behalf in order to provide or operate the Service. For instance, these 
      entities may have access to your information for purposes including (but not limited to) 
      billing and accounting, hosting, marketing, promotional and email services, customer 
      and technical support, and for the purpose of accepting deposits and paying 
      withdrawals, facilitating communications with you and/or providing customer support, 
      and processing such information on our behalf, and in each case, may use the 
      information to make improvements to the services they provide to us. Our agreements 
      with these service providers limit the kinds of information they can use or process and 
      require them to use reasonable efforts to keep your personal information secure. Please
      note, if we use a vendor to facilitate or assist in communication with you, the vendor will
      receive your personal information (such as your name, email, or telephone number) and
      may record and store the content of your communications with us; however, we do not 
      disclose mobile phone numbers to unaffiliated third parties for their marketing purposes
      without your express consent. For instance, our SMS messaging services are powered 
      by Zoom. To provide us with such service, Zoom will receive your phone number and 
      may receive and maintain copies of your SMS or text communications with us.
      4.2 When you choose to share information with social media services about your 
      activities on CutThroat by, for example, connecting your social media account to our 
      Services, then CutThroat may share your personal information and information about 
      your use of the Services with such social media sites. Please make sure to read the 
      terms and privacy statements of such sites prior to connecting to our Services in this 
      way.
      4.3 In the event that you win a prize we may use your personal information (such as 
      your name and photo) in connection with publicity purposes.
      4.4 In the event that you win a promotion run by us we may be required to either 
      publish or make available upon request your name and county in accordance with 
      applicable legislation.
      4.5 We may publish a profile page that includes your username, the date you became a 
      member, your stats from previous contests and, if you uploaded one, your profile 
      picture. Where you post personal information about yourself through our Services to 
      public areas of our Services, you acknowledge and agree that this personal information 
      will be publicly available.
      4.6 When you participate in a contest or skill game, we may publish your username on a
      list of the contest or skill game participants, along with a link to your profile page.
      4.7 Where you have provided your consent, we may share your personal information 
      with selected third parties for their commercial or marketing use in conjunction with 
      your relationship with CutThroat or as part of a specific program or feature.
      4.8 In addition, we may disclose to third parties various types of your information for 
      targeted online or mobile advertising, as further described in the Section titled "Third 
      Party Advertising and Analytics."
      4.9 We also may share personal information in other situations with your consent.
      4.10 We may access, use, preserve, transfer and disclose any of your information 
      (including the content of your communications with us, device identifiers, and personal 
      information, including precise geolocation data and other potentially sensitive personal 
      information) to third parties: (i) to satisfy any applicable law, regulation, subpoenas, 
      governmental requests or legal process if in our good faith opinion such is required or 
      permitted by law; (ii) to protect and/or defend the Services, its integrity, and our Terms 
      and Conditions or other policies applicable to the Services, including the investigation, 
      determination, or validation of potential violations thereof; (iii) to report violations of 
      CutThroat's eligibility rules to third parties, including employers of individuals affiliated 
      with other daily fantasy sites; (iv) to protect the safety, rights, property, integrity, or 
      security of the Services or any third party; and/or (v) to detect, prevent or otherwise 
      address fraud, security or technical issues. Such potential disclosures include disclosing 
      Personal Information and Device Identifiers to gaming regulators, leagues, associations, 
      governing bodies, sports teams, colleges or oversight agencies or boards (or their 
      designated vendors) in relation to the enforcement of our Terms and Conditions and 
      their rules governing authorized betting, including in connection with identifying or 
      overseeing their athletes, employees, personnel, or members. We may also use IP 
      address or other device identifiers to identify individuals, either acting alone or in 
      cooperation with third parties such as copyright owners, internet service providers, 
      wireless service providers and/or law enforcement agencies. Such identifying 
      information may be disclosed pursuant to this Section.
      4.11 In the event that CutThroat is involved in a merger, acquisition, sale, bankruptcy, 
      insolvency, reorganization, receivership, assignment for the benefit of creditors, or the 
      application of laws or equitable principles affecting creditors' rights generally, or other 
      change of control, there may be a disclosure of your personal information to another 
      entity related to such event.
      4.12 Recipients of the data disclosures described in this Privacy Policy are located in the
      United States and elsewhere in the world, including where privacy laws may not provide
      as much protection as your country.`,
  },
  {
    title: "5. Legal Bases for Processing Personal Data",
    content: `5.1 Some laws require companies to tell you about the legal grounds they rely on to 
        process your personal information. To the extent those laws apply, we process your 
        personal information:
        5.1.1 Legitimate interests: In many cases, we handle personal data on the ground that it
        furthers our legitimate interests' in commercial activities such as the following in ways 
        that are not overridden by the interests or fundamental rights and freedoms of the 
        affected individuals:
        (a) Customer service
        (b) Marketing
        (c) Protecting our players, personnel and property
        (d) Analyzing and improving our business
        (e) Processing job applications
        (f) Managing legal issues
        5.1.2 We may also process personal data for the legitimate interests of our affiliates or 
        business partners, such as to offer special events or contests or assist with marketing.
        5.1.3 Processing for the contract: Some of our processing of personal data is to meet 
        our contractual obligations with our players.
        5.1.4 Consent: Where required by law, and in some other cases, we handle personal 
        data on the basis of your implied or express consent.
        5.1.5 Legal compliance: We need to use and disclose personal data in certain ways to 
        comply with our legal obligations.`,
  },
  {
    title: "6. Third Party Advertising And Analytics",
    content: `6.1 We work with a number of companies that assist in marketing our services to you on
        third party websites. These companies may collect information about online activities 
        conducted on a particular computer, browser or device over time and across third-party 
        websites or online services for the purpose of delivering advertising that is likely to be 
        of greater interest to you, including about our Services, on sites and apps of third 
        parties. Also, certain ads appearing on our Services are delivered by our advertising 
        partners. Our advertising partners may use cookies and other similar technologies, 
        often in conjunction with unique (anonymous) cookie identifiers, to collect data about 
        your activities and interests, including the technologies described above. Some of these
        partners may use mobile device identifiers, such as Apple IDFAs and Google/Android 
        Advertising IDs. Doing this allows the ad companies to recognize your computer or 
        device each time they send you an online or mobile advertisement. In this way, the ad 
        companies may compile information about where you, or others who are using your 
        computer or device, saw their advertisements and measure your interaction with each 
        ad. This information allows ad companies to deliver targeted advertisements that may 
        be of increased interest to you, both on our Services and elsewhere online. Just as these
        technologies may be used to target ads on our websites and apps, we, or our 
        advertising partners, may use these same technologies and data points (e.g., mobile 
        identifiers, cookie identifiers, location-based data), through our Services or through 
        other services, to target advertising (for ourselves or other companies) on other sites or 
        mobile apps. Sometimes, these identifiers may be derived from a hashed or encrypted 
        version of personal information such as your email address. We may use this 
        information to measure the performance of our advertising as well, for instance, to 
        evaluate which ads or content our users prefer, or which are most effective. In addition, 
        sometimes we or an advertising partner may tailor advertising to you across different 
        devices.
        6.2 To learn more and to opt out of the collection of data on our website by third parties
        (including those described above) for interest-based advertising purposes, please 
        visit www.aboutads.info/choices or www.youronlinechoices.com. The use of cookies or 
        other tracking technologies that may be placed on devices you use to access our 
        Services by non-affiliated third parties is governed by the privacy policies of those third 
        parties. If you are interested in more information about tailored browser advertising and
        how you can generally control cookies from being put on your devices to deliver tailored
        advertising, you may visit the Network Advertising Initiative's Consumer Opt-Out link or 
        the Digital Advertising Alliance's Consumer Opt-Out link to opt out of receiving tailored 
        advertising from companies that participate in those programs. To opt out of Google 
        Analytics for display advertising or customize Google display network ads, visit 
        the Google Ads Settings page. For Mobile Applications, you can use the DAA App Opt-
        Out Tool and the NAI App Opt-Out Tool. Please note that these opt-outs apply per 
        browser and per device, so you will have to opt out for each device—and each browser 
        on each device—through which you access the Service. Note that opting out will not 
        affect the quantity of ads you see online but likely will cause the ads to be less relevant 
        to you.
        6.3 We also that we also work with third-party analytics companies to collect data on 
        our Service through cookies and other automated means. In some cases, the third 
        parties mentioned in this section may maintain the information they collect in 
        personally identifiable form. These services allow us to view a variety of reports about 
        how visitors interact with the Services so we can improve our website and understand 
        how people find and navigate it.`,
  },
  {
    title: "7. Information Security",
    content: `7.1 We are committed to protecting the security of your personal information. We use a 
            variety of security technologies and procedures to help protect your personal 
            information from unauthorized access, use, or disclosure.
            7.2 Whilst neither we, nor any other organization, can guarantee the security of 
            information processed online, we do have appropriate security measures in place to 
            protect your personal information. For example, we store the personal information you 
            provide on computer systems with limited access that are located in facilities to which 
            access is limited.
            7.3 To further protect your privacy and security, we reserve the right to take reasonable
            steps (such as requesting your password) to verify your identity before granting you 
            profile access or making corrections.
            7.4 Additionally, you should ensure your password is kept secret at all times. You will be
            responsible for maintaining the secrecy of your password and account information.`,
  },
  {
    title: "8. Your Rights and Choices",
    content: `8.1 CutThroat will process your personal information in accordance with this Privacy 
        Policy, and as part of that CutThroat provides you with certain choices about how we 
        process your personal information. These choices are set out below.
        8.2 You can access and update certain personal information CutThroat holds about you 
        at any time by logging into your account via the Services.
        8.3 We may occasionally send display media to you, in a targeted way, whether through
        web or mobile browsers or mobile applications. You may opt out of many third parties 
        that support and send this type of targeting advertising by going to www.aboutads.info, 
        and you may learn more about this type of advertising in the Section titled "Third Party 
        Advertising and Analytics." You may opt out of tracking for mobile advertising through 
        the settings on most smartphones, and you may learn more about these settings 
        through those mobile device platforms, i.e., Google ( here) and Apple ( here). (Please 
        note that these companies may change either the way these settings operate, the 
        content or the availability of these pages.)
        8.4 We may occasionally send you push notifications to your mobile device. You can 
        turn off certain push notifications in the account settings of your CutThroat mobile app. 
        You can also turn off push notifications specific to our Services in the settings on your 
        iOS device, or through the application manager in your Android device.
        8.5 Marketing Communications. In accordance with applicable law, we or our vendors 
        may send you marketing and promotional emails. If you would like to opt out of email 
        marketing, notify us at privacy@CutThroat.com or follow the unsubscribe instructions in 
        the email. You can also update your marketing preferences and whether or not you 
        would like us to send you newsletters in your account. Please note that even though you
        may opt-out of receiving marketing-related communications, we may still send you 
        important administrative and transactional messages (e.g., notifications regarding 
        updates to our legal terms).
        8.6 Rights Regarding Your Information. Depending on your jurisdiction, you may have 
        the right, in accordance with applicable data protection laws, to make requests related 
        to your "personal information" or "personal data" (as such terms are defined under 
        applicable law, and collectively referred to herein as "personal information"). 
        Specifically, you may have the right to ask us to:
        Inform you about the categories of personal information we collect or disclose 
        about you; the categories of sources of such information; the business or 
        commercial purpose for collecting your personal information; and the categories 
        of third parties with whom we disclose personal information.
        Provide you access to and/or a copy of certain personal information we hold 
        about you. Note that you may be able to access certain of this information by 
        logging into your account.
        Correct or update personal information we hold about you.
        Delete certain personal information we have about you.
        Provide you with information about the financial incentives that we offer to you, if
        any.
        Restrict or object to certain uses of your personal information.
        Withdraw consent to using your personal information (which does not affect the 
        legality of any processing that happened before the request takes effect).
        Opt you out of the processing of your personal information for direct marketing 
        or purposes of profiling in furtherance of decisions that produce legal or similarly 
        significant effects, if applicable.
        Please note that certain information may be exempt from such requests under 
        applicable law. For example, we need certain information in order to provide the 
        Services to you. You may also have the right to opt out of “sales” of your information 
        and “sharing/processing of your information for targeted advertising” as described 
        below.
        If you are a California resident, please see Section 9 below for more information about 
        our privacy practices and your rights. As provided in applicable law, you also have the 
        right to not be discriminated against for exercising your rights. Please note that certain 
        information may be exempt from such requests under applicable law. For example, we 
        need to retain certain information in order to provide our services to you. We also need 
        to take reasonable steps to verify your identity before responding to a request, which 
        may include, at a minimum, depending on the sensitivity of the information you are 
        requesting and the type of request you are making, verifying your name and email 
        address. If we are unable to verify your identity, we may be unable to respond to your 
        requests.
        To exercise any of these rights, please email us at cutthroatfantasysports@gmail.com
        You may be able to designate an authorized agent to make requests on your behalf. In 
        order for an authorized agent to be verified, you must provide the authorized agent with
        signed, written permission to make such requests or a power of attorney. We may also 
        follow up with you to verify your identity before processing the authorized agent's 
        request as permitted by applicable law.
        Depending on applicable law, you may have the right to appeal our decision to deny 
        your request, if applicable. We will provide information about how to exercise that right 
        in our response denying the request. You also have the right to lodge a complaint with a
        supervisory authority. However, we respectfully invite you to allow us to try to resolve 
        the matter directly. We will attempt to answer your questions and satisfy your concerns 
        in a timely and complete manner.
        8.7 Notice of Right to Opt Out of Sales of Personal Information and Processing/Sharing of
        Personal Information for Targeted Advertising Purposes. Depending on your jurisdiction, 
        you may also have the right to opt out of "sales" of your information and 
        "sharing/processing of your information for targeted advertising."
        We may disclose information to unaffiliated third parties we collaborate with or that 
        provide offers that we think may be of value to you. We may also provide information to
        third-party advertising providers for targeted advertising purposes or use advertising 
        analytics partners to assist us in analyzing use of our services and our user/customer 
        base. Under applicable law, the disclosure of your personal information to these third 
        parties to assist us in providing these services may be considered a "sale" of personal 
        information or the processing/sharing of personal information for targeted advertising 
        purposes.
        If you would like to opt out of the disclosure of your personal information for purposes 
        that could be considered “sales” for those third parties' own commercial purposes, or 
        “sharing” or processing for purposes of targeted advertising, please email 
        cutthroatfantasysports@gmail.com to submit a request You must make this choice on 
        each site/app on each browser/device you use to access the Services. You must also 
        renew this choice if you clear your cookies or your browser is set to do that. Submitting 
        the form will also result in the opt out of the use of your email address and other 
        personal information related to that email address for targeted advertising purposes.
        Depending on your jurisdiction, you may be permitted to designate an authorized agent 
        to submit such requests on your behalf. Please note that we do not knowingly sell the 
        personal information of minors under 16 years of age without legally-required 
        affirmative authorization. Please note that we do not knowingly sell the personal 
        information of minors under 16 years of age.
        Please note that if you have a legally-recognized browser-based opt out preference 
        signal turned on via your device browser, we recognize such preference in accordance 
        with applicable law.`,
  },
  {
    title: "9. Notice to California Residents",
    content: `9.1 California Consumer Privacy Act. If you are a California resident, California law 
        requires us to provide you with some additional information regarding how we collect, 
        use, and share your "personal information" (as defined in the California Consumer 
        Privacy Act ("CCPA")).
        Categories of personal information we collect. Throughout this Privacy Policy, we 
        discuss in detail the specific pieces of personal information we collect from and about 
        users. Under the CCPA, we are also required to provide you with the "categories" of 
        personal information we collect. The categories we may collect are: identifiers (such as 
        name, address, email address, driver's license); commercial information (such as 
        deposit or contest entry data); financial data (such as payment information); internet or 
        other network or device activity (such as browsing history or Services usage); 
        geolocation information (e.g., your city and state based on IP address); professional or 
        employment related data (e.g., as part of your profile); audio information (e.g., if you 
        participate in a customer support call and do not opt out of call recording); in certain 
        circumstances, information used to manage potential fraud or legal risk (such as 
        employment status and criminal history); inference data about you; and other 
        information that identifies or can be reasonably associated with you.
        How we use and disclose these categories of personal information. We use and disclose 
        the categories of personal information we collect from and about you consistent with 
        the various business purposes we discuss throughout this Policy, and how you engage 
        with the Services. Please see the relevant section(s) above for more information.
        9.2 Your Choices Regarding "Sharing" and "Selling"
        We may disclose information to unaffiliated third parties we collaborate with or that 
        provide offers that we think may be of value to you. We may also provide information to
        third-party advertising providers for targeted advertising purposes or use advertising 
        analytics partners to assist us in analyzing use of our services and our user/customer 
        base. Under applicable law, the disclosure of your personal information to these third 
        parties to assist us in providing these services may be considered a "sale" of personal 
        information or the processing/sharing of personal information for targeted advertising 
        purposes.
        If you would like to opt out of the disclosure of your personal information for purposes 
        that could be considered “sales” for those third parties' own commercial purposes, or 
        “sharing” or processing for purposes of targeted advertising, please email 
        cutthroatfantasysports@gmail.com to submit a request. You must make this choice on 
        each site/app on each browser/device you use to access the Services. You must also 
        renew this choice if you clear your cookies or your browser is set to do that. Submitting 
        the form will also result in the opt out of the use of your email address and other 
        personal information related to that email address for targeted advertising purposes.
        Depending on your jurisdiction, you may be permitted to designate an authorized agent 
        to submit such requests on your behalf. Please note that we do not knowingly sell the 
        personal information of minors under 16 years of age without legally-required 
        affirmative authorization. Please note that we do not knowingly sell the personal 
        information of minors under 16 years of age.
        Please note that if you have a legally-recognized browser-based opt out preference 
        signal turned on via your device browser, we recognize such preference in accordance 
        with applicable law.
        9.3 Shine the Light Disclosures. If you reside in California, you may request certain 
        general information regarding our disclosure of personal information during the 
        preceding year to third parties for their direct marketing purposes. To make such a 
        request, please write to us at cutthroatfantasysports@gmail.com.`,
  },
  {
    title: "10. Changes and updates",
    content: `10.1 This Privacy Policy may be revised periodically and this will be reflected by the 
        "Last update posted" date above. Please revisit this page to stay aware of any changes. 
        If a revision to this Privacy Policy, in our sole discretion, is material, we will notify you by
        contacting you through the email address associated with your account.
        10.2 Contact Information: Please contact CutThroat with any questions or comments 
        about this Privacy Policy by emailing cutthroatfantasysports@gmail.com.`,
  },
];

export default privacyPolicy;
