import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { USER_QUERY_KEY } from "./userQueriesKeys";
import { UserServices } from "../../services/apis/User/UserServices";

export const useFetchUserDetails = (userId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
    queryFn: () => UserServices.getUserDetails(userId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!userId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: UserServices.updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY.FETCH_USER_DETAILS],
      });
    },
  });
};
