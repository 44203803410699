import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Edit } from "../../../../assets/icons/edit2-icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import demo_logo from "../../../../assets/images/demoLogo.png";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "../../../../components/LabeledInput/LabeledInput";
import {
  useUpdateUser,
  useUserById,
} from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";
import useSignupHook from "../../../../pages/guest/Signup/hooks/useSignup";
import { emailValidation } from "../../../../utils/helpers";
import { useMemo } from "react";

export const EditUserModal = ({
  isListView,
  userId,
  isMobile,
}: {
  isListView?: boolean;
  userId: string;
  isMobile?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <EditUser closeModal={closeModal} userId={userId} />
  );
  const { modalControl, openModal } = useModal({
    title: "Edit User Profile",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      {isListView ? (
        <>
          {isMobile ? (
            <Button
              leftIcon={<Edit />}
              mode="secondary"
              onClick={openModal}
              className="rounded-[8px] w-full px-4 text-nowrap !h-10 hidden md:flex"
            >
              Edit Profile
            </Button>
          ) : (
            <EditIcon className="cursor-pointer" onClick={openModal} />
          )}
        </>
      ) : (
        <>
          <Button
            leftIcon={<Edit />}
            mode="secondary"
            onClick={openModal}
            className="rounded-[8px] w-auto px-4 text-nowrap !h-10 hidden md:flex"
          >
            Edit Profile
          </Button>
          <Button
            leftIcon={<Edit />}
            mode="primary"
            onClick={openModal}
            className="rounded-[8px] w-full px-4 text-nowrap !h-10 block md:hidden"
          >
            Edit Profile
          </Button>
        </>
      )}
    </>
  );
};

const EditUser = ({
  closeModal,
  userId,
}: {
  closeModal?: () => void;
  userId: string;
}) => {
  const { data: user } = useUserById(userId!);
  const { checkEmailUsername } = useSignupHook();
  const { mutateAsync } = useUpdateUser();

  const firstName = useMemo(() => user?.name?.split(" ")?.[0], [user?.name]);
  const secondName = useMemo(
    () =>
      user?.name
        ?.split(" ")
        ?.splice(1, user?.name?.split(" ")?.length - 1)
        ?.join(" "),
    [user?.name]
  );

  const formOptions = useForm({
    values: {
      name: firstName || "",
      surname: secondName || "",
      email: user?.email || "",
      username: user?.username || "",
    },
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = formOptions;

  const onSubmit = handleSubmit(async () => {
    try {
      await mutateAsync({
        userId: user?.id,
        name:
          formOptions.getValues("name") +
          " " +
          formOptions.getValues("surname"),
        email: formOptions.getValues("email"),
        username: formOptions.getValues("username"),
      });
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      closeModal?.();
    }
  });
  return (
    <>
      <div className="flex flex-col items-center mt-8">
        <img
          src={user?.profileUrl || demo_logo}
          alt="Avatar"
          className="w-[100px] h-[100px] rounded-[50px]"
        />
      </div>
      <div className="flex gap-4 mt-6">
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <LabeledInput
              {...field}
              placeholder="Enter name"
              error={errors?.name?.message}
              required
              errorClassName="text-text-red"
              label="Name"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
          )}
          rules={{
            required: {
              value: true,
              message: "<p>This is a wrong Name, please use your real name</p>",
            },
          }}
        />
        <Controller
          control={control}
          name="surname"
          render={({ field }) => (
            <LabeledInput
              {...field}
              placeholder="Enter surname"
              error={errors?.surname?.message}
              required
              errorClassName="text-text-red"
              label="Surname"
              labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
              containerClassName="relative"
              inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="username"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter username"
            error={errors?.username?.message}
            required
            errorClassName="text-text-red"
            label="Username"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          minLength: {
            value: 6,
            message: `<div>
          <p>The username must meet complexity requirements:</p>
          <div className="flex flex-row items-center mt-2">
          <div className="h-2 w-2 rounded-[20px] bg-gradient-to-br from-red-light-1 to-red-light-2 mr-2"></div>
       <p>Minimum 6 characters</p>
        </div></div>`,
          },
          validate: {
            value: async (value) => {
              const check = await checkEmailUsername({
                username: value,
              });
              if (user?.username !== value && !check) {
                return "<p>Username has already been taken</p>";
              }
              return true;
            },
          },
        }}
      />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <LabeledInput
            {...field}
            placeholder="Enter email"
            error={errors?.email?.message}
            required
            errorClassName="text-text-red"
            label="Email"
            labelClassName="absolute top-[12px] left-[16px] !text-text-subGrey !text-xs"
            containerClassName="relative mt-4"
            inputContainerClassName="rounded-[8px] !h-[60px] border-[1px] bg-modal-inputBg border-modal-inputBorder !px-4 pt-7"
          />
        )}
        rules={{
          pattern: {
            value: emailValidation,
            message:
              "<p>The email address did not pass format validation, enter another address</p>",
          },
          validate: {
            value: async (value) => {
              const check = await checkEmailUsername({ email: value });
              if (user?.email !== value && !check) {
                return "<p>This email address is already in use, please enter a different one</p>";
              }
              return true;
            },
          },
        }}
      />

      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={closeModal}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Back
        </Button>
        <Button
          onClick={onSubmit}
          disabled={!isDirty}
          size="md"
          className="!text-base w-full !rounded-[8px] !h-[39px]"
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default EditUser;
