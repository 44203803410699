import { useEffect, useState } from "react";
import login1 from "../../../../assets/images/login/1.png";
import login2 from "../../../../assets/images/login/2.png";
import login3 from "../../../../assets/images/login/3.png";
import login4 from "../../../../assets/images/login/4.png";
import login5 from "../../../../assets/images/login/5.png";
import login6 from "../../../../assets/images/login/6.png";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AuthServices } from "../../../../services/apis/Auth";
import { validateEmail } from "../../../../utils/helpers";
import { useNavigate } from "react-router";

const useLoginHook = () => {
  const navigate = useNavigate();
  const images = [login1, login2, login3, login4, login5, login6];
  const [activeTab, setSetActiveTab] = useState<number>(1);
  const [isRemembered, setIsRemembered] = useState<boolean>(false);
  const [randomImageSrc, setRandomImageSrc] = useState<string>("");

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images?.length);
    setRandomImageSrc(images[randomIndex]);
  };

  useEffect(() => {
    getRandomImage();
  }, []);

  const formOptions = useForm({
    defaultValues: {
      password: "",
      email: "",
      code: "",
    },
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setError,
  } = formOptions;

  const email = getValues("email");

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (activeTab === 1 && isValid) {
        const login = await AuthServices.login({
          [validateEmail(data.email) ? "email" : "username"]: data.email,
          password: data.password,
          rememberMe: isRemembered,
        });
        if (login?.data?.twoStepAuth) {
          setSetActiveTab(2);
        } else {
          window.location.reload();
        }
      } else if (activeTab === 2 && isValid) {
        await AuthServices.verify({
          [validateEmail(data.email) ? "email" : "username"]: data.email,
          verificationCode: data.code,
        });
        window.location.reload();
      }
    } catch (error: any) {
      toast.error(error?.message);
      if (activeTab === 1) {
        setError("email", {
          type: "manual",
          message: "<p>You entered the wrong email or username</p>",
        });
        setError("password", {
          type: "manual",
          message: "<p>You entered the wrong password</p>",
        });
      } else {
        setError("code", {
          type: "manual",
          message: "<p>You entered the wrong verification code</p>",
        });
      }
    }
  });
  return {
    email,
    randomImageSrc,
    control,
    errors,
    activeTab,
    isRemembered,
    navigate,
    setIsRemembered,
    onSubmit,
  };
};

export default useLoginHook;
