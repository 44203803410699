import useAuthStore from "../../stores/auth/auth";

export const AuthSessionServices = {
  createSession: async () => {
    try {
      const { loginData } = useAuthStore.getState();
      if (!loginData?.access_token) {
        throw new Error("No login data found");
      }
      useAuthStore.setState({ isLoggedIn: true });
    } catch (error) {
      console.log("error", error);
      AuthSessionServices.clearSession();
    }
  },
  clearSession: async () => {
    try {
      useAuthStore.setState({ isLoggedIn: false, loginData: null });
      localStorage.clear();
    } catch (error) {
      console.log("error", error);
    }
  },
};
