import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as Info } from "../../assets/icons/info-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";

export const Popup = ({ data, icon }: { data: any; icon?: any }) => {
  return (
    <Popover>
      <Popover.Button className="flex items-center">
        {icon ? icon : <Info />}
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className="absolute z-10 rounded-xl bg-card-bgGreyLight p-4 max-w-[420px]">
          <Popover.Button className="absolute top-1 right-1">
            <Close />
          </Popover.Button>
          {data}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
