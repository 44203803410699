import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import useLeagueHook from "./hooks/useLeague";
import demo_logo from "../../../assets/images/demoLogo.png";
import CustomBox from "./components/CustomBox";
import moment from "moment";
import { OpenSlot, TeamCard } from "./components/TeamCard";
import { DeleteLeagueModal } from "./components/DeleteLeague";
import { LaunchDraftModal } from "./components/LaunchDraft";
import { CloseRegistrationModal } from "./components/CloseRegistration";
import LeagueRegistrationModal from "./components/LeagueRegistration";
import LiveChat from "../Team/components/LiveChat";

const League = () => {
  const {
    handleNumber,
    data,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    id,
    isAdmin,
  } = useLeagueHook();

  return (
    <>
      <div className="px-10 pb-6 hidden xl:block">
        <div className="w-full flex flex-col md:flex-row justify-between items-center">
          <div className="w-[50%] flex flex-row justify-start items-center">
            <img
              src={demo_logo}
              alt="CutThroat"
              className="w-[100px] h-[100px] rounded-[50px] mr-6"
            />
            <div>
              <h2 className="font-absoluteEmpire text-[30px] leading-none">
                {data?.name}
              </h2>
            </div>
          </div>
          <div className="w-[50%] flex flex-row justify-end items-end ml-auto gap-4">
            {isAdmin ? (
              <>
                <AddNewLeagueModal isEdit />
                {data?.status === "registration" && <CloseRegistrationModal />}
                {data?.status === "registration" && <LaunchDraftModal />}
                <DeleteLeagueModal />
              </>
            ) : (
              !isJoined && (
                <LeagueRegistrationModal league={data} isLeagueView={true} />
              )
            )}
          </div>
        </div>
        <div className="mt-[32px] flex gap-6">
          <CustomBox
            title="League Status"
            data={data?.status}
            className="capitalize"
          />
          <CustomBox
            title="Creation Date"
            data={moment(data?.createdAt).format("LL")}
          />
          <CustomBox
            title="Entry Fee"
            data={`$${handleNumber(data?.entryFee)} per team`}
          />
          <CustomBox
            title="1st Place Prize"
            data={`$${handleNumber(data?.prizeFundFirstPlace)}`}
          />
          <div className="min-w-[350px] w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-6 flex flex-row justify-between gap-4">
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">
                Draft Format
              </p>
              <p className="font-gilroyBold text-[20px]">Snake</p>
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">Start Date</p>
              <p className="font-gilroyBold text-[20px]">
                {moment(data?.draftDateTime).format("MMM DD")}
              </p>
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">Time</p>
              <p className="font-gilroyBold text-[20px]">
                {moment(data?.draftDateTime).format("LT")} PST
              </p>
            </div>
          </div>
          <CustomBox
            title="Teams"
            data={`${data?.team?.length} / ${data?.numberOfTeams}`}
            className="!max-w-[110px]"
          />
        </div>
        <div className="flex gap-6 mt-[32px]">
          <div className="flex flex-row flex-wrap justify-between bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%]">
            {teamsAtRisk?.length && data?.team?.length
              ? regularTeams.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                  />
                ))
              : data?.team?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                  />
                ))}
            {data?.numberOfTeams > data?.team?.length && !inProgress
              ? [
                  ...Array(data?.numberOfTeams - data?.team?.length).keys(),
                ]?.map((_item, index) => <OpenSlot key={index} />)
              : null}
            {teamsAtRisk?.length ? (
              <div className="flex w-full gap-4 items-center">
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-red font-gilroyBold">Risk zone</p>
                  <p className="text-text-red font-gilroyMedium text-xs text-nowrap">
                    (The elimination — Tuesday 12:00 AM)
                  </p>
                </div>
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
              </div>
            ) : null}
            {teamsAtRisk?.length
              ? teamsAtRisk?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                    atRisk
                  />
                ))
              : null}
            {eliminatedTeams?.length
              ? eliminatedTeams?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                    eliminated
                  />
                ))
              : null}
          </div>
          {isJoined ? <LiveChat leagueId={id!} /> : null}
        </div>
      </div>
      <div className="flex gap-4 flex-col p-4 xl:hidden">
        <div className="w-full flex flex-row justify-center items-center">
          <img
            src={demo_logo}
            alt="CutThroat"
            className="w-[100px] h-[100px] rounded-[50px] mr-6"
          />
          <div>
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              {data?.name}
            </h2>
            <p className="text-text-grey">{data?.description}</p>
          </div>
        </div>
        <div className="w-full flex   justify-center gap-4">
          <AddNewLeagueModal isEdit />
          {data?.status === "registration" && <CloseRegistrationModal />}
        </div>
        <div className="w-full flex   justify-center gap-4">
          {data?.status === "registration" && <LaunchDraftModal />}
          <DeleteLeagueModal />
        </div>
        <div className="w-full flex   justify-center gap-4">
          {!isJoined && <LeagueRegistrationModal league={data} />}
        </div>
        <div className="mt-[24px] flex flex-col gap-6">
          <div className="w-full flex   justify-center gap-4">
            <CustomBox
              title="League Status"
              data={data?.status}
              className="capitalize"
            />
            <CustomBox
              title="Creation Date"
              data={moment(data?.createdAt).format("LL")}
            />
          </div>
          <div className="w-full flex   justify-center gap-4">
            <CustomBox
              title="Entry Fee"
              data={`$${handleNumber(data?.entryFee)} per team`}
            />
            <CustomBox
              title="1st Place Prize"
              data={`$${handleNumber(data?.prizeFundFirstPlace)}`}
            />
          </div>
          <div className="w-full flex   justify-center gap-4">
            <div className="min-w-unset w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-6 flex flex-row justify-between gap-4 xl:min-w-[350px]">
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">
                  Draft Format
                </p>
                <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                  Snake
                </p>
              </div>
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">
                  Start Date
                </p>
                <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                  {moment(data?.draftDateTime).format("MMM DD")}
                </p>
              </div>
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">Time</p>
                <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                  {moment(data?.draftDateTime).format("LT")} PST
                </p>
              </div>
            </div>
          </div>
          <CustomBox
            title="Teams"
            data={`${data?.team?.length} / ${data?.numberOfTeams}`}
            className="max-w-full xl:!max-w-[110px]"
          />
        </div>
        <div className="flex gap-6 mt-[32px]">
          <div className="flex flex-row flex-wrap justify-center bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%] xl:justify-between">
            {inProgress && data?.team?.length
              ? regularTeams.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                  />
                ))
              : data?.team?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                  />
                ))}
            {data?.numberOfTeams > data?.team?.length && !inProgress
              ? [
                  ...Array(data?.numberOfTeams - data?.team?.length).keys(),
                ]?.map((_item, index) => <OpenSlot key={index} />)
              : null}
            {inProgress && (
              <div className="flex w-full gap-4 items-center">
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-red font-gilroyBold">Risk zone</p>
                  <p className="text-text-red font-gilroyMedium text-xs text-nowrap">
                    (The elimination — Tuesday 12:00 AM)
                  </p>
                </div>
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
              </div>
            )}
            {teamsAtRisk?.length
              ? teamsAtRisk?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                    atRisk
                  />
                ))
              : null}
            {eliminatedTeams?.length
              ? eliminatedTeams?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.position}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                    eliminated
                  />
                ))
              : null}
          </div>
          {isJoined ? <LiveChat leagueId={id!} /> : null}
        </div>
      </div>
    </>
  );
};

export default League;
