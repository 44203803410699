import { Button } from "../../../components";
import { ReactComponent as BackLeft } from "../../../assets/icons/arrow-left.svg";
import { TeamCard } from "./components/TeamCard";
import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import useDraftHook from "./hooks/useDraft";
import PlayersList from "./components/PlayersList";
import { DraftServices } from "../../../services/apis/Draft/DraftServices";
import { toast } from "react-toastify";

const Draft = () => {
  const {
    columns,
    data,
    isFetching,
    isViewPlayers,
    setIsViewPlayers,
    slotsWithIndexs,
    timer,
    myTeam,
    userData,
    navigate,
  } = useDraftHook();

  return (
    <>
      <div className="px-10 pb-6 w-full flex justify-between items-center gap-4 relative">
        <div
          onClick={() => navigate(-1)}
          className="flex flex-row justify-center items-center cursor-pointer"
        >
          <BackLeft />
          <span className="font-gilroyMedium text-base ml-1 ">Back</span>
        </div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {timer()}
        </div>
        <div className="flex items-center">
          <p className="text-[24px] mr-6 font-gilroyBold">
            {data?.currentDraft?.pool?.current_userId === userData?.id
              ? "It’s your turn!"
              : ""}
          </p>
          <Button
            mode="primary"
            onClick={() => {
              setIsViewPlayers(!isViewPlayers);
            }}
            className="rounded-[8px] w-auto px-4 !h-10"
          >
            View Players
          </Button>
          <Button
            mode="secondary"
            //TODO: remove end draft button
            onClick={async () => {
              try {
                await DraftServices.endDraft(data?.currentDraft?.leagueId);
              } catch (error: any) {
                toast.error(error?.message);
              }
            }}
            className="rounded-[8px] w-auto px-4 !h-10 ml-2"
          >
            End Draft (Dev)
          </Button>
        </div>
      </div>
      <div className="px-10 pb-6 overflow-x-auto flex justify-center items-center">
        <div className="mt-6">
          <div className={`flex flex-row gap-3`}>
            {data?.teamsInDraft
              ?.sort((a: any, b: any) => a?.draftPosition - b?.draftPosition)
              .map((item: any) => (
                <TeamCard
                  key={item?.id}
                  name={item?.name}
                  userId={item?.userId}
                  logo={item?.logo_url}
                />
              ))}
          </div>
          <TableCardsListing
            columns={columns}
            onClickRow={() => {}}
            dataSource={slotsWithIndexs ?? []}
            overrideTableContainerClass=""
            containerClass="w-fit"
            loading={isFetching}
            isDraft
          />
        </div>
      </div>
      {isViewPlayers && (
        <PlayersList
          canMakePick={
            data?.currentDraft?.pool?.current_userId === userData?.id
          }
          teamId={myTeam?.id}
        />
      )}
    </>
  );
};

export default Draft;
