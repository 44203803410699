import league_logo from "../../assets/images/league-logo.png";
import clsx from "clsx";
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { Popover } from "@headlessui/react";
import { Button } from "../../components";
import { ReactComponent as User } from "../../assets/icons/user-icon.svg";
import { ReactComponent as WhiteDots } from "../../assets/icons/white-dots.svg";
import { useEffect, useState } from "react";
import { DeleteUserModal } from "../../pages/private/Users/components/DeleteUser";
import { EditUserModal } from "../../pages/private/UserProfile/components/EditUser";

interface MobileLeaguesProps {
  users: { [key: string]: any }[];
}

const MobileUsers = ({ users }: MobileLeaguesProps) => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<{ [key: string]: any }[]>(users);
  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  return (
    <>
      {allUsers?.length > 0 ? (
        allUsers?.map((data: { [key: string]: any }) => (
          <Popover>
            <div className="relative bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">
              <div className="flex justify-between">
                <img
                  width={40}
                  height={40}
                  src={data?.profileUrl || league_logo}
                  alt="League logo"
                />
                <div>
                  <p className="font-gilroyBold text-base">
                    {data?.name || ""}
                  </p>
                  <p className="text-sm text-text-subGrey">{data?.email}</p>
                </div>
                <div className={`flex flex-col cursor-pointer`}>
                  <button
                    className={`outline-none h-[40px] w-[40px] rounded-[8px] flex justify-center items-center ${
                      data.openAction
                        ? "bg-card-bgGreyDark"
                        : "bg-card-bgGreyLight"
                    }`}
                  >
                    <Popover.Button
                      className="outline-none"
                      onClick={() =>
                        setAllUsers(
                          allUsers.map((item: { [key: string]: any }) => ({
                            ...item,
                            openAction:
                              data.id === item.id ? !item.openAction : false,
                          }))
                        )
                      }
                    >
                      {" "}
                      <WhiteDots />
                    </Popover.Button>
                  </button>
                </div>
              </div>

              <Popover.Panel className="w-full rounded-[8px]  bg-card-bgGreyDark p-4 flex gap-2">
                <Button
                  leftIcon={<User />}
                  mode="secondary"
                  onClick={() => navigate(`/users/user-profile/${data?.id}`)}
                  className="rounded-[8px] w-full px-4 !h-10"
                >
                  View User
                </Button>
                <EditUserModal userId={data?.id} isListView isMobile />
                <DeleteUserModal userId={data?.id} isMobile />
              </Popover.Panel>
              <div className="bg-card-strokeGradient-2 h-[2px]"></div>

              <div className="flex justify-between">
                <div className="w-full">
                  <p className="font-gilroyBold text-base">
                    {moment(data?.createdAt)?.format("LL") || ""}
                  </p>
                  <p className="text-sm text-text-subGrey">Registration Date</p>
                </div>
                <div className="w-full">
                  <p className="font-gilroyBold text-base">
                    {data?.number_of_leagues ?? "0"}
                  </p>
                  <p className="text-sm text-text-subGrey">Number of Leagues</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-full">
                  <p className="font-gilroyBold text-base">
                    {data?.balance?.credit_amount
                      ? `$${data?.balance?.credit_amount}`
                      : ""}
                  </p>
                  <p className="text-sm text-text-subGrey">Balance</p>
                </div>
                <div className="w-full">
                  <p className="font-gilroyBold text-base">
                    {data?.balance?.credit_amount
                      ? `$${data?.balance?.credit_amount}`
                      : ""}
                  </p>
                  <p className="text-sm text-text-subGrey">Credits</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className={`flex items-center justify-between`}>
                  <div
                    className={clsx(
                      "text-xs font-gilroyBold px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
                      data?.account_status === "blocked" &&
                        "from-elements-chipGradient-1 to-elements-chipGradient-2",
                      data?.account_status === "active" &&
                        "from-red-bgGradient2-1 to-red-bgGradient2-2"
                    )}
                  >
                    {data?.account_status || ""}
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        ))
      ) : (
        <div className=" bg-card-bgGreyMedium gap-4 rounded-[16px] p-6 flex flex-col">
          <p className="text-base text-text-subGrey">No data available</p>
        </div>
      )}
    </>
  );
};

export default MobileUsers;
