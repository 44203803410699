import demo_logo from "../../../../assets/images/demoLogo.png";
import moment from "moment";
import clsx from "clsx";
import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
} from "../../../../utils/helpers";
import { ReactComponent as Info } from "../../../../assets/icons/info-red-icon.svg";
import { RequestWithdrawModal } from "./components/RequestWithdraw";
import { DepositModal } from "./components/Deposit";
import useFinances from "./hooks/useFinances";

const Finances = () => {
  const { navigate, transactions, data, balance } = useFinances();

  return (
    <div className="px-10 pb-6 mt-6 min-h-[100vh] bg-finances-bg bg-no-repeat bg-left-bottom">
      <div className="w-full flex justify-between">
        <div className="w-[30%] flex flex-row justify-start items-center">
          <div>
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              Your finances
            </h2>
            <p className="text-text-subGrey font-gilroyMedium">
              In this section you can find information about your transaction
              history, current balance and selected payment methods
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <RequestWithdrawModal
            balance={Number(data?.balance?.winning_amount)}
          />
          <DepositModal />
        </div>
      </div>
      <div className="mt-6 flex gap-6">
        <div className="w-[35%] h-[100%] px-6 py-4 bg-card-bgGreyDark border-[1px] border-modal-modalStroke rounded-[12px]">
          <div className="flex items-center gap-2">
            <p className="text-base font-gilroyMedium">
              Account Balance Overview
            </p>
            <Info />
          </div>
          <p className="mt-6 font-absoluteEmpire text-[40px]">
            ${balance?.toFixed(2)}
          </p>
          <p className="mt-2 text-text-subGrey">My current Balance</p>
        </div>
        <div className="w-full">
          <div className="relative px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
            <p className="font-gilroyBold">Transaction History</p>
            <p
              onClick={() => navigate("/finances/transaction-history")}
              className="text-text-red font-gilroyMedium cursor-pointer"
            >
              View All Transaction
            </p>
          </div>
          <div className="bg-card-bgGreyDark p-6 rounded-b-[12px] border-x-[1px] border-b-[1px] border-modal-modalStroke flex flex-col-reverse">
            {transactions?.length ? (
              Object.keys(groupMessagesByDay(transactions))?.map((date, i) => {
                const group = groupMessagesByDay(transactions)[date];
                return (
                  <div key={i}>
                    <div className="flex gap-4 my-1">
                      <p className="text-text-grey font-gilroyMedium text-nowrap">
                        {formatAsFriendlyPastDate(
                          new Date(group[0].createdAt)
                        )?.toString()}
                      </p>
                    </div>
                    {group
                      ?.sort(
                        (a: any, b: any) =>
                          new Date(a?.createdAt)?.valueOf() -
                          new Date(b?.createdAt)?.valueOf()
                      )
                      ?.map((item: any, index: any) => {
                        const lastItem = index === group.length - 1;
                        return (
                          <>
                            <div
                              className="flex justify-between items-center my-6"
                              key={index}
                            >
                              <div>
                                <div className="flex items-center">
                                  <img
                                    src={demo_logo}
                                    alt="CutThroat"
                                    className="w-[40px] h-[40px] rounded-[50px] mr-3"
                                  />
                                  <div>
                                    <p className="font-gilroyBold capitalize">
                                      {item?.type}
                                    </p>
                                    <p className="text-text-grey text-xs font-gilroyMedium">
                                      {moment(item?.createdAt).format("lll")}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <p
                                className={clsx(
                                  "text-text-red font-gilroyBold",
                                  item?.type === "deposit" && "!text-text-green"
                                )}
                              >
                                {item?.type === "deposit" ? "+" : "-"}$
                                {item?.amount}
                              </p>
                            </div>
                            <div
                              className={clsx(
                                "h-[1px] w-full bg-modal-modalStroke",
                                lastItem && "hidden"
                              )}
                            />
                          </>
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <p className="font-gilroyMedium text-text-grey">
                No transactions
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finances;
