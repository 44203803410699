import { useParams } from "react-router-dom";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";
import { useFetchUserDetails } from "../../../../queries/userQueries/userQueries";
import league_logo from "../../../../assets/images/league-logo.png";
import PlayerDetailsModal from "../components/PlayerDetails";
import { Button } from "../../../../components";
import { useRemovePlayer } from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import useAuthStore from "../../../../stores/auth/auth";

const NameRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal enabled={data?.isMyTeam} id={data?.playerId}>
      <div className={`flex flex-row items-center`}>
        <img
          src={league_logo}
          alt="League logo"
          className="w-[32px] h-[32px] rounded-[20px]"
        />
        <div className="flex flex-col ml-2 font-gilroyMedium">{data?.name}</div>
      </div>
    </PlayerDetailsModal>
  );
};

const PositionRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal enabled={data?.isMyTeam} id={data?.playerId}>
      <div className="flex items-center">
        <div className="font-gilroyMedium text-center">
          {data?.position || ""}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const TeamRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal enabled={data?.isMyTeam} id={data?.playerId}>
      <div className={`flex flex-row items-center`}>
        <img
          src={data?.officialImageSrc || league_logo}
          alt="League logo"
          className="w-[16px] h-[16px] rounded-[20px]"
        />
        <div className="flex flex-col ml-2 font-gilroyMedium">
          {data?.nflTeam || "-"}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const PointsRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal enabled={data?.isMyTeam} id={data?.playerId}>
      <div className="flex items-center">
        <div className="font-gilroyMedium text-center">
          {data?.score ?? "-"}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal enabled={data?.isMyTeam} id={data?.playerId}>
      <div className="flex items-center">
        <div className="font-gilroyMedium text-center lowercase">
          {data?.status || "-"}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const ActionsRender = ({ ...data }) => {
  return (
    <div className="flex items-center">
      {data?.isMyTeam ? (
        <Button
          className="h-[41px] w-[65px] rounded-[8px]"
          onClick={() => data?.dropPlayer(data?.playerId)}
          mode="secondary"
        >
          Drop
        </Button>
      ) : null}
    </div>
  );
};

const useTeamHook = () => {
  const { id } = useParams();
  const { userData } = useAuthStore.getState();
  const { data: team, isFetching, refetch } = useFetchTeamDetails(id!);
  const { data: user } = useFetchUserDetails(team?.userId);
  const { mutateAsync } = useRemovePlayer();

  const dropPlayer = async (playerId: string) => {
    try {
      await mutateAsync({ teamId: id, playerId: playerId });
      refetch();
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  const isMyTeam = userData?.id === team?.userId;
  const isAdmin = userData?.role === "admin";

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender isMyTeam={isMyTeam} {...record} />;
      },
      sort: true,
    },
    {
      title: "Position",
      dataIndex: "position",
      render: (_: string, record: any) => {
        return <PositionRender isMyTeam={isMyTeam} {...record} />;
      },
      sort: true,
    },
    {
      title: "NFL Team",
      dataIndex: "team",
      render: (_: string, record: any) => {
        return <TeamRender isMyTeam={isMyTeam} {...record} />;
      },
      sort: true,
    },
    {
      title: "Fantasy Points",
      dataIndex: "points",
      render: (_: string, record: any) => {
        return <PointsRender isMyTeam={isMyTeam} {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender isMyTeam={isMyTeam} {...record} />;
      },
      sort: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            isMyTeam={isMyTeam}
            dropPlayer={dropPlayer}
            {...record}
          />
        );
      },
      sort: true,
    },
  ];

  return { isFetching, team, user, columns, isMyTeam, isAdmin };
};

export default useTeamHook;
