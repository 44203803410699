import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ReactComponent as Down } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Search } from "../../assets/icons/search-icon.svg";
import useFilter from "../../hooks/useFilter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Input } from "../Input/Input";

const Filters = ({
  name,
  value,
  filters,
}: {
  name: string;
  value: string;
  filters?: string[];
}) => {
  const { onChangeFilter, filter } = useFilter({});
  return (
    <Menu as="div" className="relative">
      {() => (
        <>
          {value === "keywords" ? (
            <Input
              containerClassName="!h-[20px] border-none !py-0 !px-1 bg-transparent focus-within:bg-transparent !w-[70px]"
              inputClassName="placeholder-text-white text-sm"
              placeholder="Search"
              leftIcon={<Search />}
              onChange={(e) => {
                onChangeFilter({
                  keywords: e.target.value,
                });
              }}
            />
          ) : (
            <Menu.Button
              aria-pressed={false}
              aria-hidden
              className="w-max inline-flex items-center relative"
            >
              <p className="mr-2 text-sm font-gilroyMedium">
                {name}
                <span className="font-gilroyBold ml-2">
                  {value === "draftDateTime"
                    ? filter[value] === "All"
                      ? "All"
                      : moment(filter[value]).format("LL")
                    : filter[value]}
                </span>
              </p>
              <Down />
            </Menu.Button>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as="div"
              className="bg-card-bgGreyDark rounded-[16px] left-[50%] translate-x-[-50%] absolute z-10 flex flex-col mt-4 shadow-2xl"
            >
              {filters?.length
                ? filters?.map((item, index) => (
                    <Menu.Item key={item}>
                      <button
                        className={clsx(
                          "px-6 py-3 hover:bg-card-bgGreyMedium cursor-pointer text-base flex items-center",
                          index === 0 && "rounded-t-[16px]",
                          filters.length - 1 === index && "rounded-b-[16px]",
                          item === filter[value] && "font-gilroyBold"
                        )}
                        onClick={() => {
                          onChangeFilter({ [value]: item });
                        }}
                      >
                        <span className="text-left whitespace-nowrap">
                          {item}
                        </span>
                      </button>
                    </Menu.Item>
                  ))
                : null}
              {value === "draftDateTime" ? (
                <DatePicker
                  showPopperArrow={false}
                  // @ts-ignore
                  selected={
                    filter?.draftDateTime !== "All"
                      ? filter?.draftDateTime
                      : null
                  }
                  dateFormat="yyyy-mm-dd"
                  onChange={(date: any) => onChangeFilter({ [value]: date })}
                  inline
                />
              ) : null}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default Filters;
