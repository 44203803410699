import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing.tsx";
import useUserLeaguesHook from "./hooks/useUserLeagues.tsx";
import Filters from "../../../../components/Filters/Filters.tsx";
import { Button } from "../../../../components/index.ts";
import MobileFilters from "../../../../components/MobileFilters/MobileFilters.tsx";
import MobileLeagues from "../../../../components/MobileLeagues/MobileLeagues.tsx";

const UserLeagues = () => {
  const {
    columns,
    isFetching,
    filters,
    leagues,
    onShowJustOpen,
    onChangeFilter,
    filter,
  } = useUserLeaguesHook();

  return (
    <div className="px-4 pb-6 md:px-10">
      <div className="w-full flex justify-start gap-4 mt-7">
        <h2 className="font-absoluteEmpire text-[30px] leading-none">
          EXPLORE ALL LEAGUES
        </h2>
      </div>
      <div className="mt-6 hidden lg:block">
        <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
          <div className="flex flex-row justify-between items-center gap-[32px]">
            <p className="font-gilroyBold">All Leagues</p>
            {filters?.map((item) => (
              <Filters
                key={item?.value}
                name={item?.name}
                value={item?.value}
                filters={item?.filters}
              />
            ))}
          </div>
          <Button
            mode="primary"
            className="!max-h-[40px] rounded-[8px]"
            onClick={onShowJustOpen}
          >
            Show Just Open
          </Button>
        </div>
        <TableCardsListing
          columns={columns}
          dataSource={leagues}
          loading={isFetching}
          paginationData={{
            page: filter?.page as number,
            limit: filter?.limit as number,
            count: leagues?.length ?? 0,
            onChange: (params) => {
              onChangeFilter(params);
            },
          }}
        />
      </div>
      <div className="flex gap-4 flex-col lg:hidden">
        <p className="font-gilroyBold mt-8 mb-3">All Leagues</p>

        <MobileFilters page="leagues" onShowJustOpen={onShowJustOpen} filters={filters} />
        <MobileLeagues leagues={leagues} />
      </div>
    </div>
  );
};

export default UserLeagues;
