import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import {Button, Input} from "../../../components";
import {ReactComponent as NewUser} from "../../../assets/icons/new-user.svg";
import {ReactComponent as Export} from "../../../assets/icons/export.svg";
import {ReactComponent as Search} from "../../../assets/icons/search-icon.svg";
import useUsersHook from "./hooks/useUsers";
import MobileFilters from "../../../components/MobileFilters/MobileFilters.tsx";
import MobileUsers from "../../../components/MobileUsers/MobileUsers.tsx";


const Users = () => {
    const {
        columns,
        isFetching,
        setName,
        users,
        usersCount,
        setStatus,
        setNumOfLeagues,
        setCredits,
        setBalance,
        status,
        numOfLeagues,
        balance,
        credits
    } = useUsersHook();

    const userFilters = columns.filter((item: any) => item.name).map((item: any) => ({
        name: item.name,
        value: item.dataIndex,
        filters: item.filters
    }));
const allUsers=users.map((item:{[key: string]: any })=>({...item,openAction:false}))
    return (
        <div className="px-4 pb-6 md:px-10">
            <div className="w-full justify-end gap-4 hidden lg:flex">
                <Button
                    leftIcon={<NewUser/>}
                    mode="secondary"
                    //TODO: add function for ivite
                    onClick={() => {
                    }}
                    className="rounded-[8px] w-auto px-4 !h-10"
                >
                    Invite New User
                </Button>
                <Button
                    leftIcon={<Export/>}
                    mode="secondary"
                    //TODO: add function for export
                    onClick={() => {
                    }}
                    className="rounded-[8px] w-auto px-4 !h-10"
                >
                    Export
                </Button>
            </div>
            <div className="mt-6 hidden lg:block">
                <div
                    className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row gap-6 items-center">
                    <p className="font-gilroyBold">All Users ({usersCount})</p>
                    <Input
                        containerClassName="!h-[20px] border-none !py-0 !px-1 bg-transparent focus-within:bg-transparent !w-[70px]"
                        inputClassName="placeholder-text-white text-sm"
                        placeholder="Search"
                        leftIcon={<Search/>}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </div>
                <TableCardsListing
                    columns={columns}
                    dataSource={users}
                    loading={isFetching}
                    isPlayers
                />
            </div>
            <div className="flex gap-4 flex-col lg:hidden">
                <p className="font-gilroyBold mt-8 mb-3">All Users</p>

                <MobileFilters
                    status={status}
                    numOfLeagues={numOfLeagues}
                    balance={balance}
                    credits={credits}
                    setStatus={setStatus}
                    setNumOfLeagues={setNumOfLeagues}
                    setBalance={setBalance}
                    setCredits={setCredits}
                    filters={userFilters}
                    setName={setName}
                    page="users"
                />
                <MobileUsers users={allUsers}/>
                <div className="w-full justify-end gap-4 flex">
                    <Button
                        leftIcon={<NewUser/>}
                        mode="primary"
                        //TODO: add function for ivite
                        onClick={() => {
                        }}
                        className="rounded-[8px] w-full px-4 !h-10"
                    >
                        Invite New User
                    </Button>
                    <Button
                        leftIcon={<Export/>}
                        mode="secondary"
                        //TODO: add function for export
                        onClick={() => {
                        }}
                        className="rounded-[8px] w-full px-4 !h-10"
                    >
                        Export
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Users;
