import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Delete } from "../../../../assets/icons/delete-icon.svg";
import { useDeleteUser } from "../../../../queries/adminQueries/adminQueries";
import { toast } from "react-toastify";

export const DeleteUserModal = ({
  userId,
  isMobile,
}: {
  userId: string;
  isMobile?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <DeleteUser closeModal={closeModal} userId={userId} />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "lg",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      {isMobile ? (
        <Button
          leftIcon={<Delete />}
          mode="primary"
          onClick={openModal}
          className="rounded-[8px] w-full px-4 !h-10"
        >
          Delete
        </Button>
      ) : (
        <Delete onClick={openModal} className="cursor-pointer" />
      )}
    </>
  );
};

const DeleteUser = ({
  closeModal,
  userId,
}: {
  closeModal?: () => void;
  userId: string;
}) => {
  const { mutateAsync } = useDeleteUser();

  const onDeleteUser = async () => {
    try {
      await mutateAsync(userId);
    } catch (error) {
      toast.error("Failed to delete user");
    } finally {
      closeModal?.();
    }
  };
  return (
    <>
      <p className="text-[24px] font-gilroySemibold mb-2">
        Are you sure you want to delete user?
      </p>
      <p className="font-gilroyMedium text-text-subGrey">
        This action can’t be undone
      </p>
      <div className="flex flex-col gap-4 md:flex-row mt-6">
        <Button
          onClick={onDeleteUser}
          size="md"
          mode="secondary"
          className="!text-base w-full !rounded-[8px]"
        >
          Delete
        </Button>
        <Button
          onClick={closeModal}
          size="md"
          className="!text-base w-full !rounded-[8px]"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default DeleteUser;
