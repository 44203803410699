import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import useTransactionHistoryHook from "./hooks/useTransactionHistory";
import SingleFilter from "../../Users/hooks/SingleFilter";
import DateFilter from "./components/DateFilter";
import { RequestWithdrawModal } from "../../UserPages/Finances/components/RequestWithdraw";
import { DepositModal } from "../../UserPages/Finances/components/Deposit";

const TransactionsHistory = () => {
  const {
    columns,
    filter,
    onChangeFilter,
    transactions,
    isFetching,
    filters,
    type,
    setType,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    user,
  } = useTransactionHistoryHook();

  return (
    <div className="px-10 pb-6 mt-6">
      <div className="w-full flex justify-between">
        <div className="w-[40%] flex flex-row justify-start items-center">
          <div>
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              Transaction History
            </h2>
            <p className="text-text-subGrey font-gilroyMedium">
              Your transaction history
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <RequestWithdrawModal
            balance={Number(user?.balance?.winning_amount)}
          />
          <DepositModal />
        </div>
      </div>
      <div className="mt-6 flex gap-6">
        <div className="w-full">
          <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-start items-center">
            <div className="relative flex flex-row justify-between items-center gap-[32px]">
              <div className="flex items-center gap-2">
                <p className="font-gilroyMedium">Type</p>
                <SingleFilter
                  activeFilter={type}
                  setFilter={setType}
                  filters={filters?.type}
                />
              </div>
              <div className="flex items-center gap-2"></div>

              <DateFilter
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            </div>
          </div>
          <TableCardsListing
            columns={columns}
            onClickRow={() => {}}
            dataSource={transactions ?? []}
            overrideTableContainerClass=""
            loading={isFetching}
            paginationData={{
              page: filter?.page as number,
              limit: filter?.limit as number,
              count: transactions?.length ?? 0,
              onChange: (params) => {
                onChangeFilter(params);
              },
            }}
            isPlayers
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionsHistory;
