import privacyPolicy from "../../../configs/privacyPolicy";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="px-5 py-5 md:px-20 md:py-10">
      <h1
        className="text-4xl font-gilriyBold text-text-red cursor-pointer mb-4"
        onClick={() => navigate("/")}
      >
        CutThroat Football
      </h1>
      <p className="text-3xl font-gilriyBold mb-4">Privacy Policy</p>
      {privacyPolicy?.map((item, index) => {
        return (
          <div key={index} className="mb-6">
            <h2 className="text-2xl font-bold">{item?.title}</h2>
            <p className="mt-4">{item?.content}</p>
          </div>
        );
      })}
      <p className="text-right">All rights reserved</p>
    </div>
  );
};

export default PrivacyPolicy;
