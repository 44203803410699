import { Navigate, RouteObject } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import Signup from "../pages/guest/Signup/Signup";
import ForgotPassword from "../pages/guest/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/guest/ResetPassword/ResetPassword";
import Login from "../pages/guest/Login/Login";
import PrivacyPolicy from "../pages/guest/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/guest/TermsAndConditions/TermsAndConditions";

const publicRoutes: RouteObject[] = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-conditions", element: <TermsAndConditions /> },
  { path: "*", element: <Navigate to="/login" replace /> },
];

export const publicRouter = createBrowserRouter(publicRoutes);
